import { useListContext, useTranslate } from 'react-admin';
import { DateTime } from 'luxon';
import DatePickerInput from './DatePickerInput';

type DateRangeInputProps = {
  // Required for input to displayed when used as a Ra filter
  alwaysOn: boolean;
};

export default function DateRangeInput(_props: DateRangeInputProps) {
  const translate = useTranslate();
  const ctx = useListContext();

  const start = DateTime.fromISO(ctx.filterValues['startDate']);
  const end = DateTime.fromISO(ctx.filterValues['endDate']);

  const updateFilters = (filters: Record<string, unknown>) => {
    ctx.setFilters({ ...ctx.filterValues, ...filters }, ctx.displayedFilters);
  };

  return (
    <div className="datepicker-range">
      <DatePickerInput
        label={translate('filters.date.start')}
        value={start.isValid ? start : null}
        maxDate={end}
        onChange={(date: DateTime) => {
          updateFilters({ startDate: date.toISODate() });
        }}
      />
      <DatePickerInput
        label={translate('filters.date.end')}
        value={end.isValid ? end : null}
        minDate={start}
        onChange={(date: DateTime) => {
          updateFilters({ endDate: date.toISODate() });
        }}
      />
    </div>
  );
}
