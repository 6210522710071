import {
  CacheMachine,
  CacheMachineChecklist,
  CacheMachineChecklistLanguage,
  CacheMachineChecklistLanguageElement,
  CacheMachineLanguage,
  CacheMachinePatternGroup
} from '../../core/models/cache';
import { mapCachePatternGroupToDBPatternGroup } from '../patternGroups';
import {
  DBMachine,
  DBMachineChecklist,
  DBMachineChecklistLanguage,
  DBMachineChecklistLanguageElement,
  DBMachineLanguage,
  DBMachinePatternGroup
} from './types';

export function mapCacheMachineToDBMachine(machine: CacheMachine): DBMachine {
  return {
    id: machine.id,
    patternGroups: machine.patternGroups.map(
      mapCacheMachinePatternGroupToDBMachinePatternGroup
    ),
    checklists: machine.checklists.map(
      mapCacheMachineChecklistToDBMachineChecklist
    ),
    languages: machine.languages.map(mapCacheMachineLanguageToDBMachineLanguage)
  };
}

export function mapCacheMachinePatternGroupToDBMachinePatternGroup(
  group: CacheMachinePatternGroup
): DBMachinePatternGroup {
  return {
    patternGroup: mapCachePatternGroupToDBPatternGroup(group.patternGroup)
  };
}

export function mapCacheMachineChecklistToDBMachineChecklist(
  checklist: CacheMachineChecklist
): DBMachineChecklist {
  return {
    id: checklist.id,
    languages: checklist.languages.map(
      mapCacheMachineChecklistLanguageToDBMachineChecklistLanguage
    )
  };
}

export function mapCacheMachineLanguageToDBMachineLanguage(
  language: CacheMachineLanguage
): DBMachineLanguage {
  return {
    code: language.code,
    name: language.name
  };
}

export function mapCacheMachineChecklistLanguageToDBMachineChecklistLanguage(
  language: CacheMachineChecklistLanguage
): DBMachineChecklistLanguage {
  return {
    code: language.code,
    name: language.name,
    elements: language.elements.map(
      mapCacheMachineChecklistLanguageElementToDBMachineChecklistLanguageElement
    )
  };
}

export function mapCacheMachineChecklistLanguageElementToDBMachineChecklistLanguageElement(
  element: CacheMachineChecklistLanguageElement
): DBMachineChecklistLanguageElement {
  return {
    id: element.id,
    name: element.name
  };
}
