import { useNotify, useRecordContext } from 'react-admin';
import { useOfflineDb } from '../providers/OfflineDbProvider';
import { Cache } from '../../core/models/cache';
import { getCache } from '../../core/services/cache-api.service';
import { BsCloudArrowUp, BsFillCloudCheckFill } from 'react-icons/bs';
import { IconButton } from '@mui/material';
import {
  DBClient,
  mapCacheClientToDBClient
} from '../../service-worker/clients';
import {
  DBMachine,
  mapCacheMachineToDBMachine
} from '../../service-worker/machines';
import {
  DBClientMachine,
  mapCacheClientMachineToDBClientMachine
} from '../../service-worker/clientMachines';
import {
  DBCertificate,
  DBCertificateDetails,
  DBCertificatePatternGroup,
  mapCacheCertificatePatternGroupToDBCertificatePatternGroup,
  mapCacheCertificateToDBCertificate,
  mapCacheCertificateToDBCertificateDetails
} from '../../service-worker/certificates';
import {
  DBPatternGroup,
  mapCachePatternGroupToDBPatternGroup
} from '../../service-worker/patternGroups';
import {
  DBMagnitude,
  mapCacheMagnitudeToDBMagnitude
} from '../../service-worker/magnitudes';
import { DBUser, mapCacheUserToDBUser } from '../../service-worker/users';
import { DBUnit, mapCacheUnitToDBUnit } from '../../service-worker/units';
import {
  DBPattern,
  mapCachePatternToDBPattern
} from '../../service-worker/patterns';
import clsx from 'clsx';
import { useQuery, useQueryClient } from 'react-query';
import queryKeys from '../../core/hooks/query-keys';

export default function CacheClientButton() {
  const record = useRecordContext();
  const db = useOfflineDb();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const { data: cached, isLoading } = useQuery(
    queryKeys.cache.detail(record.id as string),
    async () => {
      const client = await db.clients.get(record.id);
      return client !== undefined;
    },
    { refetchOnWindowFocus: false }
  );

  async function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    try {
      const cache = await getCache(record.id as string);
      const dbData = convertCacheToDBData(cache);

      await Promise.all([
        db.clients.put(dbData.client),
        db.machines.bulkPut(dbData.machines),
        db.clientMachines.bulkPut(dbData.clientMachines),
        db.certificates.bulkPut(dbData.certificates),
        db.certificatesDetails.bulkPut(dbData.certificatesDetails),
        db.certificatePatternGroups.bulkPut(dbData.certificatePatternGroups),
        db.patternGroups.bulkPut(dbData.patternGroups),
        db.magnitudes.bulkPut(dbData.magnitudes),
        db.users.bulkPut(dbData.users),
        db.units.bulkPut(dbData.units),
        db.patterns.bulkPut(dbData.patterns)
      ]);

      queryClient.invalidateQueries(
        queryKeys.cache.detail(record.id as string)
      );
      queryClient.invalidateQueries(queryKeys.cache.count);
    } catch (e) {
      console.error(e);
      notify('Failed to cache', { type: 'error' });
    }
  }

  return (
    <IconButton
      aria-label="cache"
      className={clsx('cache-icon', { cached })}
      disabled={cached || isLoading}
      onClick={handleClick}
    >
      {cached ? (
        <BsFillCloudCheckFill size="22px" />
      ) : (
        <BsCloudArrowUp size="22px" />
      )}
    </IconButton>
  );
}

type DBData = {
  client: DBClient;
  machines: DBMachine[];
  clientMachines: DBClientMachine[];
  certificates: DBCertificate[];
  certificatesDetails: DBCertificateDetails[];
  certificatePatternGroups: DBCertificatePatternGroup[];
  patternGroups: DBPatternGroup[];
  magnitudes: DBMagnitude[];
  users: DBUser[];
  units: DBUnit[];
  patterns: DBPattern[];
};

function convertCacheToDBData(cache: Cache): DBData {
  const { client, patternGroups, magnitudes, units, users } = cache;
  const data: DBData = {
    client: mapCacheClientToDBClient(client),
    machines: [],
    clientMachines: [],
    certificates: [],
    certificatesDetails: [],
    certificatePatternGroups: [],
    patternGroups: patternGroups.map(mapCachePatternGroupToDBPatternGroup),
    magnitudes: magnitudes.map(mapCacheMagnitudeToDBMagnitude),
    units: units.map(mapCacheUnitToDBUnit),
    users: users.map(mapCacheUserToDBUser),
    patterns: patternGroups.flatMap((g) =>
      g.patterns.map((p) => mapCachePatternToDBPattern(p, g.id))
    )
  };

  for (const clientMachine of client.clientMachines) {
    data.clientMachines.push(
      mapCacheClientMachineToDBClientMachine(clientMachine, client)
    );

    data.machines.push(mapCacheMachineToDBMachine(clientMachine.machine));

    for (const certificate of clientMachine.certificates) {
      data.certificates.push(
        mapCacheCertificateToDBCertificate(certificate, clientMachine)
      );

      data.certificatesDetails.push(
        mapCacheCertificateToDBCertificateDetails(certificate)
      );

      for (const certificatePatternGroup of certificate.patternGroups) {
        data.certificatePatternGroups.push(
          mapCacheCertificatePatternGroupToDBCertificatePatternGroup(
            certificatePatternGroup,
            certificate
          )
        );
      }
    }
  }

  return data;
}
