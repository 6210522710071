import axios from 'axios';
import { envConfig } from '../../config/env-config';
import { HEADER_ANONYMOUS, HEADER_AVOID_ERROR_HANDLING } from './headers';
import { getError } from './intereceptors/error.interceptor';
import { getSession } from './storage-service';

const axiosInstance = axios.create({
  baseURL: envConfig.baseUrl
});

export const getDefaultRequestConfig = (locale: string = 'en') => ({
  headers: {
    Authorization: `Bearer ${getSession()?.token}`,
    'Content-Type': 'application/json',
    'Accept-Language': locale
  }
});

export const getMultiPartRequestConfig = (locale: string = 'en') => ({
  headers: {
    Authorization: `Bearer ${getSession()?.token}`,
    'Content-Type': 'multipart/form-data',
    'Accept-Language': locale
  }
});

export const getDefaultAnonymousRequestConfig = () => ({
  headers: {
    'Content-Type': 'application/json',
    [HEADER_ANONYMOUS]: 'true'
  }
});

export const getDefaultAnonymousRequestConfigWithErrorHandling = () => ({
  headers: {
    'Content-Type': 'application/json',
    [HEADER_ANONYMOUS]: 'true',
    [HEADER_AVOID_ERROR_HANDLING]: 'true'
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => getError(axiosInstance, error)
);

export default axiosInstance;
