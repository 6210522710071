import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function ClientTransferIcon(props: any) {
  const propsMerged = {
    ...props,
    viewBox: '0 0 24 24'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path
        d="M9.49484536,11.3022059 C9.71340206,11.5207626 9.71340206,11.8712781 9.49484536,12.0898348 L6.01443299,15.5702471 L19.3340206,15.5702471 C19.643299,15.5702471 19.8907216,15.8176698 19.8907216,16.1269482 C19.8907216,16.4362265 19.643299,16.6836492 19.3340206,16.6836492 L6.01443299,16.6836492 L9.49484536,20.1640616 C9.71340206,20.3826183 9.71340206,20.7331337 9.49484536,20.9516904 C9.27628866,21.1702471 8.9257732,21.1702471 8.70721649,20.9516904 L4.2742268,16.5187007 C4.17113402,16.415608 4.10927835,16.2712781 4.10927835,16.1269482 C4.10927835,15.9826183 4.16701031,15.8382884 4.2742268,15.7351956 L8.70721649,11.3022059 C8.9257732,11.0836492 9.27628866,11.0836492 9.49484536,11.3022059 Z M14.5051546,3.04830956 C14.7237113,2.82975286 15.0742268,2.82975286 15.2927835,3.04830956 L19.7257732,7.48129925 C19.8329897,7.58439203 19.8907216,7.72872193 19.8907216,7.87305183 C19.8907216,8.01738172 19.828866,8.16171162 19.7257732,8.2648044 L15.2927835,12.6977941 C15.0742268,12.9163508 14.7237113,12.9163508 14.5051546,12.6977941 C14.2865979,12.4792374 14.2865979,12.1287219 14.5051546,11.9101652 L17.985567,8.42975286 L4.66597938,8.42975286 C4.35670103,8.42975286 4.10927835,8.18233018 4.10927835,7.87305183 C4.10927835,7.56377348 4.35670103,7.3163508 4.66597938,7.3163508 L17.985567,7.3163508 L14.5051546,3.83593843 C14.2865979,3.61738172 14.2865979,3.26686626 14.5051546,3.04830956 Z"
        fill="#000000"
      ></path>
    </SvgIcon>
  );
}
