import { Edit } from 'react-admin';
import { MachineForm } from './machinesShared/machinesSharedForm/MachineForm';
import { transformToApi } from '../../core/utils/machine-util';
import CustomToolbar from '../general/Toolbar';

export const MachineEdit = () => {
  const transform = async (data) => transformToApi(data);

  return (
    <Edit actions={<CustomToolbar />} transform={transform}>
      <MachineForm />
    </Edit>
  );
};
