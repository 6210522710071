import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function ClientsIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20'
    viewBox: '-1 -3 24 24'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M17.231 11.589c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.166 0v-.602c0-.322.26-.583.583-.583zm0-3.266c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.166 0v-.602c0-.322.26-.583.583-.583zM11 11.589c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.166 0v-.602c0-.322.26-.583.583-.583zm0-3.266c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.166 0v-.602c0-.322.26-.583.583-.583zm3.116 3.266c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.167 0v-.602c0-.322.261-.583.584-.583zm0-3.266c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.167 0v-.602c0-.322.261-.583.584-.583zm-6.232 3.266c.323 0 .584.26.584.583v.602a.583.583 0 1 1-1.167 0v-.602c0-.322.261-.583.583-.583zm0-3.266c.323 0 .584.26.584.583v.602a.583.583 0 1 1-1.167 0v-.602c0-.322.261-.583.583-.583zM4.77 11.589c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.166 0v-.602c0-.322.26-.583.583-.583zm0-3.266c.322 0 .583.26.583.583v.602a.583.583 0 1 1-1.166 0v-.602c0-.322.26-.583.583-.583zM20.548.406c.381-.311.952-.04.952.427v15.541a.583.583 0 0 1-.583.584H1.083a.583.583 0 0 1-.583-.584V6.33c0-.172.075-.334.206-.445L7.123.442c.377-.321.96-.052.96.391V5.1L13.84.406a.584.584 0 0 1 .944.356l.008.071L14.79 5.1zm-.215 1.68L14.577 6.78a.583.583 0 0 1-.952-.452l-.001-4.243-5.755 4.695a.582.582 0 0 1-.945-.357l-.007-.095V2.146l-5.25 4.452v9.193h18.666z" />
    </SvgIcon>
  );
}
