import { useQuery } from 'react-query';
import { getMagnitudeUnits } from '../services/units-api.service';
import queryKeys from './query-keys';

export default function useMagnitudeUnits(magnitudeCode: string) {
  return useQuery(
    queryKeys.magnitudes.units(magnitudeCode),
    () => getMagnitudeUnits(magnitudeCode).then(({ data }) => data),
    { refetchOnWindowFocus: false }
  );
}
