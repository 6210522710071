import { HttpError, Options, fetchUtils } from 'react-admin';
import jwtManager from './jwtManager';

const getErrorMessageCode = (error?: any) => {
  return error?.body?.errors?.global?.code ?? 'unrecognized_error';
};

const httpClient = async (url: any, options?: Options) => {
  if (!options) {
    options = {};
  }

  const requestHeaders: HeadersInit = new Headers();
  if (!options.headers) {
    requestHeaders.set('Accept', 'application/json');
  }

  let token = jwtManager.getToken();
  if (token) {
    requestHeaders.set('Authorization', `Bearer ${token}`);
  }
  options.headers = requestHeaders;

  let res;
  try {
    res = await fetchUtils.fetchJson(url, options);
    return res;
  } catch (e) {
    if (e.status === 401) {
      await jwtManager.getRefreshedToken();
      token = jwtManager.getToken();
      requestHeaders.set('Authorization', `Bearer ${token}`);
      if (requestHeaders && options) {
        options.headers = requestHeaders;
      }
      return fetchUtils.fetchJson(url, options);
    }
    if (e.status === 404 || e.status === 400) {
      const code = getErrorMessageCode(e);
      throw new HttpError(`errors.${code.toLowerCase()}`, e.status, {});
    }
  }
};

export default httpClient;
