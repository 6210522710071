import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function DeleteIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20'
    viewBox: '0 0 14 18'
  };
  return (
    <SvgIcon {...propsMerged} className="pointer">
      <path d="M10.123 5.452a.4.4 0 0 1 .378.42L10 15.92c0 .213-.211.379-.423.379h-.02a.4.4 0 0 1-.38-.42L9.703 5.83a.398.398 0 0 1 .42-.379zm-5.826.379l.524 10.047a.4.4 0 0 1-.4.421c-.21 0-.421-.166-.421-.38L3.498 5.874a.4.4 0 1 1 .799-.042zm3.103.005v10.063a.4.4 0 1 1-.8 0V5.836a.4.4 0 0 1 .8 0zm5.272-4.468c.55 0 .997.444.997.988V3c0 .42-.252.771-.611.921-.114 2.264-.771 13.211-.801 13.703a.4.4 0 0 1-.4.376H2.143a.4.4 0 0 1-.4-.376c-.03-.492-.687-11.439-.8-13.703A.993.993 0 0 1 .33 3v-.643c0-.545.447-.989.997-.989H5V1c0-.552.474-1 1-1h2c.525 0 1 .448 1 1v.368zM5.832 1v.368h2.336V1c0-.11-.084-.2-.168-.2H6c-.085 0-.168.09-.168.2zM1.13 2.357V3c0 .114.088.198.197.198h11.345A.195.195 0 0 0 12.87 3v-.643a.193.193 0 0 0-.197-.189H1.327a.193.193 0 0 0-.197.189zM11.48 17.2c.118-1.957.657-10.953.773-13.2H1.746c.116 2.247.655 11.243.772 13.2z" />
    </SvgIcon>
  );
}
