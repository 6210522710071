import * as R from 'ramda';
import { isNullOrUndefined } from './object-utils';
import { isEmpty } from './string-utils';

export const parseNumber = (value: string | number | null) => {
  if (typeof value === 'number' || value === null) {
    return value;
  }

  return isNullOrUndefined(value) || isEmpty(value) ? null : +value;
};

export const round = (value: number, decimals = 2) =>
  !isNullOrUndefined(value) ? +value?.toFixed(decimals) : null;

export const compare = (a: number, b: number) => a - b;

export const getModule = (value: number, max: number, reverse = false) => {
  return reverse ? max - (value % max) - 1 : value % max;
};

export const sum = (a: number, b: number) => a + b;

export const isDivisibleBy = R.curry(
  (base: number, value: number) => value % base === 0
);

export const isEven = (value: number) => isDivisibleBy(2, value);

export const percentage = (value: number, base: number, decimals = 2) =>
  base !== 0 ? round((value * 100) / base, decimals) : null;

export const countDecimals = (number: number) => {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
  return number.toString().split('.')[1].length || 0;
};

export const toLocaleString = (value, locale, options = {}) => {
  return value.toLocaleString(locale, options);
};

export const padStart = (
  value: number,
  padPositions: number = 0,
  padChar: string = ''
) => value.toString().padStart(padPositions, padChar);

export const max = (values: number[]) => Math.max(...values);

export const min = (values: number[]) => Math.min(...values);

export const sqrt = (value: number) => Math.sqrt(value);

export const pow = (base: number, exponent: number) => Math.pow(base, exponent);

export const formatDecimal = (value: string, decimalSeparator = ',') => {
  let cleanedValue = value
    .replace(/[^0-9,.-]/g, '')
    .replace(/(?<!^)-/g, '')
    .replace(/[,.]/g, decimalSeparator);

  const regex = new RegExp(decimalSeparator, 'g');
  let firstOccurrence = true;
  cleanedValue = cleanedValue.replace(regex, (match) => {
    if (match === decimalSeparator && firstOccurrence) {
      firstOccurrence = false;
      return match;
    } else if (match !== decimalSeparator) {
      return match;
    } else {
      return '';
    }
  });

  return cleanedValue;
};

export const formatThousands = (
  value: string,
  decimalSeparator = ',',
  thousandSeparator = '.'
): string => {
  if (!value) {
    return value;
  }
  if (/\./.test(value)) {
    const decimalPart = value.split('.')[1];
    if (decimalPart && decimalPart.length > 0) {
      const [integerPart, remainingDecimalPart] = value.split('.');
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        thousandSeparator
      );
      return `${formattedIntegerPart}${decimalSeparator}${remainingDecimalPart}`;
    }
  }

  const [integerPart, decimalPart] = value?.split(',');
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator
  );

  return decimalPart
    ? `${formattedIntegerPart}${decimalSeparator}${decimalPart}`
    : formattedIntegerPart;
};
