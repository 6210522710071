import UnitType from './unitType';

type UnitConversionType = {
  from: (typeof UnitType)[keyof typeof UnitType];
  to: (typeof UnitType)[keyof typeof UnitType];
  factor: number;
};

const unitConversion: Record<string, UnitConversionType> = {
  TYPE_N_MN: { from: UnitType.TYPE_N, to: UnitType.TYPE_MN, factor: 1000.0 },
  TYPE_N_LBF: {
    from: UnitType.TYPE_N,
    to: UnitType.TYPE_LBF,
    factor: 0.2248089
  },
  TYPE_N_KN: { from: UnitType.TYPE_N, to: UnitType.TYPE_KN, factor: 0.001 },
  TYPE_N_KGF: {
    from: UnitType.TYPE_N,
    to: UnitType.TYPE_KGF,
    factor: 0.1019716
  },
  TYPE_N_GF: { from: UnitType.TYPE_N, to: UnitType.TYPE_GF, factor: 101.9716 },

  TYPE_S_MIN: {
    from: UnitType.TYPE_S,
    to: UnitType.TYPE_MIN,
    factor: 0.01666667
  },
  TYPE_S_H: {
    from: UnitType.TYPE_S,
    to: UnitType.TYPE_H,
    factor: 0.0002777778
  },

  TYPE_MM_S_M_S: {
    from: UnitType.TYPE_MM_S,
    to: UnitType.TYPE_M_S,
    factor: 0.001
  },
  TYPE_MM_S_IN_MIN: {
    from: UnitType.TYPE_MM_S,
    to: UnitType.TYPE_IN_MIN,
    factor: 0.0393701
  },
  TYPE_MM_S_KM_H: {
    from: UnitType.TYPE_MM_S,
    to: UnitType.TYPE_KM_H,
    factor: 0.0036
  },
  TYPE_MM_S_MM_MIN: {
    from: UnitType.TYPE_MM_S,
    to: UnitType.TYPE_MM_MIN,
    factor: 60.0
  },
  TYPE_MM_S_M_MIN: {
    from: UnitType.TYPE_MM_S,
    to: UnitType.TYPE_M_MIN,
    factor: 0.06
  },

  TYPE_MM_NM: { from: UnitType.TYPE_MM, to: UnitType.TYPE_NM, factor: 1000.0 },
  TYPE_MM_M: { from: UnitType.TYPE_MM, to: UnitType.TYPE_M, factor: 0.001 },
  TYPE_MM_CM: { from: UnitType.TYPE_MM, to: UnitType.TYPE_CM, factor: 0.1 },
  TYPE_MM_FT: {
    from: UnitType.TYPE_MM,
    to: UnitType.TYPE_FT,
    factor: 0.00328083
  },
  TYPE_MM_IN: {
    from: UnitType.TYPE_MM,
    to: UnitType.TYPE_IN,
    factor: 0.03936996
  },
  TYPE_MM_KM: {
    from: UnitType.TYPE_MM,
    to: UnitType.TYPE_KM,
    factor: 0.000001
  },

  TYPE_G_KG: { from: UnitType.TYPE_G, to: UnitType.TYPE_KG, factor: 0.001 },
  TYPE_G_MG: { from: UnitType.TYPE_G, to: UnitType.TYPE_MG, factor: 1000.0 },
  TYPE_G_N: { from: UnitType.TYPE_G, to: UnitType.TYPE_N, factor: 0.00981 },
  TYPE_G_ML: { from: UnitType.TYPE_G, to: UnitType.TYPE_ML, factor: 1 },
  TYPE_G_LB: {
    from: UnitType.TYPE_G,
    to: UnitType.TYPE_LB,
    factor: 0.00220462
  },

  TYPE_N_M_DAN_CM: {
    from: UnitType.TYPE_N_M,
    to: UnitType.TYPE_DAN_CM,
    factor: 0.001
  },
  TYPE_N_M_KGF_M: {
    from: UnitType.TYPE_N_M,
    to: UnitType.TYPE_KGF_M,
    factor: 0.1019716
  },
  TYPE_N_M_KN_M: {
    from: UnitType.TYPE_N_M,
    to: UnitType.TYPE_KN_M,
    factor: 0.001
  },
  TYPE_N_M_GF_CM: {
    from: UnitType.TYPE_N_M,
    to: UnitType.TYPE_GF_CM,
    factor: 10197.2
  },
  TYPE_N_M_KGF_CM: {
    from: UnitType.TYPE_N_M,
    to: UnitType.TYPE_KGF_CM,
    factor: 10.1971621
  },

  TYPE_BAR_KPA: {
    from: UnitType.TYPE_BAR,
    to: UnitType.TYPE_KPA,
    factor: 100.0
  },
  TYPE_BAR_KG_CM2: {
    from: UnitType.TYPE_BAR,
    to: UnitType.TYPE_KG_CM2,
    factor: 1.019716
  },
  TYPE_BAR_PSI: {
    from: UnitType.TYPE_BAR,
    to: UnitType.TYPE_PSI,
    factor: 14.50377
  },
  TYPE_BAR_MBAR: {
    from: UnitType.TYPE_BAR,
    to: UnitType.TYPE_MBAR,
    factor: 1000.0
  },

  TYPE_C_F: { from: UnitType.TYPE_C, to: UnitType.TYPE_F, factor: 33.8 },

  TYPE_J_M2_FTLB_IN2: {
    from: UnitType.TYPE_J_M2,
    to: UnitType.TYPE_FTLB_IN2,
    factor: 0.000475783
  },
  TYPE_J_M2_MFTLB_IN2: {
    from: UnitType.TYPE_J_M2,
    to: UnitType.TYPE_MFTLB_IN2,
    factor: 0.475782662
  },
  TYPE_J_M2_MJ_IN2: {
    from: UnitType.TYPE_J_M2,
    to: UnitType.TYPE_MJ_IN2,
    factor: 0.64516129
  },
  TYPE_J_M2_KG_CM_IN2: {
    from: UnitType.TYPE_J_M2,
    to: UnitType.TYPE_KG_CM_IN2,
    factor: 0.00658
  },

  TYPE_J_NM: {
    from: UnitType.TYPE_J,
    to: UnitType.TYPE_N_M,
    factor: 1
  }
};

export const getByUnits = (
  from: (typeof UnitType)[keyof typeof UnitType],
  to: (typeof UnitType)[keyof typeof UnitType]
): number => {
  if (from === to) {
    return 1;
  }
  const leftRight = Object.values(unitConversion).find(
    (value) => value.from === from && value.to === to
  );
  if (leftRight) {
    return leftRight.factor;
  }
  const rightLeft = Object.values(unitConversion).find(
    (value) => value.from === to && value.to === from
  );
  if (rightLeft) {
    return 1 / rightLeft.factor;
  }

  const fromMain = Object.values(unitConversion).find(
    (value) => value.to === from
  );

  const toMain = Object.values(unitConversion).find((value) => value.to === to);

  const hasSameFromCode = fromMain.from.code === toMain.from.code;
  if (fromMain && toMain && hasSameFromCode) {
    return toMain.factor / fromMain.factor;
  }

  return null;
};

export default UnitConversionType;
