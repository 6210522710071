import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function MachinesIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20'
    viewBox: '0 0 24 24'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M17.833 9.085v8.167H6.167V9.085zm-1.166 1.167H7.333v5.833h9.334zM14.932 1.5H3.833v4.037h15.671zm6.401 19.253h-1.75v2.334a.58.58 0 0 1-.583.58.58.58 0 0 1-.583-.58v-2.334H5.583v2.334a.58.58 0 0 1-.583.58.58.58 0 0 1-.583-.58v-2.334h-1.75V6.703H.917c-.322 0-.584-.26-.584-.536 0-.369.262-.63.584-.63h1.75V.333h12.702l.131.19 5.833 5.014h1.75c.323 0 .584.261.584.63 0 .276-.261.537-.584.537h-1.75zM20.167 6.75H3.833v12.836h16.334z" />
    </SvgIcon>
  );
}
