const RaLabeled = (palette) => ({
  styleOverrides: {
    root: {
      flexDirection: 'column !important',
      '& .RaLabeled-label': {
        color: palette.dark.grey,
        fontSize: '14px'
        //fontWeight: '600'
      },
      '& .MuiTextField-root': {
        width: '100% !important'
      },
      '& .MuiFormControl-root label': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root legend': {
        display: 'none'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
        height: 'auto !important'
      }
    }
  }
});

export default RaLabeled;
