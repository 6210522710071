import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type ModalHeaderProps = {
  title?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  onClose: () => void;
};

export default function ModalHeader({ title, onClose }: ModalHeaderProps) {
  return (
    <div className={`modal__header ${!title ? 'no-title' : ''}`}>
      <Typography variant="h3" className="modal__title">
        {title}
      </Typography>
      <IconButton disableRipple onClick={onClose} className="modal__close">
        <CloseIcon viewBox="-4 0 24 24" />
      </IconButton>
    </div>
  );
}
