import { Edit, useTranslate } from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { PatternGroupsPatternVersionShowForm } from './PatternGroupsPatternVersionShowForm';
import { useParams } from 'react-router-dom';

export const PatternGroupsPatternVersionShow = () => {
  const t = useTranslate();
  const { patternGroupId } = useParams<'patternGroupId'>();
  const { patternId } = useParams<'patternId'>();
  const { id } = useParams<'id'>();

  return (
    <Edit
      actions={<CustomToolbar title={t('resources.versions.name', 1)} />}
      resource="versions"
      redirect={`/pattern-groups/${patternGroupId}/patterns/${patternId}/versions/${id}`}
      mutationMode="pessimistic"
    >
      <PatternGroupsPatternVersionShowForm confirm />
    </Edit>
  );
};
