import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function EditIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20'
    viewBox: '3 3 20 20'
  };
  return (
    <SvgIcon {...propsMerged}>
      <g>
        <path d="M16.370894,4.3728061 C16.5769125,4.33511871 16.7888638,4.38170625 16.9602213,4.50153885 L19.1638101,6.03596122 C19.5194081,6.28348087 19.606866,6.77237691 19.359362,7.12784144 L12.8035685,16.5456525 C12.7337154,16.6474454 12.6407809,16.7307045 12.5333503,16.7890247 L9.89739787,18.2255534 C9.78123823,18.2893177 9.65159165,18.3219822 9.52065795,18.3219822 C9.36109817,18.3219822 9.20444704,18.2731883 9.07276865,18.1814135 C8.82875184,18.0117834 8.70152708,17.7183891 8.74447456,17.4246802 L9.17698352,14.452448 C9.1945807,14.3313895 9.24009884,14.2160457 9.30949077,14.117044 L15.8671692,4.69688542 C15.9859182,4.52591946 16.167739,4.4092509 16.370894,4.3728061 Z M16.5642806,5.44404283 L10.1592806,14.6460428 L9.79528058,17.1420428 L12.0092806,15.9350428 L18.4152806,6.73304283 L16.5642806,5.44404283 Z"></path>
        <path d="M12.6052367,4.36025388 C12.8813791,4.3602627 13.1052296,4.58412748 13.1052207,4.86026985 C13.1052119,5.13641223 12.8813471,5.3602627 12.6052048,5.36025388 L6,5.36004283 C5.71645785,5.36004283 5.49997373,5.56215324 5.49997373,5.79369926 L5.49997373,19.545338 C5.49997373,19.7768831 5.71650155,19.9790516 6.00001994,19.9790834 L18,19.9795619 C18.2835586,19.9795619 18.5000427,19.7774653 18.5000427,19.5459362 L18.5000427,14.1607067 C18.5000427,13.8845643 18.7239003,13.6607067 19.0000427,13.6607067 C19.2761851,13.6607067 19.5000427,13.8845643 19.5000427,14.1607067 L19.5000427,19.5459362 C19.5000427,20.3457041 18.8210598,20.9795619 17.9999737,20.9795619 L5.99994395,20.9790834 C5.17892278,20.9789914 4.49997373,20.3450676 4.49997373,19.545338 L4.49997373,5.79369926 C4.49997373,4.99393076 5.17894258,4.36004283 6.00001598,4.36004283 L12.6052367,4.36025388 Z"></path>
      </g>
    </SvgIcon>
  );
}
