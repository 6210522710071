const defaultMessages = {
  ra: {
    action: {
      add: 'Añadir',
      add_filter: 'Añadir filtro',
      back: 'Ir atrás',
      bulk_actions:
        '1 item seleccionado |||| %{smart_count} items seleccionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpiar valor',
      clone: 'Clonar',
      close: 'Cerrar',
      close_menu: 'Cerrar menú',
      confirm: 'Confirmar',
      create: 'Crear',
      create_item: 'Crear %{item}',
      delete: 'Eliminar',
      edit: 'Editar',
      expand: 'Expandir',
      export: 'Exportar',
      list: 'Listar',
      move_up: 'Mover arriba',
      move_down: 'Mover abajo',
      open_menu: 'Abrir menú',
      refresh: 'Refrescar',
      remove: 'Borrar',
      remove_filter: 'Borrar filtro',
      save: 'Guardar',
      search: 'Buscar',
      select_all: 'Seleccionar todo',
      select_row: 'Seleccionar esta fila',
      show: 'Mostrar',
      sort: 'Ordenar',
      undo: 'Deshacer',
      unselect: 'Deseleccionar',
      update: 'Actualizar'
    },
    auth: {
      auth_check_error: 'Por favor inicie sesión para continuar',
      logout: 'Cerrar Sesión',
      password: 'Contraseña',
      sign_in: 'Iniciar sesión',
      sign_in_error: 'La autenticación falló, por favor, vuelva a intentarlo',
      user_menu: 'Perfil',
      username: 'Usuario'
    },
    boolean: {
      true: 'Sí',
      false: 'No',
      null: ' '
    },
    configurable: {
      customize: 'Personalizar'
    },
    input: {
      file: {
        upload_several:
          'Arrastre algunos archivos para subir o haga clic para seleccionarlos.',
        upload_single:
          'Arrastre un archivo para subir o haga clic para seleccionarlo.'
      },
      image: {
        upload_several:
          'Arrastre algunas imagénes para subir o haga clic para seleccionarlas.',
        upload_single:
          'Arrastre alguna imagen para subir o haga clic para seleccionarla.'
      },
      references: {
        all_missing: 'No se pueden encontrar datos de referencias.',
        many_missing:
          'Al menos una de las referencias asociadas parece no estar disponible.',
        single_missing: 'La referencia asociada no parece estar disponible.'
      },
      password: {
        toggle_visible: 'Ocultar contraseña',
        toggle_hidden: 'Mostrar contraseña'
      }
    },
    message: {
      about: 'Acerca de',
      are_you_sure: '¿Está seguro?',
      bulk_delete_content:
        '¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?',
      bulk_delete_title:
        'Eliminar %{name} |||| Eliminar %{smart_count} %{name} items',
      bulk_update_content:
        '¿Seguro que quiere actualizar este %{name}? |||| ¿Seguro que quiere actualizar estos %{smart_count} items?',
      bulk_update_title:
        'Actualizar %{name} |||| Actualizar %{smart_count} %{name} items',
      delete_content: '¿Seguro que quiere eliminar este item?',
      delete_title: 'Eliminar %{name} #%{id}',
      details: 'Detalles',
      error:
        'Se produjo un error en el cliente y su solicitud no se pudo completar',
      invalid_form:
        'El formulario no es válido. Por favor verifique si hay errores',
      loading: 'La página se está cargando, espere un momento por favor',
      no: 'No',
      not_found:
        'O bien escribió una URL incorrecta o siguió un enlace incorrecto.',
      yes: 'Sí',
      unsaved_changes:
        'Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?'
    },
    navigation: {
      next: 'Siguiente',
      no_more_results:
        'El número de página %{page} está fuera de los límites. Pruebe la página anterior.',
      no_results: 'No se han encontrado resultados',
      page_out_from_begin: 'No puede ir antes de la página 1',
      page_out_from_end: 'No puede ir después de la última página',
      page_out_of_boundaries: 'Número de página %{page} fuera de los límites',
      page_range_info: '%{offsetBegin} - %{offsetEnd} de %{total}',
      page_rows_per_page: 'Filas por página:',
      prev: 'Anterior',
      skip_nav: 'Saltar al contenido'
    },
    sort: {
      sort_by: 'Ordenar por %{field} %{order}',
      ASC: 'ascendente',
      DESC: 'descendente'
    },
    notification: {
      bad_item: 'Elemento incorrecto',
      canceled: 'Acción cancelada',
      created: 'Elemento creado',
      data_provider_error:
        'Error del proveedor de datos. Consulte la consola para más detalles.',
      deleted: 'Elemento borrado |||| %{smart_count} elementos borrados.',
      http_error: 'Error de comunicación con el servidor',
      item_doesnt_exist: 'El elemento no existe',
      logged_out: 'Su sesión ha finalizado, vuelva a conectarse.',
      updated:
        'Elemento actualizado |||| %{smart_count} elementos actualizados',
      i18n_error:
        'No se pudieron cargar las traducciones para el idioma especificado',
      not_authorized: 'No tiene autorización para acceder a este recurso.'
    },
    page: {
      create: 'Crear %{name}',
      dashboard: 'Tablero',
      edit: '%{name} #%{id}',
      empty: 'Sin %{name} todavía.',
      error: 'Algo salió mal',
      invite: '¿Quiere agregar una?',
      list: 'Lista de %{name}',
      loading: 'Cargando',
      not_found: 'No encontrado',
      show: '%{name} #%{id}'
    },
    validation: {
      email: 'Debe ser un correo electrónico válido',
      maxLength: 'Debe contener %{max} caracteres o menos',
      maxValue: 'Debe ser %{max} o menos',
      minLength: 'Debe contener %{min} caracteres al menos',
      minValue: 'Debe ser al menos %{min}',
      number: 'Debe ser un número',
      oneOf: 'Debe ser uno de: %{options}',
      regex: 'Debe coincidir con un formato específico (regexp): %{force}',
      required: 'Requerido'
    }
  }
};

const messages = {
  ...defaultMessages,
  validation: {
    date: 'Debe ser una fecha válida',
    regexPattern: 'Debe tener un formato válido',
    'file-invalid-type': 'Tipo de archivo inválido'
  },
  auth: {
    login: {
      title: 'Iniciar sesión',
      fields: {
        username: 'Usuario o email',
        password: 'Contraseña',
        submit: 'Iniciar sesión'
      },
      page: {
        forgotPassword: '¿Olvidó su contraseña?'
      },
      errors: {
        invalidCredentials: 'Credenciales no válidas'
      }
    },
    forgotPassword: {
      title: '¿Olvidó su contraseña?',
      fields: {
        username: 'Usuario o email',
        submit: 'Restablecer contraseña'
      },
      page: {
        backToLogin: 'Volver al inicio de sesión'
      },
      errors: {
        request: 'Se produjo un error al enviar la solicitud',
        notFound: 'Usuario no encontrado'
      },
      success:
        'Se ha enviado un correo electrónico para restablecer su contraseña'
    },
    resetPassword: {
      title: 'Restablecer contraseña',
      fields: {
        password: 'Contraseña',
        passwordRepeat: 'Repite la contraseña',
        submit: 'Restablecer contraseña'
      },
      errors: {
        passwordMismatch: 'Las contraseñas no coinciden',
        request: 'Se produjo un error al enviar la solicitud'
      },
      success: 'Su contraseña se ha restablecido correctamente'
    }
  },
  cache: {
    sync: 'Sincronizar datos',
    syncing: 'Sincronizando datos'
  },
  serviceWorker: {
    newVersion:
      'Hay una nueva versión disponible. Por favor, recarga la página para aplicarla.'
  },
  offline: {
    msg: 'No es posible ver este contenido sin conexión a internet'
  },
  errors: {
    unrecognized_error: 'Error no reconocido'
  },
  general: {
    download: 'Descargar',
    form: {
      dropZone: {
        image: {
          message: 'Arrastra aquí la imagen'
        },
        file: {
          message: 'Arrastra aquí el archivo'
        }
      }
    },
    lang: {
      es: 'Español',
      en: 'Inglés'
    },
    message: {
      save_title: '¿Seguro que quiere guardar este %{name}?',
      save_content: 'Guardar %{name} #%{id}'
    }
  },
  filters: {
    date: {
      start: 'Comienzo',
      end: 'Fin'
    }
  },
  resources: {
    users: {
      name: 'Usuario |||| Usuarios',
      breadcrumb: {
        all: 'Todos los usuarios',
        edit: 'Editar',
        create: 'Crear'
      },
      fields: {
        username: 'Usuario',
        firstName: 'Nombre',
        lastName: 'Apellidos',
        email: 'Email',
        password: 'Contraseña',
        tags: 'Etiquetas',
        role: 'Rol',
        position: 'Cargo',
        signingKey: 'Firma',
        signingKeyPass: 'Contraseña de la firma'
      },
      errors: {
        create: 'No se pudo crear el usuario',
        edit: 'No se pudo actualizar el usuario'
      },
      search: {
        label: 'Búsqueda'
      }
    },
    machines: {
      name: 'Máquina |||| Máquinas',
      fields: {
        code: 'Código',
        name: 'Nombre',
        patternGroup: 'Grupo de patrones',
        hasExtraField: 'Tiene Campo Extra',
        checklist: 'Checklist',
        checklistName: 'Nombre del checklist %{code}',
        task: 'Tarea',
        observations: 'Observaciones',
        image: 'Imagen',
        observationsLang: 'Observaciones %{lang}',
        nameLang: 'Nombre máquina %{lang}',
        procedureNoIns: 'Procedimiento No Ins',
        lastUpdate: 'Ultima revisión'
      },
      form: {
        tabs: {
          magnitudes: 'Magnitudes y checklist',
          procedure: 'Procedimiento'
        },
        buttons: {
          addPatternGroup: 'Añadir grupo de patrones',
          addChecklist: 'Añadir checklist'
        }
      },
      breadcrumb: {
        all: 'Todas las máquinas',
        edit: 'Editar máquina',
        create: 'Crear máquina'
      },
      search: {
        label: 'Búsqueda'
      }
    },
    clients: {
      name: 'Cliente |||| Clientes',
      fields: {
        name: 'Nombre',
        tag: 'Etiqueta',
        erpCode: 'Código ERP'
      },
      breadcrumb: {
        all: 'Todos los clientes'
      },
      search: {
        label: 'Buscar'
      },
      success: {
        create: 'Creado correctamente',
        edit: 'Editado correctamente'
      },
      errors: {
        create: 'Error creando cliente'
      },
      modal: {
        add: 'Añadir cliente',
        edit: 'Editar cliente'
      }
    },
    'client-machines': {
      name: 'Máquina de cliente |||| Máquinas de cliente',
      fields: {
        name: 'Máquina',
        calibrated: 'Calibrada',
        date: 'Fecha',
        serialNumber: 'Número de serie',
        lastCertificateNumber: 'Último número de certificado'
      },
      calibrated: {
        true: 'Calibrada',
        false: 'No calibrada'
      },
      modal: {
        add: 'Añadir máquina',
        transferClient: {
          title: 'Transferir máquina',
          description:
            'Esta maquina se va a transferir a otro cliente. Los certificados también se van a transferir.',
          fields: {
            client: 'Cliente'
          },
          success: {
            message: 'Máquina transferida'
          },
          errors: {
            message: 'Error transfiriendo máquina'
          }
        }
      }
    },
    'pattern-groups': {
      name: 'Grupo de patrón |||| Grupos de patrones',
      breadcrumb: {
        all: 'Todos los grupos de patrones'
      },
      fields: {
        name: 'Nombre',
        magnitude: 'Magnitud'
      },
      modal: {
        add: 'Añadir grupo de patrones'
      },
      success: {
        create: 'Grupo de patrones creado correctamente.'
      },
      errors: {
        create: 'Error al crear el grupo de patrones.'
      },
      buttons: {
        selectPatterns: 'Seleccionar patrones'
      },
      titles: {
        selectedPatterns: 'Patrones seleccionados'
      }
    },
    patterns: {
      name: 'Patrón |||| Patrones',
      fields: {
        name: 'Nombre',
        code: 'Código',
        nameLang: 'Nombre %{lang}',
        required: 'Requerido en PDF'
      },
      modal: {
        add: 'Añadir patrón',
        edit: 'Editar patrón'
      },
      success: {
        create: 'Patrón creado correctamente.',
        edit: 'Patrón editado correctamente.'
      },
      errors: {
        create: 'Error al crear el patrón.',
        name: {
          unique: 'El nombre del patrón ya existe.'
        },
        code: {
          unique: 'El código del patrón ya existe.'
        }
      }
    },
    versions: {
      name: 'Versión |||| Versiones',
      forcedCaseName: 'versión |||| versiones',
      fields: {
        name: 'Nombre',
        value: 'Valor',
        uncertainty: 'Incertidumbre',
        unit: 'Unidad',
        activatedAt: 'Activado'
      },
      modal: {
        add: 'Añadir versión',
        edit: 'Editar versión'
      },
      success: {
        create: 'Versión creada correctamente.',
        edit: 'Versión editada correctamente.'
      },
      errors: {
        create: 'Error al crear la versión.',
        edit: 'Error al editar la versión.'
      }
    },
    certificates: {
      name: 'Certificado |||| Certificados',
      forcedCaseName: 'certificado |||| certificados',
      fields: {
        certificateNumber: 'N° Certificado',
        date: 'Fecha',
        user: 'Usuario',
        checklist: 'Checklist',
        manufacturer: 'Fabricante',
        model: 'Modelo',
        serialNumber: 'Número de serie',
        code: 'Código',
        temperature: 'Temperatura',
        humidity: 'Humedad',
        manualSign: 'Firma manual',
        observationsLang: 'Observaciones %{lang}',
        magnitude: {
          scale: 'Rango medida / Escala',
          scaleDivision: 'División de escala',
          resolution: 'Resolución',
          indicator: 'Dispositivo indicador',
          satNote: 'Nota SAT',
          clientObservation: 'Observación - Cliente (%{lang})'
        },
        status: 'Estado:',
        pending: 'Pendiente',
        done: 'Terminado',
        customId: 'Id',
        measurement: 'Mesurando',
        pattern: 'Patrón',
        value: 'Valor %{value}',
        measurementResult: 'Mesurando',
        measurementResultUnit: 'Mesurando (%{unit})',
        patternResult: 'Patrón',
        patternResultUnit: 'Patrón (%{unit})',
        errorResult: 'Error',
        errorResultUnit: 'Error (%{unit})',
        siResult: 'Si',
        siResultUnit: 'Si (%{unit})',
        liResult: 'Li',
        liResultUnit: 'Li (%{unit})',
        revisedAt: 'Revisado el',
        revisionInterval: 'Intervalo de revisión',
        unit: 'Unidad',
        displayValuesId: 'Mostrar ID de valores'
      },
      download: {
        confirm: {
          title: 'Existen cambios sin guardar',
          content: '¿Estás seguro que quieres descargar este certificado?'
        },
        errors: {
          missingSignature:
            'No se encontró una firma para firmar el certificado',
          invalidSignaturePassword: 'Contraseña del certificado inválida',
          failedToDownload: 'No se pudo descargar el certificado'
        }
      },
      success: {
        create: 'Certificado creado correctamente',
        edit: 'Certificado editado correctamente'
      },
      errors: {
        create: 'Error al crear certificado',
        certificateNumber: {
          unique: 'El número del certificado ya existe',
          toLarge: 'El número no puede ser mayor que 2147483647',
          toSmall: 'El número no puede ser menor que -2147483647'
        }
      },
      tabs: {
        generalData: 'Datos generales'
      },
      breadcrumb: {
        create: 'Crear',
        edit: 'Editar'
      },
      title: {
        add: 'Añadir certificado',
        edit: 'Editar certificado'
      },
      warnings: {
        thisMachineHasNewChecklists: 'Esta máquina tiene nuevas checklists',
        valuesChanged:
          'Los valores han cambiado y los resultado no corresponden a los datos introducidos. Actualiza para recarcular los resultados.'
      },
      buttons: {
        update: 'Actualizar'
      },
      modal: {
        title: 'Descargar certificado',
        language: 'Idioma'
      }
    }
  }
};

export default messages;
