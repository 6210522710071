import {
  Datagrid,
  List,
  RaRecord,
  TextField,
  useNotify,
  useRefresh,
  useTranslate
} from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { useParams } from 'react-router-dom';
import { ListEmpty } from '../shared/ListEmpty';
import { useState } from 'react';
import CreateDialog from '../shared/CreateDialog';
import { ListDelete } from '../shared/ListDelete';
import { ListBulkActions } from '../shared/ListBulkActions';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { ListEdit } from '../shared/ListEdit';
import EditDialog from '../shared/EditDialog';
import { PatternGroupsPatternForm } from './patternGroupsPatternShared/PatternGroupsPatternForm';
import { PatternNameField } from '../shared/PatternNameField';
import { getPatternName, transformToApi } from '../../core/utils/pattern-util';
import { ListPagination } from '../shared/ListPagination';

export const PatternGroupsPatternsList = () => {
  const { patternGroupId } = useParams<'patternGroupId'>();
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editingRecord, setEditingRecord] = useState<RaRecord>();

  const handleClickCreate = () => {
    setShowCreateDialog(true);
  };

  const handleCreateSuccess = () => {
    notify('resources.patterns.success.create', {
      type: 'success'
    });
    refresh();
  };

  const handleEditSuccess = () => {
    notify('resources.patterns.success.edit', {
      type: 'success'
    });
    refresh();
  };

  const handleError = () => {
    notify('resources.patterns.errors.create', { type: 'error' });
  };

  const handleClickEdit = (record: RaRecord) => {
    setEditingRecord(record);
  };

  return (
    <>
      <CreateDialog
        show={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        mutationOptions={{
          onSuccess: handleCreateSuccess,
          onError: handleError
        }}
        title={t('resources.patterns.modal.add')}
        resource="patterns"
        transform={(data) => transformToApi(data, patternGroupId)}
      >
        <PatternGroupsPatternForm />
      </CreateDialog>
      <EditDialog
        resource="patterns"
        show={!!editingRecord}
        onClose={() => setEditingRecord(undefined)}
        mutationOptions={{
          onSuccess: handleEditSuccess,
          onError: handleError
        }}
        title={t('resources.patterns.modal.edit')}
        record={editingRecord}
        transform={(data) => transformToApi(data)}
      >
        <PatternGroupsPatternForm />
      </EditDialog>
      <List
        hasCreate
        actions={
          <CustomToolbar
            title={t('resources.patterns.name', 2)}
            onClickCreate={handleClickCreate}
          />
        }
        resource="patterns"
        filter={{ pattern_group_hash: patternGroupId }}
        empty={<ListEmpty onCreateClick={handleClickCreate} />}
        pagination={<ListPagination />}
      >
        <Datagrid
          rowClick={(id) => `${id}/versions`}
          className="table-action"
          bulkActionButtons={<ListBulkActions />}
          header={
            <ListHeader
              columns={
                <>
                  <TableCell source="name" />
                  <TableCell source="code" />
                </>
              }
              editColumn
              deleteColumn
            />
          }
        >
          <PatternNameField />
          <TextField source="code" />
          <ListEdit onClick={handleClickEdit} />
          <ListDelete
            redirect={`/pattern-groups/${patternGroupId}/patterns`}
            formatter={getPatternName}
          />
        </Datagrid>
      </List>
    </>
  );
};
