import { Labeled, SelectInput, SelectInputProps } from 'react-admin';
import useMagnitudeUnits from '../../core/hooks/useMagnitudeUnits';

type MagnitudeUnitInputProps = Omit<SelectInputProps, 'source'> & {
  source?: string;
  magnitudeCode: string;
};

export default function MagnitudeUnitInput({
  source = 'unit',
  magnitudeCode,
  ...props
}: MagnitudeUnitInputProps) {
  const { data: units } = useMagnitudeUnits(magnitudeCode);
  return (
    <Labeled className="labeled-input">
      <SelectInput {...props} source={source} choices={units ?? []} />
    </Labeled>
  );
}
