import {
  CreateCertificateBody,
  mapDBCertificateToCreateCertificateBody,
  mapDBCertificateToUpdateCertificateBody,
  UpdateCertificateBody
} from './certificates';
import { DexieExt } from './db';
import axiosInstance, {
  getDefaultRequestConfig
} from '../core/services/base/axios.client';

export default async function sync(db: DexieExt) {
  const bodies = await getNewCertificates(db);
  for (const body of bodies) {
    await axiosInstance.post('/certificates', body, getDefaultRequestConfig());
  }

  const editedCertificates = await getEditedCertificates(db);
  await Promise.all(
    editedCertificates.map((c) =>
      axiosInstance.put(`/certificates/${c.id}`, c, getDefaultRequestConfig())
    )
  );

  await db.clear();
}

async function getNewCertificates(
  db: DexieExt
): Promise<CreateCertificateBody[]> {
  const ids = (await db.newCertificates.toArray()).map((c) => c.id);
  const tuples = await Promise.all(
    ids.map(async (id) => {
      const [certificate, details, patternGroups, checklists] =
        await Promise.all([
          db.certificates.get(id),
          db.certificatesDetails.get(id),
          db.certificatePatternGroups
            .where('certificateId')
            .equals(id)
            .toArray(),
          db.certificateChecklists.where('certificateId').equals(id).toArray()
        ]);

      return [certificate!, details!, patternGroups, checklists] as const;
    })
  );

  tuples.sort((a, b) => a[0].createdAt.localeCompare(b[0].createdAt));

  const bodies = tuples.map(
    ([certificate, details, patternGroups, checklists]) =>
      mapDBCertificateToCreateCertificateBody(
        certificate,
        details,
        patternGroups,
        checklists
      )
  );

  return bodies;
}

async function getEditedCertificates(
  db: DexieExt
): Promise<UpdateCertificateBody[]> {
  const ids = (await db.editedCertificates.toArray()).map((c) => c.id);
  const tuples = await Promise.all(
    ids.map(async (id) => {
      const [certificate, details, patternGroups, checklists] =
        await Promise.all([
          db.certificates.get(id),
          db.certificatesDetails.get(id),
          db.certificatePatternGroups
            .where('certificateId')
            .equals(id)
            .toArray(),
          db.certificateChecklists.where('certificateId').equals(id).toArray()
        ]);

      return [certificate!, details!, patternGroups, checklists] as const;
    })
  );

  const bodies = tuples.map(
    ([certificate, details, patternGroups, checklists]) =>
      mapDBCertificateToUpdateCertificateBody(
        certificate,
        details,
        patternGroups,
        checklists
      )
  );

  return bodies;
}
