import { Button, useTranslate } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import usePatterns from '../../../../core/hooks/usePatterns';
import { getPatternName } from '../../../../core/utils/pattern-util';
import { FormCertificate } from '../../../../core/models/certificate';
import { useFormContext } from 'react-hook-form';

type CertificateMagnitudeFormPatternsProps = {
  patternIds: string[];
  onRemove: (id: string) => void;
};

export default function CertificateMagnitudeFormPatterns({
  patternIds,
  onRemove
}: CertificateMagnitudeFormPatternsProps) {
  const t = useTranslate();

  const { watch } = useFormContext<FormCertificate>();
  const isCertificateBlocked = watch('done');

  const { data: patterns, isLoading } = usePatterns({ ids: patternIds });
  if (isLoading) return null;
  if (patterns.length === 0) return null;

  return (
    <>
      <div className="selected-patterns-header">
        {t('resources.pattern-groups.titles.selectedPatterns')}
      </div>
      {patterns
        ?.sort((a, b) => getPatternName(a).localeCompare(getPatternName(b)))
        .map((pattern) => (
          <div
            style={{ display: 'flex' }}
            className="selected-patterns-list-item"
            key={pattern.id}
          >
            <p>{`${getPatternName(pattern)} (${pattern.code})`}</p>
            <Button
              endIcon={<CloseIcon />}
              onClick={() => onRemove(pattern.id)}
              disabled={isCertificateBlocked}
              className="button-over-circle"
            />
          </div>
        ))}
    </>
  );
}
