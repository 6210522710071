import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useLocaleState } from 'ra-core';
import { DexieExt } from '../../service-worker/db';
import { NetworkStatusProvider } from './NetworkStatusProvider';
import { OfflineDBProvider } from './OfflineDbProvider';
import ServiceWorkerNotificationProvider from './ServiceWorkerNotificationProvider';

const db = new DexieExt('cache');

type AppProviderProps = {
  children: React.ReactNode;
};

export default function AppProvider({ children }: AppProviderProps) {
  const [locale] = useLocaleState();

  return (
    <NetworkStatusProvider>
      <OfflineDBProvider db={db}>
        <ServiceWorkerNotificationProvider>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={locale}
          >
            {children}
          </LocalizationProvider>
        </ServiceWorkerNotificationProvider>
      </OfflineDBProvider>
    </NetworkStatusProvider>
  );
}
