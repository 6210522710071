import { DeleteWithConfirmButton, useRecordContext } from 'react-admin';
import DeleteIcon from './icons/DeleteIcon';
import { get } from '../../core/utils/collection-utils';

type Props = {
  nameAttr?: string;
  redirect?: string;
  formatter?: (any) => any;
};

export const ListDelete = ({ nameAttr, redirect, formatter }: Props) => {
  const recordContext = useRecordContext();
  const record = nameAttr ? get(nameAttr, recordContext) : recordContext;
  const id = formatter ? formatter(record) : record;

  return (
    <DeleteWithConfirmButton
      className="delete-button"
      icon={<DeleteIcon />}
      translateOptions={{ id }}
      label=""
      redirect={redirect}
    />
  );
};
