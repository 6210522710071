import { useState } from 'react';
import {
  Button,
  CheckboxGroupInput,
  SearchInput,
  Toolbar,
  useTranslate
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounce } from 'usehooks-ts';
import { DEBOUNCE_DELAY } from '../../../../core/constants/debounce.constants';
import usePatternsByPatternGroup from '../../../../core/hooks/usePatternsByPatternGroup';
import CreateDialog from '../../../shared/CreateDialog';
import { getPatternName } from '../../../../core/utils/pattern-util';

type CertificatePatternsDialogProps = {
  show: boolean;
  onClose: () => void;
  onSave: (data: string[]) => void;
  patternIds: string[];
  patternGroupId: string;
};

export default function CertificatePatternsDialog({
  show,
  onClose,
  onSave,
  patternIds,
  patternGroupId
}: CertificatePatternsDialogProps) {
  const methods = useForm({ defaultValues: { patternIds: patternIds } });

  const [previousPatternIds, setPreviousPatternIds] = useState(patternIds);
  if (previousPatternIds !== patternIds) {
    setPreviousPatternIds(patternIds);
    methods.reset({ patternIds: patternIds });
  }

  const translate = useTranslate();
  const [patternSearchTerm, setPatternSearchTerm] = useState('');
  const debouncedPatternSearchTerm = useDebounce<string>(
    patternSearchTerm,
    DEBOUNCE_DELAY
  );

  const { data: filteredPatterns } = usePatternsByPatternGroup({
    patternGroupId,
    searchTerm: debouncedPatternSearchTerm,
    _sort: 'name'
  });

  function handleSave() {
    const data = methods.getValues('patternIds');
    onClose();
    onSave(data);
  }

  return (
    <CreateDialog
      show={show}
      title={translate('resources.pattern-groups.buttons.selectPatterns')}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <SearchInput
          source="patternSearchTerm"
          onChange={(event: any) =>
            setPatternSearchTerm(event?.target?.value ?? '')
          }
          className="pattern-search"
        />
        <CheckboxGroupInput
          source="patternIds"
          label=""
          choices={(filteredPatterns || [])
            .sort((a, b) => getPatternName(a).localeCompare(getPatternName(b)))
            .map((pattern) => ({
              ...pattern,
              name: `${getPatternName(pattern)} (${pattern.code})`
            }))}
          row={false}
          className="pattern-checkbox-group"
        />
        <Toolbar className="footertoolbar">
          <Button
            label="ra.action.save"
            variant="contained"
            onClick={handleSave}
          />
        </Toolbar>
      </FormProvider>
    </CreateDialog>
  );
}
