const RaTabbedShowLayout = (palette, defaultPadding) => ({
  styleOverrides: {
    root: {
      width: '100%',
      '& .RaTabbedShowLayout-content': {
        backgroundColor: palette.light.grey,
        padding: defaultPadding
      }
    }
  }
});

export default RaTabbedShowLayout;
