const RaSidebar = (palette) => ({
  styleOverrides: {
    root: {
      '& .RaSidebar-paperAnchorLeft': {
        backgroundColor: palette.secondary.main
      }
    }
  }
});

export default RaSidebar;
