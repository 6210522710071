import { Button, useTranslate } from 'react-admin';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { FormCertificate } from '../../../../core/models/certificate';
import { Alert } from '@mui/material';

type Props = {
  hashNewChecklists: boolean;
};

export const NewChecklistWarning = ({ hashNewChecklists }: Props) => {
  const t = useTranslate();
  const { getValues, setValue } = useFormContext<FormCertificate>();
  const [isUpdated, setIsUpdated] = useState(false);

  const handleUpdateClick = () => {
    setValue('checklists', getValues('machineChecklists'), {
      shouldDirty: true
    });
    setIsUpdated(true);
  };

  if (hashNewChecklists && !isUpdated) {
    return (
      <Alert
        color="warning"
        icon={<WarningAmberIcon />}
        action={
          <Button
            color="warning"
            label={t('resources.certificates.buttons.update')}
            variant="outlined"
            onClick={handleUpdateClick}
          />
        }
      >
        {t('resources.certificates.warnings.thisMachineHasNewChecklists')}
      </Alert>
    );
  }
};
