import { Edit, useNotify, useRecordContext, useTranslate } from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { UserForm } from './usersShared/usersSharedForm/UserForm';
import { transformToApi } from '../../core/utils/user-util';

const UserTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  if (record == null) {
    return null;
  }

  return (
    <span>{`${translate('resources.users.name', { smart_count: 1 })} #${
      record.username
    }`}</span>
  );
};

export const UserEdit = () => {
  const notify = useNotify();
  const handleError = () => {
    notify('resources.users.errors.edit', { type: 'error' });
  };

  const transform = async (data) => await transformToApi(data);

  return (
    <Edit
      title={<UserTitle />}
      mutationOptions={{ onError: handleError }}
      mutationMode="pessimistic"
      actions={<CustomToolbar />}
      transform={transform}
    >
      <UserForm />
    </Edit>
  );
};
