import { LANGS } from '../../providers/i18nProvider';
import { push, reduce } from './collection-utils';
import { findLangByCode } from './language-utils';
import { keys } from './object-utils';

export const getPatternName = (record): string =>
  LANGS.map(
    (language) =>
      findLangByCode(record?.languages || [], language.code)?.name ?? ''
  ).join(' / ');

export const transformFromApi = (record) => ({
  ...record,
  required: [record?.required ? 'required' : null],
  name: transformFromName(record?.languages ?? [])
});

const transformFromName = (languages) =>
  reduce(
    (arr: any, elem: any) => Object.assign(arr, { [elem.code]: elem.name }),
    {},
    languages ?? []
  );

export const transformToApi = (data, patternGroupId?) => ({
  ...data,
  ...(patternGroupId ? { patternGroupHash: patternGroupId } : {}),
  required: data.required?.includes('required') ?? false,
  languages: transformToLanguages(data.name)
});

const transformToLanguages = (names) => {
  let languages = [];

  keys(names).forEach((key) => {
    const code = key;
    const name = names[key];

    const existingLanguage = findLangByCode(languages, code);

    if (existingLanguage) {
      existingLanguage.name = name;
    } else {
      languages = push(languages, { code, name });
    }
  });

  return languages;
};
