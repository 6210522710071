import { SimpleForm, Labeled, AutocompleteInput } from 'react-admin';
import FormFooterToolbar from '../general/FormFooterToolbar';
import useGetAll from '../../core/hooks/useGetAll';
import { getMachineName } from '../../core/utils/machine-util';

export default function ClientMachineCreateForm() {
  const { data: machines } = useGetAll('machines');
  const choices = machines
    ?.map((machine) => ({ id: machine.id, name: getMachineName(machine) }))
    ?.sort((m1, m2) => m1.name.localeCompare(m2.name));

  return (
    <SimpleForm toolbar={<FormFooterToolbar />}>
      <Labeled className="labeled-input">
        <AutocompleteInput
          source="machine"
          choices={choices ?? []}
          label="resources.client-machines.fields.name"
        />
      </Labeled>
    </SimpleForm>
  );
}
