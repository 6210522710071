import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Labeled, useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';

type Props = {
  source?: string;
  label?: string;
  defaultValue?: DateTime;
  required?: boolean;
  value?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  onChange?: (value: DateTime) => void;
  disabled?: boolean;
};

export default function DatePickerInput({
  source,
  label,
  defaultValue = null,
  required,
  value,
  minDate,
  maxDate,
  onChange,
  disabled = false
}: Props) {
  const t = useTranslate();
  const {
    field,
    formState: { errors }
  } = useController({
    name: source ?? '',
    defaultValue,
    rules: required
      ? {
          required: t('ra.validation.required'),
          validate: (value) => {
            if (value.invalid) return t('validation.date');
          }
        }
      : {}
  });

  function handleChange(value: DateTime) {
    field.onChange(value);
    onChange?.(value);
  }

  return (
    <Labeled className="labeled-input">
      <DatePicker
        {...field}
        className="datepicker"
        label={label}
        value={value ?? field.value}
        defaultValue={defaultValue}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            error: !!errors[source]?.message,
            helperText: (errors[source]?.message as string) ?? ' '
          }
        }}
        onChange={handleChange}
        disabled={disabled}
      />
    </Labeled>
  );
}
