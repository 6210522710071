import {
  CheckboxGroupInput,
  Labeled,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate
} from 'react-admin';
import FormFooterToolbar from '../../general/FormFooterToolbar';
import { checkPatternExists } from '../../../core/services/patterns-api.service';
import { LANGS } from '../../../providers/i18nProvider';
import { transformFromApi } from '../../../core/utils/pattern-util';

function validatePatternCodeIsUnique(original?: string) {
  return async (code: string) => {
    const { exists } = await checkPatternExists({ code });
    if (original !== undefined && original === code) return undefined;
    if (exists) return 'resources.patterns.errors.code.unique';
  };
}

export const PatternGroupsPatternForm = () => {
  const record = useRecordContext();
  const t = useTranslate();

  return (
    <SimpleForm
      toolbar={<FormFooterToolbar />}
      record={transformFromApi(record)}
    >
      <div className="fieldset fieldset-observations">
        {LANGS.map((language) => (
          <Labeled
            key={crypto.randomUUID()}
            label={t('resources.patterns.fields.nameLang', {
              lang: language.code
            })}
            className="labeled-input"
          >
            <TextInput source={`name.${language.code}`} validate={required()} />
          </Labeled>
        ))}
      </div>
      <Labeled className="labeled-input">
        <TextInput
          source="code"
          validate={validatePatternCodeIsUnique(record?.code)}
        />
      </Labeled>
      <CheckboxGroupInput
        source="required"
        label=""
        choices={[
          { id: 'required', name: t('resources.patterns.fields.required') }
        ]}
        row={false}
        className="pattern-checkbox-group"
      />
    </SimpleForm>
  );
};
