import { NumberInputProps, TextInput } from 'react-admin';
import { formatDecimal } from '../../core/utils/number-utils';

export const NumberInput = (props: NumberInputProps) => {
  return (
    <TextInput
      {...props}
      format={(value) => value?.toString().replace('.', ',')}
      parse={(value) => formatDecimal(value?.toString())}
    />
  );
};
