import { Button, SaveButton, Toolbar } from 'react-admin';

type Props = {
  className?: string;
  onSaveClick?: () => void;
};

export default function FormFooterToolbar({ className, onSaveClick }: Props) {
  return (
    <Toolbar className={`footertoolbar ${className ?? ''}`}>
      {!onSaveClick && (
        <SaveButton
          type="button"
          label="ra.action.save"
          variant="contained"
          icon={null}
        />
      )}
      {onSaveClick && (
        <Button
          type="button"
          label="ra.action.save"
          variant="contained"
          onClick={() => onSaveClick()}
        />
      )}
    </Toolbar>
  );
}
