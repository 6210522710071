import { isEmpty } from './string-utils';

// TODO: handle array values
export function query(obj: Record<string, unknown>): string {
  const queryString = Object.entries(obj)
    .filter(([, value]) => value !== undefined && value !== null)
    .flatMap(([key, value]) =>
      Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
    )
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return isEmpty(queryString) ? '' : `${queryString}`;
}

export function singleQueryParam(
  param: string | string[] | undefined
): string | undefined {
  return Array.isArray(param) ? param[0] : param;
}
