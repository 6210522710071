import { CacheUnit } from '../../core/models/cache';
import { Unit } from '../../core/models/unit';
import { DBUnit } from './types';

export function mapCacheUnitToDBUnit(unit: CacheUnit): DBUnit {
  return {
    id: unit.id as Unit['id'],
    name: unit.name,
    symbol: unit.symbol
  };
}

export function mapDBUnitToUnit({ id, name, symbol }: DBUnit): Unit {
  return { id, name, symbol };
}
