type WeccType = { measurements: number; w: number; value: number };

const weccData: Record<string, WeccType> = {
  V_2_1: { measurements: 2, w: 1, value: 1.8 },
  V_2_2: { measurements: 2, w: 2, value: 7.0 },
  V_2_3: { measurements: 2, w: 3, value: 78.6 },
  V_3_1: { measurements: 3, w: 1, value: 1.3 },
  V_3_2: { measurements: 3, w: 2, value: 2.3 },
  V_3_3: { measurements: 3, w: 3, value: 6.4 },
  V_4_1: { measurements: 4, w: 1, value: 1.2 },
  V_4_2: { measurements: 4, w: 2, value: 1.7 },
  V_4_3: { measurements: 4, w: 3, value: 3.0 },
  V_5_1: { measurements: 5, w: 1, value: 1.1 },
  V_5_2: { measurements: 5, w: 2, value: 1.4 },
  V_5_3: { measurements: 5, w: 3, value: 2.2 },
  V_6_1: { measurements: 6, w: 1, value: 1.1 },
  V_6_2: { measurements: 6, w: 2, value: 1.3 },
  V_6_3: { measurements: 6, w: 3, value: 1.8 },
  V_7_1: { measurements: 7, w: 1, value: 1.1 },
  V_7_2: { measurements: 7, w: 2, value: 1.3 },
  V_7_3: { measurements: 7, w: 3, value: 1.6 },
  V_8_1: { measurements: 8, w: 1, value: 1.1 },
  V_8_2: { measurements: 8, w: 2, value: 1.2 },
  V_8_3: { measurements: 8, w: 3, value: 1.5 },
  V_9_1: { measurements: 9, w: 1, value: 1.0 },
  V_9_2: { measurements: 9, w: 2, value: 1.2 },
  V_9_3: { measurements: 9, w: 3, value: 1.4 }
};

export default WeccType;

export function getByMeasurement(measurements: number): WeccType[] {
  return Object.values(weccData).filter(
    (item) => item.measurements === measurements
  );
}

export function getByW(w: number): WeccType[] {
  return Object.values(weccData).filter((item) => item.w === w);
}

export function getByMeasurementAndW(
  measurements: number,
  w: number
): WeccType | undefined {
  return Object.values(weccData).find(
    (item) => item.measurements === measurements && item.w === w
  );
}
