import clsx from 'clsx';
import { FormTabProps, TabbedForm } from 'react-admin';
import { useWatch } from 'react-hook-form';
import {
  FormCertificate,
  FormCertificatePatternGroup
} from '../../../../core/models/certificate';
import CertificateFormPatternGroupTabCloseIcon from './CertificateFormPatternGroupTabCloseIcon';
import CertificateMagnitudeForm from './CertificateMagnitudeForm';

type CertificateFormPatternGroupTabProps = Omit<
  FormTabProps,
  'position' | 'label'
> & {
  onCloseClick: () => void;
  patternGroup: FormCertificatePatternGroup;
  position: number;
};

export default function CertificateFormPatternGroupTab({
  onCloseClick,
  patternGroup,
  position,
  ...props
}: CertificateFormPatternGroupTabProps) {
  const done = useWatch<FormCertificate>({
    name: `patternGroups.${position}.done`,
    defaultValue: false
  });

  return (
    <TabbedForm.Tab
      {...props}
      className={clsx({ finished: done })}
      label={patternGroup.magnitude.name}
      icon={
        <CertificateFormPatternGroupTabCloseIcon
          onClick={onCloseClick}
          position={position}
        />
      }
      iconPosition="end"
    >
      <CertificateMagnitudeForm
        patternGroup={patternGroup}
        position={position}
      />
    </TabbedForm.Tab>
  );
}
