import { FormEvent, useState } from 'react';
import { Link, useLogin, useNotify, useTranslate } from 'react-admin';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import logo from '../assets/images/logo-idm-test.png';

export const CustomLoginPage = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    // will call authProvider.login({ email, password })
    login({ usernameOrEmail, password }).catch(() =>
      notify('auth.login.errors.invalidCredentials', {
        type: 'error'
      })
    );
  };

  return (
    <div className="RaLogin-root">
      <div className="RaLogin-card">
        <div className="logo-container">
          <img src={logo} alt="IDM test" width="150" height="83" />
        </div>
        <Typography
          variant="h3"
          className="title-medium"
          sx={{ marginBottom: '20px' }}
        >
          {translate('auth.login.title')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <input
            name="usernameOrEmail"
            placeholder={translate('auth.login.fields.username')}
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
          />
          <input
            name="password"
            type="password"
            placeholder={translate('auth.login.fields.password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" type="submit" fullWidth>
            {translate('auth.login.fields.submit')}
          </Button>
        </form>

        <Link
          to="/forgot-password"
          sx={{ marginTop: '20px', display: 'inline-block' }}
        >
          {translate('auth.login.page.forgotPassword')}
        </Link>
      </div>
    </div>
  );
};
