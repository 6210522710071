import {
  ArrayInput,
  Button,
  SimpleFormIterator,
  TextInput,
  useTranslate
} from 'react-admin';
import DeleteIcon from './icons/DeleteIcon';
import { LANGS } from '../../providers/i18nProvider';
import CheckboxInput from './CheckboxInput';
import { useFormContext } from 'react-hook-form';
import { FormCertificate } from '../../core/models/certificate';

type Props = {
  withDoneCheckbox?: boolean;
};

export default function ChecklistsInputs({ withDoneCheckbox = false }: Props) {
  const t = useTranslate();

  const { watch } = useFormContext<FormCertificate>();
  const isCertificateBlocked = watch('done');

  return (
    <ArrayInput label=" " source="checklists">
      <SimpleFormIterator
        disableReordering
        disableClear
        className="custom-iterator custom-iterator-vertical"
        removeButton={<DeleteIcon />}
        addButton={
          <Button
            variant="outlined"
            size="medium"
            disabled={isCertificateBlocked}
          >
            <>{t('resources.machines.form.buttons.addChecklist')}</>
          </Button>
        }
        disableAdd={isCertificateBlocked}
        disableRemove={isCertificateBlocked}
      >
        <div className="custom-iterator-header">
          {LANGS.map((language) => (
            <div key={language.code}>
              {t('resources.machines.fields.checklistName', {
                code: language.code
              })}
            </div>
          ))}
        </div>
        {LANGS.map((language) => (
          <TextInput
            label=" "
            source={`name.${language.code}`}
            key={language.code}
            disabled={isCertificateBlocked}
          />
        ))}
        <ArrayInput label=" " source="elements">
          <SimpleFormIterator
            disableReordering
            disableClear
            className="custom-iterator custom-iterator-horizontal"
            removeButton={<DeleteIcon />}
            sx={{
              marginLeft: '-16px',
              marginTop: '10px'
            }}
            disableAdd={isCertificateBlocked}
            disableRemove={isCertificateBlocked}
          >
            <span className="custom-iterator-row-label">
              {t('resources.machines.fields.task')}
            </span>
            {LANGS.map((language) => (
              <TextInput
                label=" "
                source={`translations.${language.code}`}
                key={language.code}
                disabled={isCertificateBlocked}
              />
            ))}
            {withDoneCheckbox && (
              <CheckboxInput
                source="done"
                label=""
                disabled={isCertificateBlocked}
              />
            )}
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
