import * as R from 'ramda';

import { sum } from './number-utils';
import { isNullOrUndefined } from './object-utils';
import { compare } from './string-utils';

export const compareStringProperties = R.curry(
  (property: string, reverse: boolean, a: any, b: any) => {
    const reverseFactor = reverse ? -1 : 1;

    return reverseFactor * compare(a[property], b[property]);
  }
);

export const push = <T>(collection: T[], element: T) =>
  Array.isArray(collection) ? [...collection, element] : [element];

export const findIndex = <T>(
  predicate: (element: T) => boolean,
  collection: T[]
) => (Array.isArray(collection) ? collection.findIndex(predicate) : -1);

export const filter = R.curry(
  <T>(
    predicate: (element: T, index: number) => boolean,
    collection: T[]
  ): T[] => (Array.isArray(collection) ? collection.filter(predicate) : [])
);

export const findElementByPropertyValue = (
  elements: any[],
  property: string,
  value: any
) =>
  Array.isArray(elements)
    ? elements.find((element) => element[property] === value)
    : null;

export const unique = <T>(collection: T[]) => Array.from(new Set(collection));

export const sumUpValues = (values: number[]) =>
  Array.isArray(values) ? values.reduce(sum, 0) : null;

export const mapCollection = <T, U>(
  mapFunction: (element: T, i?: number, array?: T[]) => U,
  collection: T[]
) => (Array.isArray(collection) ? collection.map(mapFunction) : []);

export const intArray = (value: number) =>
  Array.from({ length: value }, (_v, k) => k);

export const length = <T>(collection: T[]) =>
  !isNullOrUndefined(collection) && Array.isArray(collection)
    ? collection.length
    : 0;

export const concat = <T>(...collections: T[][]) =>
  Array.isArray(collections)
    ? collections.reduce(
        (acc: T[], col: T[]) =>
          Array.isArray(col) ? [...acc, ...col] : [...acc],
        []
      )
    : [];

export const isEmpty = <T>(collection: T[]) => !length(collection);

export const reduce = R.curry(
  <T, V>(
    reductionFunction: (reduced: V, element: T) => V,
    initialValue: V,
    collection: T[]
  ) =>
    Array.isArray(collection)
      ? collection.reduce(reductionFunction, initialValue)
      : null
);

export const merge = <T>(a: T[], b: T[]) =>
  Array.isArray(a) && Array.isArray(b)
    ? [...a, ...b]
    : Array.isArray(a)
    ? [...a]
    : Array.isArray(b)
    ? [...b]
    : [];

export const allEqual = <T>(collection: T[]) =>
  Array.isArray(collection)
    ? collection.every((element, i, arr) => element === arr[0])
    : false;

export const limit = <T>(count: number, collection: T[]): T[] =>
  Array.isArray(collection) ? collection.slice(0, count) : [];

export const existsIndex = <T>(index: number, collection: T[]): boolean =>
  index > -1 && index < length(collection);

export const some = <T>(collection: T[], array: any[]): boolean => {
  if (array === undefined) {
    return false;
  }
  return collection.some((element) => array.includes(element));
};

export const sort = <T>(
  collection: T[],
  sortFunction: (a: any, b: any) => any
) => (Array.isArray(collection) ? collection.sort(sortFunction) : []);

export const get = R.curry(
  <T>(path: string, obj: T): T =>
    path.split('.').reduce((acc, part) => acc && acc[part], obj)
);

export const every = R.curry(<T>(pred: (value: T) => boolean, values: T[]) =>
  values.every(pred)
);

export const removeElementByIndex = R.curry(
  <T>(position: number, collection: T[] | null): T[] =>
    Array.isArray(collection)
      ? collection.filter((_, index) => index !== position)
      : []
);

export const zip2 = <T, U>(arr1: T[], arr2: U[]): [T, U][] => {
  return R.zip(arr1, arr2);
};

export const zip3 = <T1, T2, T3>(
  arr1: T1[],
  arr2: T2[],
  arr3: T3[]
): [T1, T2, T3][] => {
  const limit = Math.min(arr1.length, arr2.length, arr3.length);

  const acc = [];
  for (let i = 0; i < limit; i++) {
    acc.push([arr1[i], arr2[i], arr3[i]]);
  }

  return acc;
};

export const zip4 = <T1, T2, T3, T4>(
  arr1: T1[],
  arr2: T2[],
  arr3: T3[],
  arr4: T4[]
): [T1, T2, T3, T4][] => {
  const limit = Math.min(arr1.length, arr2.length, arr3.length, arr4.length);

  const acc = [];
  for (let i = 0; i < limit; i++) {
    acc.push([arr1[i], arr2[i], arr3[i], arr4[i]]);
  }

  return acc;
};

export const filterMap = <T, U>(
  arr: T[],
  fn: (value: T) => U | undefined | null
): U[] => {
  return arr.map(fn).filter((x) => x !== null && x !== undefined);
};

export const last = <T>(collection: T[]) => collection[collection.length];
