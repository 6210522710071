import { push } from './collection-utils';
import { transformToBase64 } from './file-utils';
import { findLangByCode } from './language-utils';
import { keys } from './object-utils';

export const transformToApi = async (data, patternId) => {
  return await {
    ...data,
    patternHash: patternId,
    unitCode: data.unit,
    languages: await transformToLanguages(data.pdf)
  };
};

const transformToLanguages = async (pdfs) => {
  let languages = [];

  await Promise.all(
    keys(pdfs).map(async (key) => {
      const code = key;
      const pdf = pdfs[key];

      const existingLanguage = findLangByCode(languages, code);

      const base64File = await transformToBase64(pdf);

      if (existingLanguage) {
        existingLanguage.pdfFile = base64File;
      } else {
        languages = push(languages, {
          code,
          pdfFile: base64File
        });
      }
    })
  );

  return languages;
};
