import { useRecordContext } from 'react-admin';
import { getPatternName } from '../../core/utils/pattern-util';

type Props = {
  source?: string;
};

export const PatternNameField = ({ source }: Props) => {
  const contextRecord = useRecordContext();
  const record = source ? contextRecord[source] : contextRecord;

  const name = getPatternName(record);

  return <span>{name}</span>;
};
