import jsonServerProvider from 'ra-data-json-server';
import { DataProvider, fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { flattenObject } from '../core/utils/ fetch.utils';

export default function customDataProvider(
  apiUrl: any,
  httpClient = fetchUtils.fetchJson
): DataProvider {
  return {
    ...jsonServerProvider(apiUrl, httpClient),
    getList: async (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        ...flattenObject(params.filter),
        _start: (page - 1) * perPage,
        _end: page * perPage,
        _sort: field,
        _order: order
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const { headers, json } = await httpClient(url);
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response.'
        );
      }
      return {
        data: json,
        total: parseInt(headers.get('x-total-count') as string, 10)
      };
    },
    getAll: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
        data: json
      }));
    }
  };
}
