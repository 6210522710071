import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export const editAtom = atom(false);

export default function EditListener() {
  const {
    reset,
    formState: { isDirty, isValid }
  } = useFormContext();
  const setEdit = useSetAtom(editAtom);
  const [previous, setPrevious] = useState(isDirty);
  if (isDirty !== previous) {
    setPrevious(isDirty);
    setEdit(isDirty);
  }

  const edit = useAtomValue(editAtom);
  const [previousEdit, setPreviousEdit] = useState(false);

  if (previousEdit !== edit) {
    setPreviousEdit(edit);
    if (!edit && isValid) {
      reset({}, { keepValues: true });
    }
  }

  return <></>;
}
