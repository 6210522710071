import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';

export default function useGetAll(resource: string) {
  const dataProvider = useDataProvider();
  return useQuery(
    [resource, 'getAll'],
    () => dataProvider.getAll(resource).then(({ data }) => data),
    { refetchOnWindowFocus: false }
  );
}
