import { Edit, useNotify, useRefresh, useTranslate } from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { CertificateForm } from './certificatesShared/certificatesSharedForm/CertificateForm';
import { useParams } from 'react-router-dom';
import { transformToApi } from '../../core/utils/certificate-util';
import useDetailedCertificate from '../../core/hooks/useDetailedCertificate';
import { FormCertificate } from '../../core/models/certificate';
import DownloadCertificateModal from './certificatesShared/DownloadCertificateModal';

export const CertificateEdit = () => {
  const { id: certificateId } = useParams();
  const t = useTranslate();
  const refresh = useRefresh();

  const notify = useNotify();
  const onSuccess = () => {
    notify('resources.certificates.success.edit', { type: 'success' });
  };

  const { data: certificate } = useDetailedCertificate(certificateId);
  if (!certificate) return null;

  const transform = (data: FormCertificate) => transformToApi(data);

  return (
    <Edit
      redirect={false}
      actions={
        <CustomToolbar title={t('resources.certificates.title.edit')}>
          <DownloadCertificateModal
            certificateId={certificateId}
            onDownload={() => refresh()}
          />
        </CustomToolbar>
      }
      mutationOptions={{ onSuccess }}
      resource="certificates"
      mutationMode="pessimistic"
      transform={transform}
    >
      <CertificateForm certificate={certificate} confirm />
    </Edit>
  );
};
