import { useFieldArray } from 'react-hook-form';
import { FormCertificatePatternGroup } from '../../../../core/models/certificate';
import { PatternGroup } from '../../../../core/models/pattern-group';

type CertificateAddTabPatternGroupProps = {
  patternGroup: PatternGroup;
  onGroupClick: (
    group: FormCertificatePatternGroup,
    e: React.MouseEvent
  ) => void;
};

export default function CertificateAddTabPatternGroup({
  patternGroup,
  onGroupClick
}: CertificateAddTabPatternGroupProps) {
  const { append } = useFieldArray({ name: 'patternGroups' });
  function handleGroupClick(group: PatternGroup, e: React.MouseEvent) {
    const formGroup: FormCertificatePatternGroup = {
      patternGroupId: group.id,
      magnitude: group.magnitude,
      satNote: null,
      done: false,
      patternIds: [],
      values: [],
      results: [],
      hasExtraField: false,
      patternResultPrimary: false
    };

    append(formGroup);
    onGroupClick(formGroup, e);
  }

  return (
    <li onClick={(e) => handleGroupClick(patternGroup, e)}>
      {patternGroup.name}
    </li>
  );
}
