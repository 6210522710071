import { useQuery } from 'react-query';
import { getPatternGroupUnits } from '../services/units-api.service';
import queryKeys from './query-keys';

export default function usePatternGroupUnits(patternGroupId: string) {
  return useQuery(
    queryKeys.patternGroups.units(patternGroupId),
    () => getPatternGroupUnits(patternGroupId).then(({ data }) => data),
    { refetchOnWindowFocus: false }
  );
}
