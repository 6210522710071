import decodeJwt from 'jwt-decode';
import { AuthProvider, UserIdentity } from 'react-admin';
import jwtManager from './jwtManager';
import axiosInstance, {
  getDefaultAnonymousRequestConfig
} from '../core/services/base/axios.client';
import jwtDecode from 'jwt-decode';

const apiUrl = process.env.REACT_APP_API_URL;
const authProvider: AuthProvider = {
  login: login(),
  logout: logout(),
  checkAuth: checkAuth(),
  checkError: checkError(),
  getPermissions: getPermissions(),
  getIdentity: getIdentity()
};

export default authProvider;

function getPermissions(): (params: any) => Promise<any> {
  return async () => {
    await jwtManager.waitForTokenRefresh();
    const token = jwtManager.getToken();
    let role = null;
    if (token) {
      const decodedToken: { privileges: string } = decodeJwt(token);
      role = decodedToken.privileges;
    }
    return role ? Promise.resolve(role) : Promise.reject();
  };
}

function checkError(): (error: any) => Promise<void> {
  return async (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      jwtManager.deleteToken();
      return Promise.reject();
    }

    return Promise.resolve();
  };
}

function checkAuth(): (params: any) => Promise<void> {
  return async () => {
    await jwtManager.waitForTokenRefresh();
    return jwtManager.getToken() ? Promise.resolve() : Promise.reject();
  };
}

function logout(): (params: any) => Promise<string | false | void> {
  return async () => {
    jwtManager.deleteToken();

    return '/login';
  };
}

function login(): (params: any) => Promise<any> {
  return async ({ usernameOrEmail, password, remindUser = true }) => {
    const response = await axiosInstance.post(
      `${apiUrl}/auth/login`,
      JSON.stringify({ usernameOrEmail: usernameOrEmail, password }),
      {
        ...getDefaultAnonymousRequestConfig(),
        headers: {
          ...getDefaultAnonymousRequestConfig().headers,
          creadentials: 'include'
        }
      }
    );
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const { token, refreshToken } = response.data;
    if (remindUser) {
      return jwtManager.setToken(token, refreshToken);
    } else {
      return jwtManager.setToken(token, '');
    }
  };
}

function getIdentity(): () => Promise<UserIdentity> {
  return async () => {
    const decodedToken = jwtDecode(jwtManager.getToken());
    return {
      id: decodedToken['sub'],
      fullName: `${decodedToken['firstName']} ${decodedToken['lastName']}`
    };
  };
}
