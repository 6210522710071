const MuiOutlinedInput = () => ({
  styleOverrides: {
    root: {
      backgroundColor: '#FFF',
      input: {
        padding: '8.5px 14px'
      }
    }
  }
});

export default MuiOutlinedInput;
