const RaSimpleFormIterator = () => ({
  styleOverrides: {
    root: {
      width: '100%',
      marginBottom: '10px',
      '& .RaSimpleFormIterator-action': {
        visibility: 'visible'
      }
    }
  }
});

export default RaSimpleFormIterator;
