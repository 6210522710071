import { Client, FormClient } from '../models/client';

export const transformToApi = (data: Client): FormClient => {
  return {
    id: data.id,
    name: data.name,
    tagId: data.tag.id,
    erpCode: data.erpCode
  };
};
