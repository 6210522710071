import { Checkbox, FormControlLabel } from '@mui/material';
import { FieldTitle } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  source: string;
  label?: string;
  disabled?: boolean;
};

export default function CheckboxInput({
  source,
  label,
  disabled = false
}: Props) {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      label={<FieldTitle label={label} source={source} />}
      disabled={disabled}
      control={
        <Controller
          name={source}
          control={control}
          render={({ field: props }) => (
            <Checkbox
              {...props}
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}
              disabled={disabled}
            />
          )}
        />
      }
    />
  );
}
