import { Link, useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axiosInstance, {
  getDefaultAnonymousRequestConfig
} from '../core/services/base/axios.client';
import logo from '../assets/images/logo-idm-test.png';

type ForgotPasswordFields = {
  usernameOrEmail: string;
};

export default function ForgotPasswordPage() {
  const translate = useTranslate();
  const notify = useNotify();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordFields>();

  const onSubmit = async ({ usernameOrEmail }: ForgotPasswordFields) => {
    axiosInstance
      .post(
        '/auth/recover-password',
        JSON.stringify({ usernameOrEmail }),
        getDefaultAnonymousRequestConfig()
      )
      .then(function (response) {
        notify('auth.forgotPassword.success', { type: 'success' });
      })
      .catch(function (error) {
        if (error.response.status === 404) {
          notify('auth.forgotPassword.errors.notFound', {
            type: 'error'
          });
        } else {
          notify('auth.forgotPassword.errors.request', {
            type: 'error'
          });
        }
      });
  };

  return (
    <div className="RaLogin-root">
      <div className="RaLogin-card">
        <div className="logo-container">
          <img src={logo} alt="IDM test" width="150" height="83" />
        </div>
        <Typography
          variant="h3"
          className="title-medium"
          sx={{ marginBottom: '20px' }}
        >
          {translate('auth.forgotPassword.title')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder={translate('auth.forgotPassword.fields.username')}
            {...register('usernameOrEmail', {
              required: translate('ra.validation.required')
            })}
          />
          {errors.usernameOrEmail && (
            <span className="error">{errors.usernameOrEmail.message}</span>
          )}
          <Button variant="contained" type="submit" fullWidth>
            {translate('auth.forgotPassword.fields.submit')}
          </Button>
        </form>

        <Link to="/login" sx={{ marginTop: '20px', display: 'inline-block' }}>
          {translate('auth.forgotPassword.page.backToLogin')}
        </Link>
      </div>
    </div>
  );
}
