import {
  Datagrid,
  List,
  RaRecord,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useTranslate
} from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { useState } from 'react';
import { ListEdit } from '../shared/ListEdit';
import EditDialog from '../shared/EditDialog';
import CreateDialog from '../shared/CreateDialog';
import { ClientSharedForm } from './clientsShared/ClientSharedForm';
import { ListDelete } from '../shared/ListDelete';
import { ListBulkActions } from '../shared/ListBulkActions';
import { ListEmpty } from '../shared/ListEmpty';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { transformToApi } from '../../core/utils/client-util';
import CacheClientButton from '../clientMachines/CacheClientButton';
import { ListPagination } from '../shared/ListPagination';

export const ClientsList = () => {
  const refresh = useRefresh();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editingRecord, setEditingRecord] = useState<RaRecord>();
  const t = useTranslate();
  const notify = useNotify();

  const clientFilters = [
    <TextInput
      key="0"
      source="q"
      label="resources.clients.search.label"
      alwaysOn
    />
  ];

  const handleClickCreate = () => {
    setShowCreateDialog(true);
  };

  const handleClickEdit = (record: RaRecord) => {
    setEditingRecord(record);
  };

  const handleCreateSuccess = () => {
    notify('resources.clients.success.create', {
      type: 'success'
    });
    refresh();
  };

  const handleEditSuccess = () => {
    notify('resources.clients.success.edit', {
      type: 'success'
    });
    refresh();
  };

  const handleError = () => {
    notify('resources.clients.errors.create', { type: 'error' });
  };

  return (
    <>
      <List
        hasCreate
        actions={<CustomToolbar onClickCreate={handleClickCreate} />}
        filters={clientFilters}
        empty={<ListEmpty onCreateClick={handleClickCreate} />}
        pagination={<ListPagination />}
      >
        <Datagrid
          rowClick={(id) => `${id}/machines`}
          className="table-action"
          bulkActionButtons={<ListBulkActions />}
          header={
            <ListHeader
              columns={
                <>
                  <TableCell source="name" />
                  <TableCell source="tag" sortable={false} />
                  <TableCell source="erpCode" />
                  <TableCell className="cell-action" sortable={false} />
                </>
              }
              editColumn
              deleteColumn
            />
          }
        >
          <TextField source="name" />
          <TextField source="tag.name" />
          <TextField source="erpCode" />
          <CacheClientButton />
          <ListEdit onClick={handleClickEdit} />
          <ListDelete nameAttr="name" />
        </Datagrid>
      </List>

      <CreateDialog
        show={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        mutationOptions={{
          onSuccess: handleCreateSuccess,
          onError: handleError
        }}
        title={t('resources.clients.modal.add')}
        transform={transformToApi}
      >
        <ClientSharedForm />
      </CreateDialog>

      <EditDialog
        show={!!editingRecord}
        onClose={() => setEditingRecord(undefined)}
        mutationOptions={{
          onSuccess: handleEditSuccess,
          onError: handleError
        }}
        title={t('resources.clients.modal.edit')}
        record={editingRecord}
        transform={transformToApi}
      >
        <ClientSharedForm />
      </EditDialog>
    </>
  );
};
