const RaRadioButtonGroupInput = (palette) => ({
  styleOverrides: {
    root: {
      width: 'auto',
      '& .RaRadioButtonGroupInput-label': {
        position: 'relative',
        transform: 'none',
        lineHeight: '1.5',
        color: palette.dark.grey
      },
      '& .RaRadioButtonGroupInput-label span': {
        color: palette.dark.grey,
        fontSize: '14px',
        display: 'inline-block',
        marginBottom: '3px'
      }
    }
  }
});

export default RaRadioButtonGroupInput;
