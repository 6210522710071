import React, { useContext } from 'react';
import { DexieExt } from '../../service-worker/db';

const OfflineDbContext = React.createContext<DexieExt | null>(null);

export function useOfflineDb() {
  return useContext(OfflineDbContext);
}

type OfflineDbProviderProps = {
  children: React.ReactNode;
  db: DexieExt;
};

export function OfflineDBProvider({ children, db }: OfflineDbProviderProps) {
  return (
    <OfflineDbContext.Provider value={db}>{children}</OfflineDbContext.Provider>
  );
}
