import { DateTime } from 'luxon';
import { LANGS } from '../../providers/i18nProvider';
import { mapCollection, push } from './collection-utils';
import { length } from './collection-utils';
import { reduce } from './collection-utils';
import { transformToBase64 } from './file-utils';
import { findLangByCode } from './language-utils';
import { keys } from './object-utils';

export const getMachineName = (record): string =>
  LANGS.map(
    (language) =>
      findLangByCode(record?.languages || [], language.code)?.name ?? ''
  ).join(' / ');

export const transformFromApi = (record) => ({
  ...record,
  checklists: transformFromChecklists(record?.checklists ?? []),
  observation: transformFromObservation(record?.languages ?? []),
  name: transformFromName(record?.languages ?? []),
  procedure: {
    ...(record?.procedure ?? {}),
    lastUpdate: DateTime.fromISO(record?.procedure?.lastUpdate),
    blocks: transformFromProcedureBlocks(record?.procedure?.blocks ?? [])
  }
});

const transformFromChecklists = (checklists) => {
  return mapCollection(
    (checklist: any) => ({
      ...checklist,
      name: reduce(
        (arr: any, elem: any) => Object.assign(arr, { [elem.code]: elem.name }),
        {},
        checklist.languages
      ),
      elements: transformChecklistElements(checklist.languages)
    }),
    checklists
  );
};

const transformChecklistElements = (languages) => {
  const elements = [];

  for (let i = 0; i < length(languages); i++) {
    const language = languages[i];
    const languageElements = language.elements;

    for (let j = 0; j < length(languageElements); j++) {
      const element = languageElements[j];

      if (!elements[j]) {
        elements[j] = {};
      }
      elements[j].done = element.done;
      if (!elements[j].translations) {
        elements[j].translations = {};
      }
      elements[j].translations[language.code] = element.name;
    }
  }

  return elements;
};

const transformFromObservation = (languages) =>
  reduce(
    (arr: any, elem: any) =>
      Object.assign(arr, { [elem.code]: elem.observation }),
    {},
    languages ?? []
  );

const transformFromName = (languages) =>
  reduce(
    (arr: any, elem: any) => Object.assign(arr, { [elem.code]: elem.name }),
    {},
    languages ?? []
  );

const transformFromProcedureBlocks = (blocks) =>
  blocks.map((block) => ({
    ...block,
    languages: {
      text: transformFromProcedureBlockText(block.languages)
    }
  }));

const transformFromProcedureBlockText = (languages) =>
  reduce(
    (arr: any, elem: any) => Object.assign(arr, { [elem.code]: elem.text }),
    {},
    languages
  );

export const transformToApi = async (data) => {
  return await {
    ...data,
    patternGroups: transformToPatternGroups(data.patternGroups),
    checklists: transformToChecklists(data.checklists),
    image: await transformToBase64(data.image),
    languages: transformToLanguages(data.observation, data.name),
    procedure: {
      ...data.procedure,
      blocks: await transformToProcedureBlocks(data.procedure.blocks)
    }
  };
};

const transformToPatternGroups = (patternGroups) => {
  return mapCollection(
    (patternGroup: any) => ({
      ...patternGroup,
      patternGroupId: patternGroup.patternGroup.id
    }),
    patternGroups
  );
};

const transformToChecklists = (checklists) => {
  return mapCollection(
    (checklist: any) => ({
      ...checklist,
      languages: transformToChecklistLanguages(
        checklist.elements,
        checklist.name
      )
    }),
    checklists
  );
};

const transformToChecklistLanguages = (elements, names) => {
  let languages = [];

  elements.forEach((element) => {
    keys(element.translations).forEach((key) => {
      const code = key;
      const name = element.translations[key];

      const existingLanguage = findLangByCode(languages, code);

      if (existingLanguage) {
        existingLanguage.elements.push({ name });
      } else {
        languages = push(languages, { code, elements: [{ name }] });
      }
    });
  });

  keys(names).forEach((key) => {
    const code = key;
    const name = names[key];

    const existingLanguage = findLangByCode(languages, code);

    if (existingLanguage) {
      existingLanguage.name = name;
    } else {
      languages.push({ code, name });
    }
  });

  return languages;
};

const transformToLanguages = (observations, names) => {
  let languages = [];

  keys(observations).forEach((key) => {
    const code = key;
    const observation = observations[key];
    const name = names[key];

    const existingLanguage = findLangByCode(languages, code);

    if (existingLanguage) {
      existingLanguage.observation = observation;
      existingLanguage.name = name;
    } else {
      languages = push(languages, { code, observation, name });
    }
  });

  return languages;
};

const transformToProcedureBlocks = async (blocks) => {
  return await Promise.all(
    blocks.map(async (block) => ({
      ...block,
      image: await transformToBase64(block.image),
      languages: transformToProcedureLanguages(block.languages.text)
    }))
  );
};

const transformToProcedureLanguages = (blockLanguages) => {
  let languages = [];

  keys(blockLanguages).forEach((key) => {
    const code = key;
    const text = blockLanguages[key];

    const existingLanguage = findLangByCode(languages, code);

    if (existingLanguage) {
      existingLanguage.text = text;
    } else {
      languages = push(languages, { code, text });
    }
  });

  return languages;
};
