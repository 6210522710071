import { Link } from '@mui/material';
import { useGetOne, useTranslate } from 'react-admin';
import {
  BreadcrumbComponentProps,
  BreadcrumbsRoute
} from 'use-react-router-breadcrumbs';
import { useParams } from 'react-router-dom';
import { getMachineName } from '../../core/utils/machine-util';
import { getPatternName } from '../../core/utils/pattern-util';

const Users = () => {
  const t = useTranslate();
  return <Link href="#/users">{t('resources.users.breadcrumb.all')}</Link>;
};

const UsersEdit = () => {
  const t = useTranslate();
  return <>{t('resources.users.breadcrumb.edit')}</>;
};

const UsersCreate = () => {
  const t = useTranslate();
  return <>{t('resources.users.breadcrumb.create')}</>;
};

const Machines = () => {
  const t = useTranslate();
  return (
    <Link href="#/machines">{t('resources.machines.breadcrumb.all')}</Link>
  );
};

const MachinesEdit = () => {
  const t = useTranslate();
  return <>{t('resources.machines.breadcrumb.edit')}</>;
};

const MachinesCreate = () => {
  const t = useTranslate();
  return <>{t('resources.machines.breadcrumb.create')}</>;
};

const Clients = () => {
  const t = useTranslate();
  return <Link href="#/clients">{t('resources.clients.breadcrumb.all')}</Link>;
};

const PatternGroups = () => {
  const t = useTranslate();
  return (
    <Link href="#/pattern-groups">
      {t('resources.pattern-groups.breadcrumb.all')}
    </Link>
  );
};

function PatternGroupPatterns({ match }: BreadcrumbComponentProps) {
  const { data } = useGetOne('pattern-groups', {
    id: match.params.patternGroupId
  });
  if (!data) return null;
  return <Link href={`#${match.pathname}`}>{data.name}</Link>;
}

function PatternGroupPatternVersions({ match }: BreadcrumbComponentProps) {
  const { data } = useGetOne('patterns', { id: match.params.patternId });
  if (!data) return null;
  return <Link href={`#${match.pathname}`}>{getPatternName(data)}</Link>;
}

const PatternGroupPatternVersionValues = ({
  match
}: BreadcrumbComponentProps) => {
  const { data } = useGetOne('versions', { id: match.params.id });
  if (!data) return null;
  return <Link href={`#${match.pathname}`}>{data.name}</Link>;
};

function ClientMachines({ match }: BreadcrumbComponentProps) {
  const { clientId } = useParams();
  const { data } = useGetOne('clients', { id: clientId });
  if (!data) return null;

  return <Link href={`#${match.pathname}`}>{data.name}</Link>;
}

function Certificates({ match }: BreadcrumbComponentProps) {
  const { clientMachineId } = useParams();
  const { data: clientMachine } = useGetOne('client-machines', {
    id: clientMachineId
  });
  if (!clientMachine) return null;

  return (
    <Link href={`#${match.pathname}`}>
      {getMachineName(clientMachine.machine)}
    </Link>
  );
}

function CertificatesCreate() {
  const t = useTranslate();
  return <>{t('resources.certificates.breadcrumb.create')}</>;
}

function CertificatesEdit({ match }: BreadcrumbComponentProps) {
  const t = useTranslate();
  return <>{t('resources.certificates.breadcrumb.edit')}</>;
}

const routes: BreadcrumbsRoute[] = [
  { path: '/users', breadcrumb: Users },
  { path: '/users/:id', breadcrumb: UsersEdit },
  { path: '/users/create', breadcrumb: UsersCreate },
  { path: '/machines', breadcrumb: Machines },
  { path: '/machines/:id', breadcrumb: MachinesEdit },
  { path: '/machines/create', breadcrumb: MachinesCreate },
  { path: '/clients', breadcrumb: Clients },
  { path: '/clients/:clientId/machines', breadcrumb: ClientMachines },
  {
    path: '/clients/:clientId/machines/:clientMachineId/certificates',
    breadcrumb: Certificates
  },
  {
    path: '/clients/:clientId/machines/:clientMachineId/certificates/create',
    breadcrumb: CertificatesCreate
  },
  {
    path: '/clients/:clientId/machines/:clientMachineId/certificates/:id',
    breadcrumb: CertificatesEdit
  },
  { path: '/pattern-groups', breadcrumb: PatternGroups },
  {
    path: '/pattern-groups/:patternGroupId/patterns',
    breadcrumb: PatternGroupPatterns
  },
  {
    path: '/pattern-groups/:patternGroupId/patterns/:patternId/versions',
    breadcrumb: PatternGroupPatternVersions
  },
  {
    path: '/pattern-groups/:patternGroupId/patterns/:patternId/versions/:id',
    breadcrumb: PatternGroupPatternVersionValues
  }
];

export default routes;
