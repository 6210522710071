import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axiosInstance, {
  getDefaultAnonymousRequestConfig
} from '../core/services/base/axios.client';
import logo from '../assets/images/logo-idm-test.png';

type ResetPasswordFields = {
  password: string;
  passwordRepeat: string;
};

export default function ResetPasswordPage() {
  const translate = useTranslate();
  const notify = useNotify();
  const { hash } = useParams<{ hash: string }>();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<ResetPasswordFields>();

  const onSubmit = async ({
    password,
    passwordRepeat
  }: ResetPasswordFields) => {
    axiosInstance
      .put(
        '/auth/reset-password',
        JSON.stringify({ hash, password, passwordRepeat }),
        getDefaultAnonymousRequestConfig()
      )
      .then(function (response) {
        notify('auth.resetPassword.success', { type: 'success' });
      })
      .catch(function (error) {
        notify('auth.resetPassword.errors.request', {
          type: 'error'
        });
      });
  };

  return (
    <div className="RaLogin-root">
      <div className="RaLogin-card">
        <div className="logo-container">
          <img src={logo} alt="IDM test" width="150" height="83" />
        </div>
        <Typography
          variant="h3"
          className="title-medium"
          sx={{ marginBottom: '20px' }}
        >
          {translate('auth.resetPassword.title')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder={translate('auth.resetPassword.fields.password')}
            type="password"
            {...register('password', {
              required: translate('ra.validation.required')
            })}
          />
          {errors.password && (
            <span className="error">{errors.password.message}</span>
          )}

          <input
            placeholder={translate('auth.resetPassword.fields.passwordRepeat')}
            type="password"
            {...register('passwordRepeat', {
              required: translate('ra.validation.required'),
              validate: (value) =>
                value === getValues('password') ||
                translate('auth.resetPassword.errors.passwordMismatch')
            })}
          />
          {errors.passwordRepeat && (
            <span className="error">{errors.passwordRepeat.message}</span>
          )}

          <Button variant="contained" type="submit" fullWidth>
            {translate('auth.resetPassword.fields.submit')}
          </Button>
        </form>
      </div>
    </div>
  );
}
