import { CacheMagnitude } from '../../core/models/cache';
import { Magnitude } from '../../core/models/magnitude';
import { mapCacheUnitToDBUnit, mapDBUnitToUnit } from '../units';
import { DBMagnitude } from './types';

export function mapCacheMagnitudeToDBMagnitude(
  magnitude: CacheMagnitude
): DBMagnitude {
  return {
    id: magnitude.id,
    name: magnitude.name,
    units: magnitude.units.map(mapCacheUnitToDBUnit)
  };
}

export function mapDBMagnitudeToMagnitude({
  id,
  name,
  units
}: DBMagnitude): Magnitude {
  return { id, name, units: units.map(mapDBUnitToUnit) };
}
