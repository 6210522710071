import { Pagination, PaginationProps, useListContext } from 'react-admin';
import { filter } from '../../core/utils/collection-utils';

const DEFAULT_PER_PAGE_OPTIONS: number[] = [5, 10, 25, 50];

export const ListPagination = (props: PaginationProps) => {
  const { total: maxPerPageOption } = useListContext();
  const filteredOptions: number[] = filter(
    (option: number) => !maxPerPageOption || option < maxPerPageOption,
    DEFAULT_PER_PAGE_OPTIONS
  );
  const perPageOptions = [
    ...filteredOptions,
    ...(maxPerPageOption ? [maxPerPageOption] : [])
  ];
  return <Pagination rowsPerPageOptions={perPageOptions} {...props} />;
};
