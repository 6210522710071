import { hasKeys, isArray, isBuffer, isObject } from './object-utils';

const isValidObject = (value: any) => {
  if (!value) {
    return false;
  }
  return (
    !isArray(value) && !isBuffer(value) && isObject(value) && hasKeys(value)
  );
};

export const flattenObject: any = (value: any, path: string[] = []) => {
  if (isValidObject(value)) {
    return Object.assign(
      {},
      ...Object.keys(value).map((key: any) =>
        flattenObject(value[key], path.concat([key]))
      )
    );
  } else {
    return path.length ? { [path.join('.')]: value } : value;
  }
};
