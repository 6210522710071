import { SaveButton, TabbedForm, Toolbar, useRefresh } from 'react-admin';
import { CertificateFormGeneralData } from './CertificateFormGeneralData';
import { transformFromApi } from '../../../../core/utils/certificate-util';
import {
  DetailedCertificate,
  FormCertificatePatternGroup
} from '../../../../core/models/certificate';
import ChecklistsInputs from '../../../shared/ChecklistsInputs';
import { useState } from 'react';
import CertificateFormPatternGroupTab from './CertificateFormPatternGroupTab';
import CertificateAddTab from './CertificateAddTab';
import { filter } from '../../../../core/utils/collection-utils';
import { NewChecklistWarning } from './NewChecklistWarning';
import SaveWithConfirmButton from '../../../shared/SaveWithConfirmButton';
import EditListener, { editAtom } from '../../../shared/EditListener';
import { useSetAtom } from 'jotai';
import { useQueryClient } from 'react-query';
import queryKeys from '../../../../core/hooks/query-keys';
import { isNullOrUndefined } from '../../../../core/utils/object-utils';

type Props = {
  certificate: DetailedCertificate;
  confirm?: boolean;
};

export const CertificateForm = ({ certificate, confirm = false }: Props) => {
  const refresh = useRefresh();
  const transformedCertificate = transformFromApi(certificate);

  const [patternGroups, setPatternGroups] = useState<
    FormCertificatePatternGroup[]
  >(transformedCertificate?.patternGroups || []);

  const [previousCertificate, setPreviousCertificate] = useState(certificate);
  if (certificate !== previousCertificate) {
    setPreviousCertificate(certificate);
    setPatternGroups(transformedCertificate?.patternGroups || []);
  }

  const addPatternGroup = (group: FormCertificatePatternGroup) => {
    setPatternGroups((prev) => [...prev, group]);
  };

  const deletePatternGroup = (position: string) => {
    setPatternGroups(
      filter((_, index): boolean => index.toString() !== position)
    );
  };

  const setEdit = useSetAtom(editAtom);
  const queryClient = useQueryClient();
  const handleConfirm = async () => {
    setEdit(false);
    await queryClient.invalidateQueries(
      queryKeys.certificates.detail(certificate.id)
    );
    await queryClient.refetchQueries(
      queryKeys.certificates.detail(certificate.id)
    );
    refresh();
  };

  return (
    <TabbedForm
      toolbar={
        <Toolbar className="footertoolbar">
          {confirm ? (
            <SaveWithConfirmButton
              translateOptions={{ id: certificate.certificateNumber }}
              onConfirm={handleConfirm}
            />
          ) : (
            <SaveButton
              type="button"
              label="ra.action.save"
              variant="contained"
              icon={null}
            />
          )}
        </Toolbar>
      }
      record={transformedCertificate}
    >
      <TabbedForm.Tab label="resources.certificates.tabs.generalData">
        <CertificateFormGeneralData
          originalCertificateNumber={
            isNullOrUndefined(transformedCertificate?.certificateNumber)
              ? undefined
              : parseInt(transformedCertificate.certificateNumber)
          }
        />
      </TabbedForm.Tab>
      {patternGroups.map((patternGroup, index) => (
        <CertificateFormPatternGroupTab
          key={patternGroup.id ?? crypto.randomUUID()}
          onCloseClick={() => deletePatternGroup(index.toString())}
          patternGroup={patternGroup}
          position={index}
        />
      ))}
      <TabbedForm.Tab label="resources.certificates.fields.checklist">
        <NewChecklistWarning
          hashNewChecklists={
            transformedCertificate.machineChecklists?.length > 0
          }
        />
        <ChecklistsInputs withDoneCheckbox />
      </TabbedForm.Tab>
      <CertificateAddTab onGroupClick={addPatternGroup} />
      <EditListener />
    </TabbedForm>
  );
};
