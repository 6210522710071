const MuiButton = () => ({
  styleOverrides: {
    root: {
      fontWeight: '700',
      fontSize: '16px',
      textTransform: 'none' as const,
      '&.Mui-disabled': {
        background: '#CCC',
        color: '#666'
      }
    }
  }
});

export default MuiButton;
