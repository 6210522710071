import { FormCertificatePatternGroup } from '../../../../core/models/certificate';
import { PatternGroup } from '../../../../core/models/pattern-group';
import CertificateAddTabPatternGroup from './CertificateAddTabPatternGroup';

type CertificateAddTabPatternGroupListProps = {
  onGroupClick: (
    group: FormCertificatePatternGroup,
    e: React.MouseEvent
  ) => void;
  patternGroups: PatternGroup[];
};

export default function CertificateAddTabPatternGroupList({
  onGroupClick,
  patternGroups
}: CertificateAddTabPatternGroupListProps) {
  return (
    <ul>
      {patternGroups?.map((patternGroup) => (
        <CertificateAddTabPatternGroup
          key={patternGroup.id}
          patternGroup={patternGroup}
          onGroupClick={onGroupClick}
        />
      ))}
    </ul>
  );
}
