import { ReactNode } from 'react';
import { Layout } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';

import { CustomAppBar } from './AppBar';
import { useResponsiveSideBar } from '../../core/hooks/useResponsiveSideBar';

import { CustomMenu } from './menu/CustomMenu';
import HideOffline from '../shared/HideOffline';
import AppProvider from '../providers/AppProvider';

type CustomLayoutProps = {
  children: ReactNode;
};

export const CustomLayout = ({ children, ...props }: CustomLayoutProps) => {
  useResponsiveSideBar();
  return (
    <>
      <AppProvider>
        <Layout {...props} appBar={CustomAppBar} menu={CustomMenu}>
          <HideOffline>{children}</HideOffline>
        </Layout>
      </AppProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};
