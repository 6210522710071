import { Datagrid, EmailField, List, TextField, TextInput } from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { ListBulkActions } from '../shared/ListBulkActions';
import { ListDelete } from '../shared/ListDelete';
import { ListEmpty } from '../shared/ListEmpty';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { ListPagination } from '../shared/ListPagination';

const userFilters = [
  <TextInput key="0" source="q" label="resources.users.search.label" alwaysOn />
];

export const UserList = () => {
  return (
    <List
      hasCreate
      filters={userFilters}
      actions={<CustomToolbar />}
      empty={<ListEmpty />}
      pagination={<ListPagination />}
    >
      <Datagrid
        rowClick="edit"
        className="table-action"
        bulkActionButtons={<ListBulkActions />}
        header={
          <ListHeader
            columns={
              <>
                <TableCell source="username" />
                <TableCell source="firstName" />
                <TableCell source="lastName" />
                <TableCell source="email" />
              </>
            }
            deleteColumn
          />
        }
      >
        <TextField source="username" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <ListDelete nameAttr="username" />
      </Datagrid>
    </List>
  );
};
