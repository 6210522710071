import { TableCell as MuiTableCell } from '@mui/material';
import { ReactNode } from 'react';
import { useListContext, useTranslate } from 'react-admin';

type Props = {
  source?: string;
  label?: string;
  className?: string;
  sortable?: boolean;
  children?: ReactNode;
  padding?: 'checkbox' | 'none' | 'normal';
};

export const TableCell = ({
  source,
  label,
  className,
  sortable = true,
  children,
  padding
}: Props) => {
  const { sort, setSort, resource } = useListContext();
  const t = useTranslate();

  const handleSort = () => {
    if (sortable && setSort) {
      setSort({ field: source, order: sort.order === 'ASC' ? 'DESC' : 'ASC' });
    }
  };

  const displayText = label
    ? t(label)
    : source
    ? t(`resources.${resource}.fields.${source}`)
    : '';

  return (
    <MuiTableCell
      padding={padding}
      className={`${className ?? ''} ${sortable ? 'pointer' : ''}`}
      onClick={handleSort}
    >
      {displayText}
      {children}
      {sort.field === source && <span>{sort.order === 'ASC' ? '▲' : '▼'}</span>}
    </MuiTableCell>
  );
};
