import {
  Labeled,
  useTranslate,
  SelectInput,
  SelectInputProps
} from 'react-admin';
import usePatternGroupUnits from '../../core/hooks/usePatternGroupUnits';
import { useController } from 'react-hook-form';

type UnitFieldProps = SelectInputProps & {
  patternGroupId: string;
  required?: boolean;
};

export default function PatternGroupUnitInput({
  patternGroupId,
  required = false,
  ...props
}: UnitFieldProps) {
  const t = useTranslate();
  const { data: units } = usePatternGroupUnits(patternGroupId);

  const { field } = useController({
    name: 'unit',
    rules: required
      ? {
          required: t('ra.validation.required')
        }
      : {}
  });

  return (
    <Labeled className="labeled-input">
      <SelectInput
        {...props}
        {...field}
        source="unit"
        choices={units ?? []}
        required={required}
      />
    </Labeled>
  );
}
