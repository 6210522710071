import { v4 as uuid } from 'uuid';
import {
  CacheCertificate,
  CacheCertificateLanguage,
  CacheCertificatePattenGroupResult,
  CacheCertificatePatternGroup,
  CacheCertificatePatternGroupLanguage,
  CacheCertificatePatternGroupPattern,
  CacheCertificatePatternGroupValue,
  CacheClientMachine
} from '../../core/models/cache';
import {
  DetailedCertificate,
  DetailedCertificateChecklist,
  DetailedCertificateChecklistElement,
  DetailedCertificateChecklistLanguage,
  DetailedCertificateChecklistLanguageElement,
  DetailedCertificateLanguage,
  DetailedCertificatePatternGroup,
  DetailedCertificatePatternGroupLanguage,
  DetailedCertificatePatternGroupPattern,
  DetailedCertificatePatternGroupResult,
  DetailedCertificatePatternGroupValue,
  DetailedCertificateTemplate,
  DetailedCertificateTemplateChecklist,
  DetailedCertificateTemplateChecklistLanguage,
  DetailedCertificateTemplateChecklistLanguageElement,
  DetailedCertificateTemplatePatternGroup,
  DetailedCertificateTemplatePatternGroupLanguage,
  ShortCertificate
} from '../../core/models/certificate';
import {
  DBMachine,
  DBMachineChecklist,
  DBMachineChecklistLanguage,
  DBMachineChecklistLanguageElement,
  DBMachinePatternGroup,
  DBMachinePatternGroupLanguage
} from '../machines';
import {
  DBMagnitude,
  mapCacheMagnitudeToDBMagnitude,
  mapDBMagnitudeToMagnitude
} from '../magnitudes';
import {
  DBPatternGroup,
  mapCachePatternGroupToDBPatternGroup,
  mapDBPatternGroupToDetailedCertificateOriginalPatternGroup
} from '../patternGroups';
import { DBUnit, mapCacheUnitToDBUnit, mapDBUnitToUnit } from '../units';
import { DBUser } from '../users';
import {
  CreateCertificateBody,
  CreateCertificateChecklistBody,
  CreateCertificateChecklistElementBody,
  CreateCertificateChecklistLanguageBody,
  CreateCertificateChecklistLanguageElementBody,
  CreateCertificateLanguageBody,
  CreateCertificatePatternGroupBody,
  CreateCertificatePatternGroupLanguageBody,
  CreateCertificatePatternGroupPatternBody,
  CreateCertificatePatternGroupResult,
  CreateCertificatePatternGroupValue
} from './routes/createCertificate';
import {
  UpdateCertificateBody,
  UpdateCertificateChecklistBody,
  UpdateCertificateChecklistElementBody,
  UpdateCertificateChecklistLanguageBody,
  UpdateCertificateChecklistLanguageElementBody,
  UpdateCertificateLanguageBody,
  UpdateCertificatePatternGroupBody,
  UpdateCertificatePatternGroupLanguageBody,
  UpdateCertificatePatternGroupPatternBody,
  UpdateCertificatePatternGroupResult,
  UpdateCertificatePatternGroupValue
} from './routes/updateCertificate';
import {
  CertificateChecklistId,
  CertificateChecklistLanguageElementId,
  CertificateId,
  CertificatePatternGroupId,
  DBCertificate,
  DBCertificateChecklist,
  DBCertificateChecklistElement,
  DBCertificateChecklistLanguage,
  DBCertificateChecklistLanguageElement,
  DBCertificateDetails,
  DBCertificateDetailsLanguage,
  DBCertificatePatternGroup,
  DBCertificatePatternGroupLanguage,
  DBCertificatePatternGroupPattern,
  DBCertificatePatternGroupResult,
  DBCertificatePatternGroupValue
} from './types';

export function mapCacheCertificateToDBCertificate(
  certificate: CacheCertificate,
  clientMachine: CacheClientMachine
): DBCertificate {
  return {
    id: certificate.id,
    userId: certificate.userId,
    userUsername: certificate.userUsername,
    clientMachineId: clientMachine.id,
    certificateNumber: certificate.certificateNumber,
    createdAt: certificate.createdAt
  };
}

export function mapCacheCertificateToDBCertificateDetails(
  certificate: CacheCertificate
): DBCertificateDetails {
  return {
    id: certificate.id,
    certificateNumber: certificate.certificateNumber,
    manufacturer: certificate.manufacturer,
    model: certificate.model,
    serialNumber: certificate.serialNumber,
    code: certificate.code,
    temperature: certificate.temperature,
    humidity: certificate.humidity,
    revisedAt: certificate.revisedAt,
    revisionInterval: certificate.revisionInterval,
    manualSign: certificate.manualSign,
    languages: certificate.languages.map(
      mapCacheCertificateLanguageToDBCertificateDetailsLanguage
    ),
    done: certificate.done
  };
}

export function mapCacheCertificateLanguageToDBCertificateDetailsLanguage(
  language: CacheCertificateLanguage
): DBCertificateDetailsLanguage {
  return {
    code: language.code,
    observation: language.observation
  };
}

export function mapCacheCertificatePatternGroupToDBCertificatePatternGroup(
  group: CacheCertificatePatternGroup,
  certificate: CacheCertificate
): DBCertificatePatternGroup {
  return {
    id: group.id,
    certificateId: certificate.id,
    patternGroup: mapCachePatternGroupToDBPatternGroup(group.patternGroup),
    magnitude: mapCacheMagnitudeToDBMagnitude(group.magnitude),
    unit: mapCacheUnitToDBUnit(group.unit),
    scale: group.scale,
    scaleDivision: group.scaleDivision,
    resolution: group.resolution,
    indicator: group.indicator,
    satNote: group.satNote,
    done: group.done,
    hasExtraField: group.hasExtraField,
    languages: group.languages.map(
      mapCacheCertificatePatternGroupLanguageToDBCertificatePatternGroupLanguage
    ),
    patterns: group.patterns.map(
      mapCacheCertificatePatternGroupPatternToDBCertificatePatternGroupPattern
    ),
    patternResultPrimary: group.patternResultPrimary,
    values: group.values.map(
      mapCacheCertificatePatternGroupValuesToDBCertidicaePatternGroupValues
    ),
    results: group.results.map(
      mapCacheCertificatePatternGroupResultsToDBCertificatePatternGroupResults
    )
  };
}

export function mapCacheCertificatePatternGroupValuesToDBCertidicaePatternGroupValues(
  value: CacheCertificatePatternGroupValue
): DBCertificatePatternGroupValue {
  return {
    customId: value.customId,
    measurement: value.measurement,
    datas: value.datas
  };
}

export function mapCacheCertificatePatternGroupResultsToDBCertificatePatternGroupResults(
  result: CacheCertificatePattenGroupResult
): DBCertificatePatternGroupResult {
  return {
    id: result.id,
    measurement: result.measurement,
    pattern: result.pattern,
    error: result.error,
    li: result.li,
    si: result.si
  };
}

export function mapCacheCertificatePatternGroupLanguageToDBCertificatePatternGroupLanguage(
  language: CacheCertificatePatternGroupLanguage
): DBCertificatePatternGroupLanguage {
  return {
    code: language.code,
    clientObservation: language.clientObservation
  };
}

export function mapCacheCertificatePatternGroupPatternToDBCertificatePatternGroupPattern(
  pattern: CacheCertificatePatternGroupPattern
): DBCertificatePatternGroupPattern {
  return {
    id: pattern.id
  };
}

export function mapDBCertificatePatternGroupToDetailedCertificatePatternGroup(
  g: DBCertificatePatternGroup
): DetailedCertificatePatternGroup {
  return {
    id: g.id,
    patternGroup: mapDBPatternGroupToDetailedCertificateOriginalPatternGroup(
      g.patternGroup
    ),
    magnitude: mapDBMagnitudeToMagnitude(g.magnitude),
    unit: mapDBUnitToUnit(g.unit),
    scale: g.scale,
    scaleDivision: g.scaleDivision,
    resolution: g.resolution,
    indicator: g.indicator,
    satNote: g.satNote,
    done: g.done,
    hasExtraField: g.hasExtraField,
    languages: g.languages.map(
      mapDBCertificatePatternGroupLanguageToDetailedCertificatePatternGroupLanguage
    ),
    patterns: g.patterns.map(
      mapDBCertificatePatternGroupPatternToDetailedCertificatePatternGroupPattern
    ),
    values: g.values.map(
      mapDBCertificatePattenGroupValueToDetailedCertificatePatternGroupValue
    ),
    results: g.results.map(
      mapDBCertificatePatternGroupResultToDetailedCertificatePatternGroupResult
    ),
    patternResultPrimary: g.patternResultPrimary
  };
}

export function mapDBCertificatePatternGroupLanguageToDetailedCertificatePatternGroupLanguage(
  language: DBCertificatePatternGroupLanguage
): DetailedCertificatePatternGroupLanguage {
  return {
    id: language.id,
    code: language.code,
    clientObservation: language.clientObservation
  };
}

export function mapDBCertificatePatternGroupPatternToDetailedCertificatePatternGroupPattern(
  pattern: DBCertificatePatternGroupPattern
): DetailedCertificatePatternGroupPattern {
  return {
    id: pattern.id
  };
}

export function mapDBCertificatePattenGroupValueToDetailedCertificatePatternGroupValue(
  value: DBCertificatePatternGroupValue
): DetailedCertificatePatternGroupValue {
  return {
    customId: value.customId,
    measurement: value.measurement,
    datas: value.datas
  };
}

export function mapDBCertificatePatternGroupResultToDetailedCertificatePatternGroupResult(
  result: DBCertificatePatternGroupResult
): DetailedCertificatePatternGroupResult {
  return {
    id: result.id,
    measurement: result.measurement,
    pattern: result.pattern,
    error: result.error,
    li: result.li,
    si: result.si
  };
}

export function mapDBCertificateToShortCertificate(
  c: DBCertificate
): ShortCertificate {
  return {
    id: c.id,
    certificateNumber: c.certificateNumber,
    user: {
      id: c.userId,
      username: c.userUsername
    },
    createdAt: c.createdAt
  };
}

export function mapDBCertificateDetailsToDetailedCertificate(
  d: DBCertificateDetails,
  gs: DBCertificatePatternGroup[],
  cs: DBCertificateChecklist[]
): DetailedCertificate {
  return {
    id: d.id,
    certificateNumber: d.certificateNumber!,
    manufacturer: d.manufacturer,
    model: d.model,
    serialNumber: d.serialNumber,
    code: d.code,
    temperature: d.temperature,
    humidity: d.humidity,
    manualSign: d.manualSign,
    revisedAt: d.revisedAt,
    revisionInterval: d.revisionInterval,
    patternGroups: gs.map(
      mapDBCertificatePatternGroupToDetailedCertificatePatternGroup
    ),
    languages: d.languages.map(
      mapDBCertificateDetailsLanguageToDetailedCertificateLanguage
    ),
    checklists: cs.map(mapDBCertificateChecklistToDetailedCertificateChecklist),
    machineChecklists: [],
    done: d.done
  };
}

export function mapDBCertificateDetailsLanguageToDetailedCertificateLanguage(
  language: DBCertificateDetailsLanguage
): DetailedCertificateLanguage {
  return {
    code: language.code,
    observation: language.observation
  };
}

export function mapDBCertificateChecklistToDetailedCertificateChecklist(
  c: DBCertificateChecklist
): DetailedCertificateChecklist {
  return {
    hash: c.id,
    languages: c.languages.map(
      mapDBCertificateChecklistLanguageToDetailedCertificateChecklistLanguage
    ),
    elements: c.elements.map(
      mapDBCertificateChecklistElementToDetailedCertificateChecklistElement
    )
  };
}

export function mapDBCertificateChecklistElementToDetailedCertificateChecklistElement(
  e: DBCertificateChecklistElement
): DetailedCertificateChecklistElement {
  return { done: e.done };
}

export function mapDBCertificateChecklistLanguageToDetailedCertificateChecklistLanguage(
  l: DBCertificateChecklistLanguage
): DetailedCertificateChecklistLanguage {
  return {
    code: l.code,
    name: l.name,
    elements: l.elements.map(
      mapDBCertificateChecklistLanguageElementToDetailedCertificateChecklistLanguageElement
    )
  };
}

export function mapDBCertificateChecklistLanguageElementToDetailedCertificateChecklistLanguageElement(
  e: DBCertificateChecklistLanguageElement
): DetailedCertificateChecklistLanguageElement {
  return {
    hash: e.id,
    name: e.name
  };
}

export function mapDBMachineToDetailedCertificateTemplate(
  m: DBMachine
): DetailedCertificateTemplate {
  return {
    id: null,
    certificateNumber: null,
    manufacturer: null,
    model: null,
    serialNumber: null,
    code: null,
    temperature: null,
    humidity: null,
    manualSign: false,
    patternGroups: m.patternGroups.map(
      mapDBMachinePatternGroupToDetailedCertificateTemplatePatternGroup
    ),
    checklists: m.checklists.map(
      mapDBMachineChecklistToDetailedCertificateTemplateChecklist
    ),
    machineChecklists: [],
    languages: [],
    done: false
  };
}

export function mapDBMachinePatternGroupToDetailedCertificateTemplatePatternGroup(
  g: DBMachinePatternGroup
): DetailedCertificateTemplatePatternGroup {
  return {
    id: null,
    patternGroup: { id: g.patternGroup.id },
    magnitude: mapDBMagnitudeToMagnitude(g.patternGroup.magnitude),
    unit: null,
    scale: null,
    scaleDivision: null,
    resolution: null,
    indicator: null,
    satNote: null,
    done: false,
    hasExtraField: false,
    languages: [],
    patterns: [],
    values: [],
    results: []
  };
}

export function mapDBMachinePatternGroupLanguageToDetailedCertificateTemplatePatternGroupLanguage(
  l: DBMachinePatternGroupLanguage
): DetailedCertificateTemplatePatternGroupLanguage {
  return {
    id: l.id,
    code: l.code,
    clientObservation: l.clientObservation
  };
}

export function mapDBMachineChecklistToDetailedCertificateTemplateChecklist(
  c: DBMachineChecklist
): DetailedCertificateTemplateChecklist {
  return {
    hash: null,
    languages: c.languages.map(
      mapDBMachineChecklistLanguageToDetailedCertificateTemplateChecklistLanguage
    ),
    elements: [{ done: false }]
  };
}

export function mapDBMachineChecklistLanguageToDetailedCertificateTemplateChecklistLanguage(
  l: DBMachineChecklistLanguage
): DetailedCertificateTemplateChecklistLanguage {
  return {
    code: l.code,
    name: l.name,
    elements: (l.elements ?? []).map(
      mapDBMachineChecklistLanguageElementToDetailedCertificateTemplateChecklistLanguageElement
    )
  };
}

export function mapDBMachineChecklistLanguageElementToDetailedCertificateTemplateChecklistLanguageElement(
  e: DBMachineChecklistLanguageElement
): DetailedCertificateTemplateChecklistLanguageElement {
  return {
    hash: null,
    name: e.name
  };
}

export function mapCreateCertificateBodyToDBCertificate(
  body: CreateCertificateBody,
  user: DBUser,
  createdAt: string
): DBCertificate {
  return {
    id: uuid() as CertificateId,
    userId: user.id,
    userUsername: user.username,
    clientMachineId: body.clientMachineHash,
    certificateNumber: `${body.certificateNumber}`,
    createdAt
  };
}

export function mapCreateCertificateBodyToDBCertificateDetails(
  body: CreateCertificateBody,
  certificateId: CertificateId
): DBCertificateDetails {
  return {
    id: certificateId,
    certificateNumber: `${body.certificateNumber}`,
    manufacturer: body.manufacturer,
    model: body.model,
    serialNumber: body.serialNumber,
    code: body.code,
    temperature: body.temperature,
    humidity: body.humidity,
    manualSign: body.manualSign,
    revisedAt: body.revisedAt,
    revisionInterval: body.revisionInterval,
    languages: body.languages.map(
      mapCreateCertificateLanguageBodyToDBCertificateDetailsLanguage
    ),
    done: body.done
  };
}

export function mapCreateCertificateLanguageBodyToDBCertificateDetailsLanguage(
  lang: CreateCertificateLanguageBody
): DBCertificateDetailsLanguage {
  return {
    code: lang.code,
    observation: lang.observation
  };
}

export function mapCreateCertificatePatternGroupBodyToDBCertificatePatternGroup(
  certificatePatternGroup: CreateCertificatePatternGroupBody,
  certificateId: CertificateId,
  patternGroup: DBPatternGroup,
  unit: DBUnit
): DBCertificatePatternGroup {
  return {
    id: uuid() as CertificatePatternGroupId,
    certificateId,
    patternGroup,
    magnitude: patternGroup.magnitude,
    unit,
    scale: certificatePatternGroup.scale,
    scaleDivision: certificatePatternGroup.scaleDivision,
    resolution: certificatePatternGroup.resolution,
    indicator: certificatePatternGroup.indicator,
    satNote: certificatePatternGroup.satNote,
    done: certificatePatternGroup.done,
    hasExtraField: certificatePatternGroup.hasExtraField,
    languages: certificatePatternGroup.languages.map(
      mapCreateCertificatePatternGroupLanguageBodyToDBCertificatePatternGroupLanguage
    ),
    patterns: certificatePatternGroup.patterns.map(
      mapCreateCertificatePatternGroupPatternBodyToDBCertificatePatternGroupPattern
    ),
    patternResultPrimary: certificatePatternGroup.patternResultPrimary,
    values: certificatePatternGroup.values.map(
      mapCreateCertificatePatternGroupValuesToDBCertificatePatternGroupValue
    ),
    results: certificatePatternGroup.results.map(
      mapCreateCertificatePatternGroupResultToDBCertificatePatternGroupResult
    )
  };
}

export function mapCreateCertificatePatternGroupValuesToDBCertificatePatternGroupValue(
  value: CreateCertificatePatternGroupValue
): DBCertificatePatternGroupValue {
  return {
    customId: value.customId,
    measurement: value.measurement,
    datas: value.datas
  };
}

export function mapCreateCertificatePatternGroupResultToDBCertificatePatternGroupResult(
  result: CreateCertificatePatternGroupResult
): DBCertificatePatternGroupResult {
  return {
    id: result.id,
    measurement: result.measurement,
    pattern: result.pattern,
    error: result.error,
    li: result.li,
    si: result.si
  };
}

export function mapCreateCertificatePatternGroupPatternBodyToDBCertificatePatternGroupPattern(
  pattern: CreateCertificatePatternGroupPatternBody
): DBCertificatePatternGroupPattern {
  return {
    id: pattern.id
  };
}

export function mapCreateCertificatePatternGroupLanguageBodyToDBCertificatePatternGroupLanguage(
  lang: CreateCertificatePatternGroupLanguageBody
): DBCertificatePatternGroupLanguage {
  return {
    code: lang.code,
    clientObservation: lang.clientObservation
  };
}

export function mapCreateCertificateChecklistBodyToDBCertificateChecklist(
  checklist: CreateCertificateChecklistBody,
  certificateId: CertificateId
): DBCertificateChecklist {
  return {
    id: uuid() as CertificateChecklistId,
    certificateId,
    languages: checklist.languages.map(
      mapCreateCertificateChecklistLanguageBodyToDBCertificateChecklistLanguage
    ),
    elements: checklist.elements.map(
      mapCreateCertificateChecklistElementBodyToDBCertificateChecklistElement
    )
  };
}

export function mapCreateCertificateChecklistLanguageBodyToDBCertificateChecklistLanguage(
  language: CreateCertificateChecklistLanguageBody
): DBCertificateChecklistLanguage {
  return {
    code: language.code,
    name: language.name,
    elements: language.elements.map(
      mapCreateCertificateChecklistLanguageElementBodyToDBCertificateChecklistLanguageElement
    )
  };
}

export function mapCreateCertificateChecklistLanguageElementBodyToDBCertificateChecklistLanguageElement(
  element: CreateCertificateChecklistLanguageElementBody
): DBCertificateChecklistLanguageElement {
  return {
    id: uuid() as CertificateChecklistLanguageElementId,
    name: element.name
  };
}

export function mapCreateCertificateChecklistElementBodyToDBCertificateChecklistElement(
  element: CreateCertificateChecklistElementBody
): DBCertificateChecklistElement {
  return {
    done: element.done
  };
}

export function mapUpdateCertificateBodyToPartialDBCertificate(
  body: UpdateCertificateBody
): Partial<DBCertificate> {
  return {
    certificateNumber: `${body.certificateNumber}`
  };
}

export function mapUpdateCertificateBodyToPartialDBCertificateDetails(
  body: UpdateCertificateBody
): Partial<DBCertificateDetails> {
  return {
    certificateNumber: `${body.certificateNumber}`,
    manufacturer: body.manufacturer,
    model: body.model,
    serialNumber: body.serialNumber,
    code: body.code,
    temperature: body.temperature,
    humidity: body.humidity,
    manualSign: body.manualSign,
    languages: body.languages.map(
      mapUpdateCertificateLanguageBodyToDBCertificateDetailsLanguage
    ),
    revisedAt: body.revisedAt,
    revisionInterval: body.revisionInterval,
    done: body.done
  };
}

export function mapUpdateCertificateLanguageBodyToDBCertificateDetailsLanguage(
  language: UpdateCertificateLanguageBody
): DBCertificateDetailsLanguage {
  return {
    code: language.code,
    observation: language.observation
  };
}

export function mapUpdateCertificatePatternGroupBodyToDBCertificatePatternGroup(
  body: UpdateCertificatePatternGroupBody,
  certificateId: CertificateId,
  patternGroup: DBPatternGroup,
  magnitude: DBMagnitude,
  unit: DBUnit
): DBCertificatePatternGroup {
  return {
    id: body.id ?? (uuid() as CertificatePatternGroupId),
    certificateId,
    patternGroup,
    magnitude,
    unit,
    scale: body.scale,
    scaleDivision: body.scaleDivision,
    resolution: body.resolution,
    indicator: body.indicator,
    satNote: body.satNote,
    done: body.done,
    hasExtraField: body.hasExtraField,
    languages: body.languages.map(
      mapUpdateCertificatePatternGroupBodyToDBCertificatePatternGroupLanguage
    ),
    patterns: body.patterns.map(
      mapUpdateCertificatePatternGroupBodyDBCertificatePatternGroupPattern
    ),
    patternResultPrimary: body.patternResultPrimary,
    values: body.values.map(
      mapUpdateCertificatePatternGroupValuesToDBCertificatePatternGroupValue
    ),
    results: body.results.map(
      mpaUpdateCertificatePatternGroupResultToDBCertificatePatternGroupResult
    )
  };
}

export function mapUpdateCertificatePatternGroupValuesToDBCertificatePatternGroupValue(
  value: UpdateCertificatePatternGroupValue
): DBCertificatePatternGroupValue {
  return {
    customId: value.customId,
    measurement: value.measurement,
    datas: value.datas
  };
}

export function mpaUpdateCertificatePatternGroupResultToDBCertificatePatternGroupResult(
  result: UpdateCertificatePatternGroupResult
): DBCertificatePatternGroupResult {
  return {
    id: result.id,
    measurement: result.measurement,
    pattern: result.pattern,
    error: result.error,
    li: result.li,
    si: result.si
  };
}

export function mapUpdateCertificatePatternGroupBodyToDBCertificatePatternGroupLanguage(
  patternGroup: UpdateCertificatePatternGroupLanguageBody
): DBCertificatePatternGroupLanguage {
  return {
    code: patternGroup.code,
    clientObservation: patternGroup.clientObservation
  };
}

export function mapUpdateCertificatePatternGroupBodyDBCertificatePatternGroupPattern(
  pattern: UpdateCertificatePatternGroupPatternBody
): DBCertificatePatternGroupPattern {
  return {
    id: pattern.id
  };
}

export function mapUpdateCertificateChecklistBodyToDBCertificateChecklist(
  body: UpdateCertificateChecklistBody,
  certificateId: CertificateId
): DBCertificateChecklist {
  return {
    id: body.hash ?? (uuid() as CertificateChecklistId),
    certificateId,
    languages: body.languages.map(
      mapUpdateCertificateChecklistLanguageToDBCertificateChecklistLanguage
    ),
    elements: body.elements.map(
      mapUpdateCertificateChecklistElementBodyToDBCertificateChecklistElement
    )
  };
}

export function mapUpdateCertificateChecklistElementBodyToDBCertificateChecklistElement(
  element: UpdateCertificateChecklistElementBody
): DBCertificateChecklistElement {
  return {
    done: element.done
  };
}

export function mapUpdateCertificateChecklistLanguageToDBCertificateChecklistLanguage(
  language: UpdateCertificateChecklistLanguageBody
): DBCertificateChecklistLanguage {
  return {
    code: language.code,
    name: language.name,
    elements: (language.elements ?? []).map(
      mapUpdateCertificateChecklistLanguageElementToDBCertificateChecklistLanguageElement
    )
  };
}

export function mapUpdateCertificateChecklistLanguageElementToDBCertificateChecklistLanguageElement(
  element: UpdateCertificateChecklistLanguageElementBody
): DBCertificateChecklistLanguageElement {
  return {
    id: uuid() as CertificateChecklistLanguageElementId,
    name: element.name
  };
}

export function mapDBCertificateToCreateCertificateBody(
  certificate: DBCertificate,
  details: DBCertificateDetails,
  patternGroups: DBCertificatePatternGroup[],
  checklists: DBCertificateChecklist[]
): CreateCertificateBody {
  return {
    clientMachineHash: certificate.clientMachineId,
    certificateNumber: +certificate.certificateNumber,
    manufacturer: details.manufacturer,
    model: details.model,
    serialNumber: details.serialNumber,
    code: details.serialNumber,
    temperature: details.temperature,
    humidity: details.humidity,
    manualSign: details.manualSign ?? false,
    revisedAt: details.revisedAt,
    revisionInterval: details.revisionInterval,
    languages: details.languages.map(
      mapDBCertificateLanguageToCreateCertificateLanguageBody
    ),
    patternGroups: patternGroups.map(
      mapDBCertificatePatternGroupToCreateCertificatePatternGroupBody
    ),
    checklists: checklists.map(
      mapDBCertificateChecklistToCreateCertificateChecklistBody
    ),
    done: details.done ?? false
  };
}

export function mapDBCertificateLanguageToCreateCertificateLanguageBody(
  language: DBCertificateDetailsLanguage
): CreateCertificateLanguageBody {
  return {
    code: language.code,
    observation: language.observation
  };
}

export function mapDBCertificatePatternGroupToCreateCertificatePatternGroupBody(
  group: DBCertificatePatternGroup
): CreateCertificatePatternGroupBody {
  return {
    unit: group.unit.id,
    magnitude: group.magnitude.id,
    scale: group.scale,
    scaleDivision: group.scaleDivision,
    resolution: group.resolution,
    indicator: group.indicator,
    satNote: group.satNote,
    patternGroupId: group.patternGroup.id,
    languages: group.languages.map(
      mapDBCertificatePatternGroupLanguageToCreateCertificatePatternGroupLanguageBody
    ),
    patterns: group.patterns.map(
      mapDBCertificatePatternGroupPatternToCreateCertificatePatternGroupPatternBody
    ),
    values: group.values.map(
      mapDBCertificatePattenGroupValuesToCreateCertificatePatternGroupValueBody
    ),
    done: group.done,
    hasExtraField: group.hasExtraField,
    patternResultPrimary: group.patternResultPrimary,
    results: group.results.map(
      mapDBCertificatePattenGroupResultToCreateCertificatePatternGroupResultBody
    )
  };
}

export function mapDBCertificatePatternGroupLanguageToCreateCertificatePatternGroupLanguageBody(
  language: DBCertificatePatternGroupLanguage
): CreateCertificatePatternGroupLanguageBody {
  return {
    code: language.code,
    clientObservation: language.clientObservation
  };
}

export function mapDBCertificatePatternGroupPatternToCreateCertificatePatternGroupPatternBody(
  pattern: DBCertificatePatternGroupPattern
): CreateCertificatePatternGroupPatternBody {
  return {
    id: pattern.id
  };
}

export function mapDBCertificateChecklistToCreateCertificateChecklistBody(
  checklist: DBCertificateChecklist
): CreateCertificateChecklistBody {
  return {
    languages: checklist.languages.map(
      mapDBCertificateChecklistLanguageToCreateCertificateChecklistLanguageBody
    ),
    elements: checklist.elements.map(
      mapDBCertificateChecklistElementLanguageToCreateCertificateChecklistElementLanguageBody
    )
  };
}

export function mapDBCertificateChecklistLanguageToCreateCertificateChecklistLanguageBody(
  language: DBCertificateChecklistLanguage
): CreateCertificateChecklistLanguageBody {
  return {
    code: language.code,
    name: language.name,
    elements: language.elements.map(
      mapDBCertificateChecklistLanguageElementToCreateCertificateChecklistLanguageElementBody
    )
  };
}

export function mapDBCertificateChecklistLanguageElementToCreateCertificateChecklistLanguageElementBody(
  element: DBCertificateChecklistLanguageElement
): CreateCertificateChecklistLanguageElementBody {
  return {
    name: element.name
  };
}

export function mapDBCertificateChecklistElementLanguageToCreateCertificateChecklistElementLanguageBody(
  element: DBCertificateChecklistElement
): CreateCertificateChecklistElementBody {
  return {
    done: element.done
  };
}

export function mapDBCertificateToUpdateCertificateBody(
  certificate: DBCertificate,
  details: DBCertificateDetails,
  patternGroups: DBCertificatePatternGroup[],
  checklists: DBCertificateChecklist[]
): UpdateCertificateBody {
  return {
    id: certificate.id,
    certificateNumber: +certificate.certificateNumber,
    manufacturer: details.manufacturer,
    model: details.model,
    serialNumber: details.serialNumber,
    code: details.serialNumber,
    temperature: details.temperature,
    humidity: details.humidity,
    manualSign: details.manualSign ?? false,
    languages: details.languages.map(
      mapDBCertificateLanguageToUpdateCertificateLanguageBody
    ),
    patternGroups: patternGroups.map(
      mapDBCertificatePatternGroupToUpdateCertificatePatternGroupBody
    ),
    checklists: checklists.map(
      mapDBCertificateChecklistToUpdateCertificateChecklistBody
    ),
    revisedAt: details.revisedAt,
    revisionInterval: details.revisionInterval,
    done: details.done ?? false
  };
}

export function mapDBCertificateLanguageToUpdateCertificateLanguageBody(
  language: DBCertificateDetailsLanguage
): UpdateCertificateLanguageBody {
  return {
    code: language.code,
    observation: language.observation
  };
}

export function mapDBCertificatePatternGroupToUpdateCertificatePatternGroupBody(
  group: DBCertificatePatternGroup
): UpdateCertificatePatternGroupBody {
  return {
    unit: group.unit.id,
    magnitude: group.magnitude.id,
    scale: group.scale,
    scaleDivision: group.scaleDivision,
    resolution: group.resolution,
    indicator: group.indicator,
    satNote: group.satNote,
    patternGroupId: group.patternGroup.id,
    languages: group.languages.map(
      mapDBCertificatePatternGroupLanguageToUpdateCertificatePatternGroupLanguageBody
    ),
    patterns: group.patterns.map(
      mapDBCertificatePatternGroupPatternToUpdateCertificatePatternGroupPatternBody
    ),
    done: group.done,
    hasExtraField: group.hasExtraField,
    patternResultPrimary: group.patternResultPrimary,
    values: group.values.map(
      mapDBCertificatePattenGroupValuesToUpdateCertificatePatternGroupValues
    ),
    results: group.results.map(
      mapDBCertificatePattenGroupResultToUpdateCertificatePatternGroupResultBody
    )
  };
}

export function mapDBCertificatePatternGroupLanguageToUpdateCertificatePatternGroupLanguageBody(
  language: DBCertificatePatternGroupLanguage
): UpdateCertificatePatternGroupLanguageBody {
  return {
    code: language.code,
    clientObservation: language.clientObservation
  };
}

export function mapDBCertificatePatternGroupPatternToUpdateCertificatePatternGroupPatternBody(
  pattern: DBCertificatePatternGroupPattern
): UpdateCertificatePatternGroupPatternBody {
  return {
    id: pattern.id
  };
}

export function mapDBCertificateChecklistToUpdateCertificateChecklistBody(
  checklist: DBCertificateChecklist
): UpdateCertificateChecklistBody {
  return {
    languages: checklist.languages.map(
      mapDBCertificateChecklistLanguageToUpdateCertificateChecklistLanguageBody
    ),
    elements: checklist.elements.map(
      mapDBCertificateChecklistElementLanguageToUpdateCertificateChecklistElementLanguageBody
    )
  };
}

export function mapDBCertificateChecklistLanguageToUpdateCertificateChecklistLanguageBody(
  language: DBCertificateChecklistLanguage
): UpdateCertificateChecklistLanguageBody {
  return {
    code: language.code,
    name: language.name,
    elements: language.elements.map(
      mapDBCertificateChecklistLanguageElementToUpdateCertificateChecklistLanguageElementBody
    )
  };
}

export function mapDBCertificateChecklistLanguageElementToUpdateCertificateChecklistLanguageElementBody(
  element: DBCertificateChecklistLanguageElement
): UpdateCertificateChecklistLanguageElementBody {
  return {
    name: element.name
  };
}

export function mapDBCertificateChecklistElementLanguageToUpdateCertificateChecklistElementLanguageBody(
  element: DBCertificateChecklistElement
): UpdateCertificateChecklistElementBody {
  return {
    done: element.done
  };
}

export function mapDBCertificatePattenGroupValuesToCreateCertificatePatternGroupValueBody(
  element: DBCertificatePatternGroupValue
): CreateCertificatePatternGroupValue {
  return {
    customId: element.customId,
    measurement: element.measurement,
    datas: element.datas
  };
}

export function mapDBCertificatePattenGroupValuesToUpdateCertificatePatternGroupValues(
  element: DBCertificatePatternGroupValue
): UpdateCertificatePatternGroupValue {
  return {
    customId: element.customId,
    measurement: element.measurement,
    datas: element.datas
  };
}

export function mapDBCertificatePattenGroupResultToCreateCertificatePatternGroupResultBody(
  element: DBCertificatePatternGroupResult
): CreateCertificatePatternGroupResult {
  return {
    id: element.id,
    measurement: element.measurement,
    pattern: element.pattern,
    error: element.error,
    li: element.li,
    si: element.si
  };
}

export function mapDBCertificatePattenGroupResultToUpdateCertificatePatternGroupResultBody(
  element: DBCertificatePatternGroupResult
): UpdateCertificatePatternGroupResult {
  return {
    id: element.id,
    measurement: element.measurement,
    pattern: element.pattern,
    error: element.error,
    li: element.li,
    si: element.si
  };
}
