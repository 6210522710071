import { useEffect } from 'react';
import { useNotify } from 'react-admin';

type ServiceWorkerNotificationProviderProps = {
  children: React.ReactNode;
};

export default function ServiceWorkerNotificationProvider({
  children
}: ServiceWorkerNotificationProviderProps) {
  const notify = useNotify();
  function handleServiceWorkerMessage(e: ExtendableMessageEvent) {
    if (e.data?.type === 'NEW_VERSION_AVAILABLE') {
      notify('serviceWorker.newVersion', { type: 'warning' });
    }
  }

  useEffect(() => {
    navigator.serviceWorker?.controller?.addEventListener(
      'message',
      handleServiceWorkerMessage
    );

    return () => {
      navigator.serviceWorker?.controller?.removeEventListener(
        'message',
        handleServiceWorkerMessage
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
}
