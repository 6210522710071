import { Create, useNotify, useRedirect, useTranslate } from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { CertificateForm } from './certificatesShared/certificatesSharedForm/CertificateForm';
import { useParams } from 'react-router-dom';
import { transformToApi } from '../../core/utils/certificate-util';
import useLastClientMachineCertificate from '../../core/hooks/useLastClientMachineCertificate';

export const CertificateCreate = () => {
  const { clientId, clientMachineId } = useParams();
  const redirect = useRedirect();
  const t = useTranslate();

  const notify = useNotify();
  const onSuccess = (data: { id: string }) => {
    notify('resources.certificates.success.create', { type: 'success' });
    redirect(
      `/clients/${clientId}/machines/${clientMachineId}/certificates/${data.id}`
    );
  };

  const { data: certificate } =
    useLastClientMachineCertificate(clientMachineId);
  if (!certificate) return null;

  const transform = (data) => transformToApi(data, clientMachineId);

  return (
    <Create
      actions={<CustomToolbar title={t('resources.certificates.title.add')} />}
      mutationOptions={{ onSuccess }}
      resource="certificates"
      transform={transform}
    >
      <CertificateForm key={certificate.id} certificate={certificate} />
    </Create>
  );
};
