import Dexie, { Table } from 'dexie';
import {
  DBCertificate,
  DBCertificateChecklist,
  DBCertificateDetails,
  DBCertificatePatternGroup,
  DBEditedCertificate,
  DBNewCertificate
} from './certificates';
import { DBClientMachine } from './clientMachines';
import { DBClient } from './clients/types';
import { DBMachine } from './machines';
import { DBMagnitude } from './magnitudes';
import { DBPatternGroup } from './patternGroups';
import { DBPattern } from './patterns';
import { DBUnit } from './units';
import { DBUser } from './users';
import { DBVersion } from './version';

export class DexieExt extends Dexie {
  clients!: Table<DBClient>;
  machines!: Table<DBMachine>;
  clientMachines!: Table<DBClientMachine>;
  certificates!: Table<DBCertificate>;
  certificatesDetails!: Table<DBCertificateDetails>;
  certificatePatternGroups!: Table<DBCertificatePatternGroup>;
  certificateChecklists!: Table<DBCertificateChecklist>;
  newCertificates!: Table<DBNewCertificate>;
  editedCertificates!: Table<DBEditedCertificate>;
  patternGroups!: Table<DBPatternGroup>;
  magnitudes!: Table<DBMagnitude>;
  units!: Table<DBUnit>;
  users!: Table<DBUser>;
  patterns!: Table<DBPattern>;
  versions!: Table<DBVersion>;

  constructor(name: string) {
    super(name);

    this.version(1).stores({
      clients: 'id, name, tag',
      machines: 'id',
      clientMachines: 'id, clientId, machineId, machineName, lastCalibratedAt',
      certificates: 'id, userId, userUsername, certificateNumber, createdAt',
      certificatesDetails: 'id',
      certificatePatternGroups: 'id, certificateId',
      certificateChecklists: 'id, certificateId',
      newCertificates: 'id',
      editedCertificates: 'id',
      patternGroups: 'id, name',
      magnitudes: 'id, name',
      units: 'id, name',
      users: 'id',
      patterns: 'id, name, patternGroupId'
    });

    this.version(2).stores({
      clients: 'id, name, tag',
      machines: 'id',
      clientMachines: 'id, clientId, machineId, machineName, lastCalibratedAt',
      certificates: 'id, userId, userUsername, certificateNumber, createdAt',
      certificatesDetails: 'id',
      certificatePatternGroups:
        'id, certificateId, patternSubstractMeasurement',
      certificateChecklists: 'id, certificateId',
      newCertificates: 'id',
      editedCertificates: 'id',
      patternGroups: 'id, name',
      magnitudes: 'id, name',
      units: 'id, name',
      users: 'id',
      patterns: 'id, name, patternGroupId'
    });

    this.version(3).stores({
      clients: 'id, name, tag, erpCode',
      machines: 'id',
      clientMachines: 'id, clientId, machineId, machineName, lastCalibratedAt',
      certificates: 'id, userId, userUsername, certificateNumber, createdAt',
      certificatesDetails: 'id',
      certificatePatternGroups:
        'id, certificateId, patternSubstractMeasurement',
      certificateChecklists: 'id, certificateId',
      newCertificates: 'id',
      editedCertificates: 'id',
      patternGroups: 'id, name',
      magnitudes: 'id, name',
      units: 'id, name',
      users: 'id',
      patterns: 'id, name, patternGroupId'
    });

    this.version(4).stores({
      clients: 'id, name, tag, erpCode',
      machines: 'id',
      clientMachines: 'id, clientId, machineId, machineName, lastCalibratedAt',
      certificates: 'id, userId, userUsername, certificateNumber, createdAt',
      certificatesDetails: 'id',
      certificatePatternGroups:
        'id, certificateId, patternSubstractMeasurement',
      certificateChecklists: 'id, certificateId',
      newCertificates: 'id',
      editedCertificates: 'id',
      patternGroups: 'id, name',
      magnitudes: 'id, name',
      units: 'id, name',
      users: 'id',
      patterns: 'id, name, patternGroupId, code'
    });

    this.version(5).stores({
      clients: 'id, name, tag, erpCode',
      machines: 'id',
      clientMachines: 'id, clientId, machineId, machineName, lastCalibratedAt',
      certificates: 'id, userId, userUsername, certificateNumber, createdAt',
      certificatesDetails: 'id',
      certificatePatternGroups:
        'id, certificateId, patternSubstractMeasurement',
      certificateChecklists: 'id, certificateId',
      newCertificates: 'id',
      editedCertificates: 'id',
      patternGroups: 'id, name',
      magnitudes: 'id, name',
      units: 'id, name',
      users: 'id',
      patterns: 'id, name, patternGroupId, code',
      versions: 'id'
    });

    const v6 = {
      clients: 'id, name, tag, erpCode',
      machines: 'id',
      clientMachines: 'id, clientId, machineId, machineName, lastCalibratedAt',
      certificates: 'id, userId, userUsername, certificateNumber, createdAt',
      certificatesDetails: 'id',
      certificatePatternGroups: 'id, certificateId',
      certificateChecklists: 'id, certificateId',
      newCertificates: 'id',
      editedCertificates: 'id',
      patternGroups: 'id, name',
      magnitudes: 'id, name',
      units: 'id, name',
      users: 'id',
      patterns: 'id, name, patternGroupId, code',
      versions: 'id'
    };
    this.version(6).stores(v6);

    const v7 = {
      ...v6,
      certificates:
        'id, userId, userUsername, certificateNumber, createdAt, clientMachineId'
    };
    this.version(7).stores(v7);
  }

  async clear() {
    await Promise.all([
      this.clients.clear(),
      this.machines.clear(),
      this.clientMachines.clear(),
      this.certificates.clear(),
      this.certificatesDetails.clear(),
      this.certificatePatternGroups.clear(),
      this.certificateChecklists.clear(),
      this.newCertificates.clear(),
      this.editedCertificates.clear(),
      this.patternGroups.clear(),
      this.magnitudes.clear(),
      this.units.clear(),
      this.users.clear(),
      this.patterns.clear(),
      this.versions.clear()
    ]);
  }
}
