const getMany = 'getMany';
const getOne = 'getOne';

const cache = {
  all: ['cache'] as const,
  detail: (clientId: string) => ['cache', clientId] as const,
  count: ['cache', 'count'] as const
};

const certificates = {
  detail: (certificateId: string) =>
    ['certificates', { id: certificateId }] as const
};

const certificatePatternGroups = {
  detail: (certificatePatternGroupId: string) =>
    ['certificate-pattern-groups', certificatePatternGroupId] as const
};

const clientMachines = {
  lastCertificate: (clientMachineId: string) =>
    ['client-machines', clientMachineId, 'last-certificate'] as const,
  patternGroups: {
    list: (clientMachineId: string) =>
      ['client-machines', clientMachineId, 'pattern-groups', getMany] as const
  }
};

const magnitudes = {
  units: (magnitudeCode: string) =>
    ['manitudes', magnitudeCode, 'units', getMany] as const
};

const patterns = {
  list: (filters: { ids: string[] } | { q: string; patternGroupId: string }) =>
    ['patterns', getMany, filters] as const,
  detail: (id: string) => ['patterns', getOne, { id }] as const
};

const patternGroups = {
  all: ['pattern-groups', getMany] as const,
  units: (patternGroupId: string) =>
    ['pattern-groups', patternGroupId, 'units', getMany] as const
};

const tags = {
  all: ['tags', getMany] as const
};

const versions = {
  detail: (versionId: string) => ['versions', { id: versionId }] as const
};

export default {
  cache,
  certificates,
  certificatePatternGroups,
  clientMachines,
  magnitudes,
  patterns,
  patternGroups,
  tags,
  versions
};
