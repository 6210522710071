import {
  CheckboxGroupInput,
  Labeled,
  PasswordInput,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
  email,
  required,
  useRecordContext
} from 'react-admin';
import FormFooterToolbar from '../../../general/FormFooterToolbar';
import useTags from '../../../../core/hooks/useTags';
import { transformFromApi } from '../../../../core/utils/user-util';
import { UserResponse } from '../../../../core/models/user';
import useRoles from '../../../../core/hooks/useRoles';
import InputFile from '../../../shared/InputFile';

type Props = {
  isCreate?: boolean;
};

export const UserForm = ({ isCreate }: Props) => {
  const record = useRecordContext();
  const transformedUser = transformFromApi(record as UserResponse);

  const { data: tags } = useTags();
  const { data: roles } = useRoles();

  return (
    <SimpleForm
      className="two-columns-form"
      toolbar={<FormFooterToolbar />}
      record={transformedUser}
    >
      <Labeled className="labeled-input">
        <TextInput source="username" validate={required()} autoComplete="off" />
      </Labeled>
      <Labeled className="labeled-input">
        <TextInput
          source="email"
          type="email"
          validate={[required(), email()]}
          autoComplete="off"
        />
      </Labeled>
      <Labeled className="labeled-input">
        <TextInput source="firstName" validate={required()} />
      </Labeled>
      <Labeled className="labeled-input">
        <TextInput source="lastName" validate={required()} />
      </Labeled>
      <Labeled className="labeled-input">
        <TextInput source="position" />
      </Labeled>
      {isCreate ? (
        <Labeled className="labeled-input">
          <PasswordInput
            source="password"
            validate={required()}
            autoComplete="off"
          />
        </Labeled>
      ) : (
        <span />
      )}
      <Labeled className="labeled-input">
        <InputFile
          source="signingKey"
          validate={[required()]}
          options={{ accept: '.pfx' }}
        />
      </Labeled>
      <Labeled className="labeled-input">
        <PasswordInput
          source="signingKeyPass"
          validate={isCreate ? [required()] : []}
          autoComplete="off"
        />
      </Labeled>
      <div className="fieldset fieldset-two-columns-auto">
        <CheckboxGroupInput source="tags" choices={tags ?? []} row={false} />
        <RadioButtonGroupInput
          source="roleCode"
          choices={roles ?? []}
          optionValue="code"
          row={false}
          label="resources.users.fields.role"
          validate={required()}
        />
      </div>
    </SimpleForm>
  );
};
