import { CacheVersionValue } from '../../core/models/cache';
import { VersionValue } from '../../core/models/versionValue';
import { DBVersionValue } from './types';

export function mapCacheVersionValueToDBVersionValue(
  versionValue: CacheVersionValue
): DBVersionValue {
  return {
    id: versionValue.id,
    value: versionValue.value,
    uncertainty: versionValue.uncertainty
  };
}

export function mapDBVersionValueToVersionValue(
  versionValue: DBVersionValue
): VersionValue {
  return {
    id: versionValue.id,
    value: versionValue.value,
    uncertainty: versionValue.uncertainty
  };
}
