import { Overlay } from '../Overlay';
import ModalBody from './ModalBody';
import ModalHeader from './ModalHeader';

type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
  show: boolean;
};

export default function Modal({ children, onClose, show }: ModalProps) {
  if (!show) return;

  return (
    <div className="modal">
      <div className="modal__content">{children}</div>
      <Overlay onClick={onClose} />
    </div>
  );
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
