import {
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  List,
  useGetOne,
  useNotify,
  useTranslate,
  useRedirect,
  RaRecord
} from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { useParams } from 'react-router-dom';
import DateRangeInput from '../shared/DateRangeInput';
import { ListDelete } from '../shared/ListDelete';
import { ListBulkActions } from '../shared/ListBulkActions';
import { useState } from 'react';
import CreateDialog from '../shared/CreateDialog';
import ClientMachineCreateForm from './ClientMachineCreateForm';
import { ListEmpty } from '../shared/ListEmpty';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { isNullOrUndefined } from '../../core/utils/object-utils';
import { MachineNameField } from '../shared/MachineNameField';
import { getMachineName } from '../../core/utils/machine-util';
import { ListPagination } from '../shared/ListPagination';
import ClientMachineClientChangeButton from './ClientMachineClientChangeButton';
import EditDialog from '../shared/EditDialog';
import { ClientMachineClientTransferForm } from './ClientMachineClientTransferForm';

const filters = [<DateRangeInput key="0" alwaysOn />];

export default function ClientMachineList() {
  const translate = useTranslate();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  const [changeClient, setChangeClient] = useState<RaRecord>();

  const { clientId } = useParams();
  const { data: client } = useGetOne('clients', { id: clientId });
  if (!client) return null;

  const handleCreateSuccess = (data: { id: string }) => {
    notify('resources.clients.success.create', {
      type: 'success'
    });

    redirect(`${data.id}/certificates/create`);
  };

  const handleError = () =>
    notify('resources.clients.errors.create', { type: 'error' });

  const handleOnCreate = () => setShowCreateDialog(true);

  const handleClientTransferSuccess = () =>
    notify('resources.client-machines.modal.transferClient.success.message', {
      type: 'success'
    });

  const handleClientTransferError = () =>
    notify('resources.client-machines.modal.transferClient.errors.message', {
      type: 'error'
    });

  return (
    <>
      <List
        actions={
          <CustomToolbar
            onClickCreate={handleOnCreate}
            title={client.name as string}
          />
        }
        resource="client-machines"
        filter={{ clientId }}
        filters={filters}
        hasCreate
        empty={<ListEmpty onCreateClick={handleOnCreate} />}
        pagination={<ListPagination />}
      >
        <Datagrid
          rowClick={(id) => `${id}/certificates`}
          className="table-action"
          bulkActionButtons={<ListBulkActions />}
          header={
            <ListHeader
              columns={
                <>
                  <TableCell
                    label="resources.client-machines.fields.name"
                    source="machine.name"
                  />
                  <TableCell
                    label="resources.client-machines.fields.lastCertificateNumber"
                    sortable={false}
                  />
                  <TableCell
                    label="resources.client-machines.fields.serialNumber"
                    sortable={false}
                  />
                  <TableCell source="calibrated" sortable={false} />
                  <TableCell
                    label="resources.client-machines.fields.date"
                    source="lastCalibratedAt"
                  />
                </>
              }
              editColumn
              deleteColumn
            />
          }
        >
          <MachineNameField source="machine" />
          <FunctionField
            label="resources.client-machines-fields.lastCertificateNumber"
            render={({ lastCertificateNumber }) => {
              return !isNullOrUndefined(lastCertificateNumber)
                ? `${lastCertificateNumber}`.padStart(5, '0')
                : '';
            }}
          />
          <TextField
            label="resource.client-machines.fields.serialNumber"
            source="serialNumber"
          />
          <FunctionField
            label="resources.client-machines.fields.calibrated"
            render={(record) =>
              translate(
                `resources.client-machines.calibrated.${
                  record.lastCalibratedAt != null
                }`
              )
            }
          />
          <DateField
            label="resources.client-machines.fields.date"
            source="lastCalibratedAt"
          />
          <ClientMachineClientChangeButton
            onClick={(record) => setChangeClient(record)}
          />
          <ListDelete nameAttr="machine" formatter={getMachineName} />
        </Datagrid>
      </List>
      <CreateDialog
        show={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        mutationOptions={{
          onSuccess: handleCreateSuccess,
          onError: handleError
        }}
        resource="client-machines"
        transform={(data) => ({ machineId: data.machine, clientId })}
        title={translate('resources.client-machines.modal.add')}
      >
        <ClientMachineCreateForm />
      </CreateDialog>
      <EditDialog
        resource="client-machines"
        show={!!changeClient}
        onClose={() => setChangeClient(undefined)}
        mutationOptions={{
          onSuccess: handleClientTransferSuccess,
          onError: handleClientTransferError
        }}
        title={translate(
          'resources.client-machines.modal.transferClient.title'
        )}
        record={changeClient}
      >
        <p>
          {translate(
            'resources.client-machines.modal.transferClient.description'
          )}
        </p>
        <ClientMachineClientTransferForm />
      </EditDialog>
    </>
  );
}
