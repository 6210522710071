import { EditButton, RaRecord, useRecordContext } from 'react-admin';
import ClientTransferIcon from '../shared/icons/ClientTransferIcon';

type Props = {
  onClick: (id: RaRecord) => void;
};

export default function ClientMachineClientChangeButton({ onClick }: Props) {
  const record = useRecordContext();

  return (
    <EditButton
      className="edit-button"
      icon={<ClientTransferIcon />}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(record);
      }}
      label=""
    />
  );
}
