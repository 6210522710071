import {
  Labeled,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate
} from 'react-admin';
import FormFooterToolbar from '../../general/FormFooterToolbar';
import useTags from '../../../core/hooks/useTags';

export const ClientSharedForm = () => {
  const t = useTranslate();

  const { data: tags } = useTags();

  return (
    <SimpleForm toolbar={<FormFooterToolbar />}>
      <Labeled className="labeled-input">
        <TextInput source="name" validate={required()} />
      </Labeled>
      <Labeled className="labeled-input">
        <SelectInput
          source="tag.id"
          label={t('resources.clients.fields.tag')}
          choices={tags ?? []}
          fullWidth
          validate={required()}
        />
      </Labeled>
      <Labeled className="labeled-input">
        <TextInput source="erpCode" />
      </Labeled>
    </SimpleForm>
  );
};
