import { Create, useRedirect } from 'react-admin';
import { MachineForm } from './machinesShared/machinesSharedForm/MachineForm';
import { MACHINES_INDEX_URL } from '../../core/constants/url.constants';
import CustomToolbar from '../general/Toolbar';
import { transformToApi } from '../../core/utils/machine-util';

export const MachineCreate = () => {
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect(MACHINES_INDEX_URL);
  };

  const transform = async (data) => transformToApi(data);

  return (
    <Create
      mutationOptions={{ onSuccess }}
      actions={<CustomToolbar />}
      transform={transform}
    >
      <MachineForm />
    </Create>
  );
};
