import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  useGetOne,
  useRedirect
} from 'react-admin';
import { useParams } from 'react-router-dom';
import CustomToolbar from '../general/Toolbar';
import { ListBulkActions } from '../shared/ListBulkActions';
import { padStart } from '../../core/utils/number-utils';
import { ListDelete } from '../shared/ListDelete';
import { ListEmpty } from '../shared/ListEmpty';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { getMachineName } from '../../core/utils/machine-util';
import { ListPagination } from '../shared/ListPagination';

export default function CertificateList() {
  const { clientId, clientMachineId } = useParams();
  const redirect = useRedirect();

  const handleOnCreate = () =>
    redirect(
      `/clients/${clientId}/machines/${clientMachineId}/certificates/create`
    );

  const { data: clientMachine } = useGetOne('client-machines', {
    id: clientMachineId
  });
  if (!clientMachine) return null;

  return (
    <List
      hasCreate
      actions={
        <CustomToolbar
          title={getMachineName(clientMachine?.machine)}
          onClickCreate={handleOnCreate}
        />
      }
      resource="certificates"
      filter={{ clientMachineId }}
      empty={<ListEmpty onCreateClick={handleOnCreate} />}
      pagination={<ListPagination />}
    >
      <Datagrid
        rowClick={(id) => `${id}`}
        className="table-action"
        bulkActionButtons={<ListBulkActions />}
        header={
          <ListHeader
            columns={
              <>
                <TableCell source="certificateNumber" />
                <TableCell
                  label="resources.certificates.fields.date"
                  source="createdAt"
                />
                <TableCell source="user" />
              </>
            }
            deleteColumn
          />
        }
      >
        <FunctionField
          label="resources.certificates.fields.certificateNumber"
          render={(record) => padStart(record.certificateNumber, 5, '0')}
        />
        <DateField
          label="resources.certificates.fields.date"
          source="createdAt"
        />
        <ReferenceField
          label="resources.certificates.fields.user"
          source="user.id"
          reference="users"
        >
          <TextField source="username" />
        </ReferenceField>
        <ListDelete
          nameAttr="certificateNumber"
          redirect={`/clients/${clientId}/machines/${clientMachineId}/certificates`}
        />
      </Datagrid>
    </List>
  );
}
