import { PatternGroup } from '../models/pattern-group';
import axiosInstance, { getDefaultRequestConfig } from './base/axios.client';

type GetPatternGroupsOptions = {
  locale?: string;
  _sort?: string;
};

export async function getPatternGroups({
  locale,
  _sort
}: GetPatternGroupsOptions = {}) {
  const params = new URLSearchParams({ ...(_sort ? { _sort } : {}) });
  return await axiosInstance.get<PatternGroup[]>(
    `/pattern-groups?${params}`,
    getDefaultRequestConfig(locale)
  );
}
