import {
  ArrayInput,
  Button,
  SimpleFormIterator,
  TextInput,
  useTranslate
} from 'react-admin';
import DeleteIcon from '../../../shared/icons/DeleteIcon';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import {
  intArray,
  reduce,
  removeElementByIndex
} from '../../../../core/utils/collection-utils';
import { CERTIFICATE_VALUES_MIN_COLS } from '../../../../core/constants/certificate.constants';
import { useFormContext } from 'react-hook-form';
import { max } from '../../../../core/utils/number-utils';
import {
  FormCertificate,
  FormCertificatePatternGroupValue
} from '../../../../core/models/certificate';
import { IconButton } from '@mui/material';
import SwitchInput from '../../../shared/SwitchInput';
import { NumberInput } from '../../../shared/NumberInput';

type Props = {
  showCustomId?: boolean;
  position: number;
};

export const CertificateFormValues = ({
  showCustomId = false,
  position
}: Props) => {
  const t = useTranslate();
  const { getValues, setValue, watch } = useFormContext<FormCertificate>();
  const isCertificateBlocked = watch('done');

  const maxDatasLength = reduce(
    (acc: number, curr: FormCertificatePatternGroupValue) =>
      curr.datas ? max([acc, curr.datas?.length]) : acc,
    CERTIFICATE_VALUES_MIN_COLS,
    getValues(`patternGroups.${position}.values`)
  );

  const [valueColumns, setValueColumns] = useState(maxDatasLength);

  const handleDeleteColumn = (pos: number) => () => {
    getValues(`patternGroups.${position}.values`).forEach((row, index) => {
      setValue(
        `patternGroups.${position}.values.${index}.datas`,
        removeElementByIndex(pos, row.datas),
        { shouldDirty: true }
      );
    });
    setValueColumns(Number(valueColumns) - 1);
  };

  return (
    <>
      <div className="fieldset" style={{ marginTop: 0, marginBottom: '30px' }}>
        <SwitchInput
          source={`patternGroups.${position}.patternResultPrimary`}
          label=""
          labelPlacement="start"
          leftLabel="resources.certificates.fields.measurement"
          rightLabel="resources.certificates.fields.pattern"
          disabled={isCertificateBlocked}
        />
      </div>
      <div className="form-row">
        {showCustomId && (
          <span className="form-col">
            {t('resources.certificates.fields.customId')}
          </span>
        )}
        <span className="form-col">
          {watch(`patternGroups.${position}.patternResultPrimary`)
            ? t('resources.certificates.fields.pattern')
            : t('resources.certificates.fields.measurement')}
        </span>
        {intArray(Number(valueColumns)).map((index) => (
          <div key={index} className="form-col">
            <span>
              {t('resources.certificates.fields.value', {
                value: index + 1
              })}
            </span>
            <IconButton
              size="small"
              aria-label="delete"
              disabled={
                CERTIFICATE_VALUES_MIN_COLS >= Number(valueColumns) ||
                isCertificateBlocked
              }
              onClick={handleDeleteColumn(index)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ))}
        <span className="add-col">
          <Button
            startIcon={<AddIcon />}
            onClick={() => setValueColumns(Number(valueColumns) + 1)}
            disabled={isCertificateBlocked}
          />
        </span>
      </div>
      <ArrayInput label=" " source={`patternGroups.${position}.values`}>
        <SimpleFormIterator
          disableReordering
          disableClear
          removeButton={<DeleteIcon />}
          className="iterator-horizontal"
          disableAdd={isCertificateBlocked}
          disableRemove={isCertificateBlocked}
        >
          {showCustomId && (
            <TextInput
              className="no-label"
              source="customId"
              label={t('resources.certificates.fields.customId')}
              disabled={isCertificateBlocked}
            />
          )}
          <NumberInput
            className="no-label"
            source="measurement"
            label={t('resources.certificates.fields.measurement')}
            disabled={isCertificateBlocked}
          />
          {intArray(Number(valueColumns)).map((index) => (
            <NumberInput
              key={index}
              className="no-label"
              label={`${index + 1}`}
              source={`datas.${index}.data`}
              disabled={isCertificateBlocked}
            />
          ))}
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
