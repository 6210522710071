const RaSimpleForm = (palette, defaultPadding) => ({
  styleOverrides: {
    root: {
      backgroundColor: palette.light.grey,
      padding: defaultPadding
    }
  }
});

export default RaSimpleForm;
