import {
  CreateBase,
  CreateProps,
  RaRecord,
  UseCreateMutateParams
} from 'react-admin';
import Modal from './Modal';

type CreateDialogProps<RecordType extends RaRecord = any> =
  CreateProps<RecordType> & {
    children: React.ReactNode;
    onClose: () => void;
    onOutsideClick?: () => void;
    show: boolean;
  };

export default function CreateDialog<RecordType extends RaRecord = any>({
  children,
  mutationOptions,
  onClose,
  onOutsideClick,
  show,
  title,
  ...props
}: CreateDialogProps<RecordType>) {
  const onSuccess = (
    data: RecordType,
    variables: UseCreateMutateParams<RecordType>,
    context: unknown
  ) => {
    onClose();
    mutationOptions && mutationOptions?.onSuccess(data, variables, context);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header title={title} onClose={onClose} />
      <Modal.Body>
        <CreateBase
          {...props}
          mutationOptions={{ ...mutationOptions, onSuccess }}
        >
          <div>{children}</div>
        </CreateBase>
      </Modal.Body>
    </Modal>
  );
}
