import '@fontsource/titillium-web/400.css';
import '@fontsource/titillium-web/600.css';
import '@fontsource/titillium-web/700.css';
import './App.css';

import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Navigate, Route } from 'react-router-dom';

import ForgotPasswordPage from './auth/ForgotPasswordPage';
import { CustomLoginPage } from './auth/LoginPage';
import ResetPasswordPage from './auth/ResetPasswordPage';
import { CustomLayout } from './components/general/Layout';
import MachinesIcon from './components/shared/icons/MachinesIcon';
import MagnitudesIcon from './components/shared/icons/MagnitudesIcon';
import ClientsIcon from './components/shared/icons/ClientsIcon';
import UsersIcon from './components/shared/icons/UsersIcon';
import { UserCreate } from './components/users/UserCreate';
import { UserEdit } from './components/users/UserEdit';
import { UserList } from './components/users/UserList';
import authProvider from './providers/authProvider';
import httpClient from './providers/httpClient';
import { i18nProvider } from './providers/i18nProvider';
import { theme } from './theme';
import { MachineList } from './components/machines/MachineList';
import { MachineEdit } from './components/machines/MachineEdit';
import { MachineCreate } from './components/machines/MachineCreate';
import { ClientsList } from './components/clients/ClientList';
import { PatternGroupsList } from './components/patternGroups/PatternGroupsList';
import { PatternGroupsPatternsList } from './components/patternGroups/PatternGroupsPatternsList';
import { PatternGroupsPatternVersionList } from './components/patternGroups/PatternGroupsPatternVersionList';
import { PatternGroupsPatternVersionShow } from './components/patternGroups/PatternGroupsPatternVersionShow';
import ClientMachineList from './components/clientMachines/ClientMachineList';
import customDataProvider from './providers/dataProvider';
import CertificateList from './components/certificates/CertificateList';
import { CertificateCreate } from './components/certificates/CertificateCreate';
import { CertificateEdit } from './components/certificates/CertificateEdit';
import { ProtectedRoute } from './components/general/ProtectedRoutes';
import { PrivilegeType } from './core/models/privilegeType';
import { ReactElement } from 'react';
import { CLIENTS_INDEX_URL } from './core/constants/url.constants';

const apiUrl = process.env.REACT_APP_API_URL;
const data = customDataProvider(apiUrl, httpClient);

type ChildrenProp = {
  children: ReactElement;
};

const PatternGroupsProtectedRoute = ({ children }: ChildrenProp) => {
  return (
    <ProtectedRoute
      privileges={[PrivilegeType.ADMIN, PrivilegeType.USER_MAINTENANCE]}
      redirectPath={CLIENTS_INDEX_URL}
    >
      {children}
    </ProtectedRoute>
  );
};

const UsersProtectedRoute = ({ children }: ChildrenProp) => {
  return (
    <ProtectedRoute
      privileges={[PrivilegeType.ADMIN]}
      redirectPath={CLIENTS_INDEX_URL}
    >
      {children}
    </ProtectedRoute>
  );
};

const App = () => (
  <Admin
    dataProvider={data}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    loginPage={CustomLoginPage}
    theme={theme}
    layout={CustomLayout}
  >
    <Resource name="clients" icon={ClientsIcon} list={ClientsList}>
      <Route path=":clientId" element={<Navigate to="machines" />} />
      <Route path=":clientId/machines" element={<ClientMachineList />} />
      <Route
        path=":clientId/machines/:clientMachineId"
        element={<Navigate to="certificates" />}
      />
      <Route
        path=":clientId/machines/:clientMachineId/certificates"
        element={<CertificateList />}
      />
      <Route
        path=":clientId/machines/:clientMachineId/certificates/create/*"
        element={<CertificateCreate />}
      />
      <Route
        path=":clientId/machines/:clientMachineId/certificates/:id/*"
        element={<CertificateEdit />}
      />
    </Resource>
    <Resource
      name="users"
      icon={UsersIcon}
      list={
        <UsersProtectedRoute>
          <UserList />
        </UsersProtectedRoute>
      }
      edit={
        <UsersProtectedRoute>
          <UserEdit />
        </UsersProtectedRoute>
      }
      create={
        <UsersProtectedRoute>
          <UserCreate />
        </UsersProtectedRoute>
      }
    />
    <Resource
      name="pattern-groups"
      icon={MagnitudesIcon}
      list={
        <PatternGroupsProtectedRoute>
          <PatternGroupsList />
        </PatternGroupsProtectedRoute>
      }
    >
      <Route
        path=":patternGroupId"
        element={
          <PatternGroupsProtectedRoute>
            <Navigate to="patterns" />
          </PatternGroupsProtectedRoute>
        }
      />
      <Route
        path=":patternGroupId/patterns"
        element={
          <PatternGroupsProtectedRoute>
            <PatternGroupsPatternsList />
          </PatternGroupsProtectedRoute>
        }
      />
      <Route
        path=":patternGroupId/patterns/:patternId"
        element={
          <PatternGroupsProtectedRoute>
            <Navigate to="versions" />
          </PatternGroupsProtectedRoute>
        }
      />
      <Route
        path=":patternGroupId/patterns/:patternId/versions"
        element={
          <PatternGroupsProtectedRoute>
            <PatternGroupsPatternVersionList />
          </PatternGroupsProtectedRoute>
        }
      />
      <Route
        path=":patternGroupId/patterns/:patternId/versions/:id"
        element={
          <PatternGroupsProtectedRoute>
            <PatternGroupsPatternVersionShow />
          </PatternGroupsProtectedRoute>
        }
      />
    </Resource>
    <Resource name="magnitudes" icon={MagnitudesIcon} />
    <Resource
      name="machines"
      icon={MachinesIcon}
      list={MachineList}
      edit={MachineEdit}
      create={MachineCreate}
    />
    <CustomRoutes noLayout>
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password/:hash" element={<ResetPasswordPage />} />
    </CustomRoutes>
  </Admin>
);

export default App;
