import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function PumpIcon(props: any) {
  const propsMerged = {
    ...props,
    //viewPort: '20 20'
    viewBox: '-1 -1 26 26'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M7.762 4.256c-.255.255-.685.255-.919 0L4.8 2.096 4.8 18.296h16.04l-2.148-2.059a.655.655 0 0 1 0-.924c.296-.242.664-.242.919 0l3.19 3.113c.121.145.193.29.193.458a.637.637 0 0 1-.192.457l-3.191 3.073c-.255.255-.623.255-.919 0a.648.648 0 0 1 0-.919l2.148-2.032H4.16c-.267 0-.528-.261-.528-.583V2.12L1.58 4.255a.648.648 0 0 1-.919 0 .648.648 0 0 1 0-.92L3.775.147a.657.657 0 0 1 .457-.192c.168 0 .337.067.511.192l3.02 3.19a.648.648 0 0 1 0 .92zm14.1 10.244c.321 0 .638.235.638.557 0 .322-.317.61-.639.61s-.528-.288-.528-.61.206-.557.528-.557zm0-2.333c.321 0 .638.235.638.557 0 .322-.317.61-.639.61s-.528-.288-.528-.61.206-.557.528-.557zm0-2.334c.321 0 .638.235.638.558 0 .322-.317.609-.639.609s-.528-.287-.528-.61c0-.322.206-.557.528-.557zm0-2.333c.321 0 .638.235.638.557 0 .322-.317.61-.639.61s-.528-.288-.528-.61.206-.557.528-.557zm0-2.333c.321 0 .638.235.638.557 0 .322-.317.61-.639.61s-.528-.288-.528-.61.206-.557.528-.557zm0-2.334c.321 0 .638.235.638.558 0 .322-.317.609-.639.609s-.528-.287-.528-.61c0-.322.206-.557.528-.557zm0-2.333c.321 0 .638.235.638.557 0 .322-.317.61-.639.61s-.528-.288-.528-.61S21.54.5 21.861.5zM7.956.378c.322 0 .543.261.543.583 0 .322-.22.583-.543.583-.322 0-.624-.26-.624-.583 0-.322.302-.583.624-.583zm2.334 0c.322 0 .542.261.542.583 0 .322-.22.583-.542.583-.323 0-.624-.26-.624-.583 0-.322.301-.583.624-.583zm2.333 0c.322 0 .543.261.543.583 0 .322-.22.583-.543.583-.322 0-.624-.26-.624-.583 0-.322.302-.583.624-.583zm2.333 0c.322 0 .543.261.543.583 0 .322-.22.583-.543.583-.322 0-.624-.26-.624-.583 0-.322.302-.583.624-.583zm2.334 0c.322 0 .542.261.542.583 0 .322-.22.583-.542.583-.323 0-.624-.26-.624-.583 0-.322.301-.583.624-.583zm2.333 0c.322 0 .543.261.543.583 0 .322-.22.583-.543.583-.322 0-.624-.26-.624-.583 0-.322.302-.583.624-.583z" />
    </SvgIcon>
  );
}
