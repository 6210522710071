import defaultMessages from 'ra-language-english';

const messages = {
  ...defaultMessages,
  validation: {
    date: 'Must be a valid date',
    regexPattern: 'Must be a valid format',
    'file-invalid-type': 'Invalid file type'
  },
  auth: {
    login: {
      title: 'Login',
      fields: {
        username: 'Username or email',
        password: 'Password',
        submit: 'Login'
      },
      page: {
        forgotPassword: 'Forgot password?'
      },
      errors: {
        invalidCredentials: 'Invalid credentials'
      }
    },
    forgotPassword: {
      title: 'Forgot password?',
      fields: {
        username: 'Username or email',
        submit: 'Reset password'
      },
      page: {
        backToLogin: 'Back to login'
      },
      errors: {
        request: 'An error occurred while submitting your request.',
        notFound: 'User not found.'
      },
      success: 'Password reset email sent.'
    },
    resetPassword: {
      title: 'Reset password',
      fields: {
        password: 'Password',
        passwordRepeat: 'Repeat password',
        submit: 'Reset password'
      },
      errors: {
        passwordMismatch: 'Passwords do not match',
        request: 'An error occurred while submitting your request.'
      },
      success: 'Password resetted successfully.'
    }
  },
  cache: {
    sync: 'Synchronize data',
    syncing: 'Synchronizing data'
  },
  serviceWorker: {
    newVersion:
      'There is a new version available. Please, reload the page to apply it.'
  },
  offline: {
    msg: 'Cannot display this content with no internet connection'
  },
  errors: {
    unrecognized_error: 'Unrecognized error'
  },
  general: {
    download: 'Download',
    form: {
      dropZone: {
        image: {
          message: 'Drop image here'
        },
        file: {
          message: 'Drop file here'
        }
      }
    },
    lang: {
      es: 'Spanish',
      en: 'English'
    },
    message: {
      save_title: 'Are you sure you want to save this %{name}?',
      save_content: 'Save %{name} #%{id}'
    }
  },
  filters: {
    date: {
      start: 'Start',
      end: 'End'
    }
  },
  resources: {
    users: {
      name: 'User |||| Users',
      breadcrumb: {
        all: 'All users',
        edit: 'Edit',
        create: 'Create'
      },
      fields: {
        username: 'User',
        firstName: 'Name',
        lastName: 'Surname',
        email: 'Email',
        tags: 'Tags',
        role: 'Role',
        position: 'Position'
      },
      errors: {
        create: 'Could not create user.',
        edit: 'Could not update user.'
      },
      search: {
        label: 'Search'
      }
    },
    machines: {
      name: 'Machine |||| Machines',
      fields: {
        code: 'Code',
        name: 'Name',
        patternGroup: 'Standard group',
        hasExtraField: 'Has Extra Field',
        checklist: 'Checklist',
        checklistName: 'Checklist name %{code}',
        task: 'Task',
        observations: 'Observations',
        image: 'Image',
        observationsLang: 'Observations %{lang}',
        nameLang: 'Machine name %{lang}',
        procedureNoIns: 'Procedure No Ins',
        lastUpdate: 'Last update'
      },
      form: {
        tabs: {
          magnitudes: 'Magnitudes and checklist',
          procedure: 'Procedure'
        },
        buttons: {
          addPatternGroup: 'Add group of Standards',
          addChecklist: 'Add checklist'
        }
      },
      breadcrumb: {
        all: 'All machines',
        edit: 'Edit machine',
        create: 'Create machine'
      },
      search: {
        label: 'Search'
      }
    },
    clients: {
      name: 'Client |||| Clients',
      fields: {
        name: 'Name',
        tag: 'Tag',
        erpCode: 'ERP code'
      },
      breadcrumb: {
        all: 'All clients'
      },
      search: {
        label: 'Search'
      },
      success: {
        create: 'Create successfull',
        edit: 'Editing successfull'
      },
      errors: {
        create: 'Error creating client'
      },
      modal: {
        add: 'Add client',
        edit: 'Edit client'
      }
    },
    'client-machines': {
      name: 'Client machine |||| Client machines',
      fields: {
        name: 'Machine',
        calibrated: 'Calibrated',
        date: 'Date',
        serialNumber: 'Serial number',
        lastCertificateNumber: 'Last certificate number'
      },
      calibrated: {
        true: 'Calibrated',
        false: 'Not calibrated'
      },
      modal: {
        add: 'Add machine',
        transferClient: {
          title: 'Transfer machine',
          description:
            'This machine will transfered to other client. All certificates will be also transfered.',
          fields: {
            client: 'Client'
          },
          success: {
            message: 'Machine transfered'
          },
          errors: {
            message: 'Error transfering machine'
          }
        }
      }
    },
    'pattern-groups': {
      name: 'Standard group |||| Standard groups',
      breadcrumb: {
        all: 'All Standard groups'
      },
      fields: {
        name: 'Name',
        magnitude: 'Magnitude'
      },
      modal: {
        add: 'Add Standard group'
      },
      success: {
        create: 'Standard group created successfully.'
      },
      errors: {
        create: 'Error creating Standard group.'
      },
      buttons: {
        selectPatterns: 'Select Standards'
      },
      titles: {
        selectedPatterns: 'Selected Standards'
      }
    },
    patterns: {
      name: 'Standard |||| Standards',
      fields: {
        name: 'Name',
        code: 'Code',
        nameLang: 'Name %{lang}',
        required: 'Required on PDF'
      },
      modal: {
        add: 'Add Standard',
        edit: 'Edit Standard'
      },
      success: {
        create: 'Standard created successfully.',
        edit: 'Standard edited successfully.'
      },
      errors: {
        create: 'Error creating Standard.',
        name: {
          unique: 'Standard name already exists.'
        },
        code: {
          unique: 'Standard code already exists.'
        }
      }
    },
    versions: {
      name: 'Version |||| Versions',
      forcedCaseName: 'version |||| versions',
      fields: {
        name: 'Name',
        value: 'Value',
        uncertainty: 'Uncertainty',
        unit: 'Unit',
        activatedAt: 'Activated at'
      },
      modal: {
        add: 'Add version',
        edit: 'Edit version'
      },
      success: {
        create: 'Version created successfully.',
        edit: 'Version edited successfully.'
      },
      errors: {
        create: 'Error creating version.',
        edit: 'Error editing version.'
      }
    },
    certificates: {
      name: 'Certificate |||| Certificates',
      forcedCaseName: 'certificate |||| certificates',
      fields: {
        certificateNumber: 'Certificate number',
        date: 'Date',
        user: 'User',
        checklist: 'Checklist',
        manufacturer: 'Manufacturer',
        model: 'Model',
        serialNumber: 'Serial number',
        code: 'Code',
        temperature: 'Temperature',
        humidity: 'Humidity',
        manualSign: 'Manual sign',
        observationsLang: 'Observations %{lang}',
        magnitude: {
          scale: 'Measurement range / Scale',
          scaleDivision: 'Scale division',
          resolution: 'Resolution',
          indicator: 'Indicator device',
          satNote: 'SAT Note',
          clientObservation: 'Observation - Client (%{lang})'
        },
        status: 'Status:',
        pending: 'Pending',
        done: 'Done',
        customId: 'Id',
        measurement: 'Measurement',
        pattern: 'Standard',
        value: 'Value %{value}',
        measurementResult: 'Measurement',
        measurementResultUnit: 'Measurement (%{unit})',
        patternResult: 'Standard',
        patternResultUnit: 'Standard (%{unit})',
        errorResult: 'Error',
        errorResultUnit: 'Error (%{unit})',
        siResult: 'Si',
        siResultUnit: 'Si (%{unit})',
        liResult: 'Li',
        liResultUnit: 'Li (%{unit})',
        revisedAt: 'Revised at',
        revisionInterval: 'Revision interval',
        unit: 'Unit',
        displayValuesId: 'Display value ID'
      },
      download: {
        confirm: {
          title: 'There are unsaved changes',
          content: 'Are you sure you want to download this certificate?'
        },
        errors: {
          missingSignature: 'Missing certificate signature',
          invalidSignaturePassword: 'Invalid signature password',
          failedToDownload: 'Failed to download certificate'
        }
      },
      success: {
        create: 'Certificate created successfully',
        edit: 'Certificate edited successfully'
      },
      errors: {
        create: 'Error creating certificate',
        certificateNumber: {
          unique: 'Certificate number already exists',
          toLarge: 'The number could not be larger than 2147483647',
          toSmall: 'The number could not be smaller than -2147483647'
        }
      },
      tabs: {
        generalData: 'General data'
      },
      breadcrumb: {
        create: 'Create',
        edit: 'Edit'
      },
      title: {
        add: 'Add certificate',
        edit: 'Edit certificate'
      },
      warnings: {
        thisMachineHasNewChecklists: 'This machine has new checklists',
        valuesChanged:
          'The values ​​have changed and the results do not correspond to the data entered. Refresh to recalculate the results.Los valores han cambiado y los resultado no corresponden a los datos introducidos. Actualiza para recarcular los resultados.'
      },
      buttons: {
        update: 'Update'
      },
      modal: {
        title: 'Download certificate',
        language: 'Language'
      }
    }
  }
};

export default messages;
