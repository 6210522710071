import {
  AlignmentButtons,
  ColorButtons,
  FormatButtons,
  ListButtons,
  RichTextInput as RTI,
  RichTextInputToolbar
} from 'ra-input-rich-text';

type Props = {
  source: string;
  label?: string;
};

export default function RichTextInput({ source, label }: Props) {
  const Toolbar = ({ size, ...props }) => {
    return (
      <RichTextInputToolbar {...props}>
        <FormatButtons
          size={size}
          sx={{
            '& .MuiToggleButton-root[value="strike"]': { display: 'none' },
            '& .MuiToggleButton-root[value="code"]': { display: 'none' },
            '& .MuiToggleButton-root[value="underline"]': {
              borderRadius: '0px 4px 4px 0px'
            }
          }}
        />
        <ColorButtons size={size} />
        <AlignmentButtons size={size} />
        <ListButtons size={size} />
      </RichTextInputToolbar>
    );
  };

  return (
    <RTI
      source={source}
      label={label ?? ' '}
      toolbar={<Toolbar size="medium" />}
    />
  );
}
