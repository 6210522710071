import { Labeled, TextInput, required, useTranslate } from 'react-admin';
import CheckboxInput from '../../../shared/CheckboxInput';
import { LANGS } from '../../../../providers/i18nProvider';
import { MULTILINE_TEXT_ROWS } from '../../../../core/constants/input.constants';
import { checkCertificateNumberExists } from '../../../../core/services/certificate-api.service';
import DatePickerInput from '../../../shared/DatePickerInput';
import { MAX_NUMBER } from '../../../../core/constants/number.constants';
import { NumberInput } from '../../../shared/NumberInput';
import SwitchInput from '../../../shared/SwitchInput';
import { useFormContext } from 'react-hook-form';
import { FormCertificate } from '../../../../core/models/certificate';

function validateCertificateNumberIsUnique(original?: number) {
  return async (value: number) => {
    const { exists } = await checkCertificateNumberExists(value);
    if (original !== undefined && original === +value) return undefined;
    if (exists) return 'resources.certificates.errors.certificateNumber.unique';
  };
}

function validateNumber() {
  return async (value: number) => {
    if (value > MAX_NUMBER) {
      return 'resources.certificates.errors.certificateNumber.toLarge';
    }
    if (value < -1 * MAX_NUMBER) {
      return 'resources.certificates.errors.certificateNumber.toSmall';
    }
  };
}

type CertificateFormGeneralDataProps = {
  originalCertificateNumber?: number;
};

export const CertificateFormGeneralData = ({
  originalCertificateNumber
}: CertificateFormGeneralDataProps) => {
  const t = useTranslate();

  const { watch } = useFormContext<FormCertificate>();
  const isCertificateBlocked = watch('done');

  return (
    <>
      <div className="select-patterns">
        <div></div>
        <div>
          <SwitchInput
            source="done"
            label="resources.certificates.fields.status"
            labelPlacement="start"
            leftLabel="resources.certificates.fields.pending"
            rightLabel="resources.certificates.fields.done"
          />
        </div>
      </div>
      <div className="two-columns-form">
        <div className="fieldset fieldset-input-group">
          <Labeled className="labeled-input">
            <NumberInput
              source="certificateNumber"
              validate={[
                required(),
                validateNumber(),
                validateCertificateNumberIsUnique(originalCertificateNumber)
              ]}
              disabled={isCertificateBlocked}
            />
          </Labeled>
          <Labeled className="labeled-input">
            <TextInput source="manufacturer" disabled={isCertificateBlocked} />
          </Labeled>
          <Labeled className="labeled-input">
            <TextInput source="model" disabled={isCertificateBlocked} />
          </Labeled>
          <Labeled className="labeled-input">
            <TextInput source="serialNumber" disabled={isCertificateBlocked} />
          </Labeled>
          <Labeled className="labeled-input">
            <TextInput source="code" disabled={isCertificateBlocked} />
          </Labeled>
          <CheckboxInput source="manualSign" disabled={isCertificateBlocked} />
          <Labeled className="labeled-input">
            <NumberInput source="humidity" disabled={isCertificateBlocked} />
          </Labeled>
          <Labeled className="labeled-input">
            <NumberInput source="temperature" disabled={isCertificateBlocked} />
          </Labeled>
          <Labeled className="labeled-input">
            <DatePickerInput
              source="revisedAt"
              required
              disabled={isCertificateBlocked}
            />
          </Labeled>
          <Labeled className="labeled-input">
            <NumberInput
              source="revisionInterval"
              min="1"
              defaultValue={1}
              validate={required()}
              disabled={isCertificateBlocked}
            />
          </Labeled>
        </div>
        <div className="fieldset fieldset-observations fieldset-input-group">
          {LANGS.map((language) => (
            <Labeled
              label={t('resources.certificates.fields.observationsLang', {
                lang: language.code
              })}
              className="labeled-input"
              key={language.code}
            >
              <TextInput
                source={`observation.${language.code}`}
                multiline
                rows={MULTILINE_TEXT_ROWS}
                key={crypto.randomUUID()}
                disabled={isCertificateBlocked}
              />
            </Labeled>
          ))}
        </div>
      </div>
    </>
  );
};
