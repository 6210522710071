import { SaveUserRequest, UserResponse, User, UserTag } from '../models/user';
import { mapCollection } from './collection-utils';
import { convertFileToBase64 } from './file-utils';

export const transformFromApi = (record: UserResponse): User => ({
  id: record?.id,
  username: record?.username,
  email: record?.email,
  firstName: record?.firstName,
  lastName: record?.lastName,
  tags: transformFromTags(record?.tags ?? []),
  roleCode: record?.role?.code,
  position: record?.position,
  signingKey: record?.signingKey
});

const transformFromTags = (tags: UserTag[]): string[] =>
  mapCollection((tag) => tag.tagId, tags);

export const transformToApi = async (data: User): Promise<SaveUserRequest> => ({
  id: data.id,
  username: data.username,
  email: data.email,
  firstName: data.firstName,
  lastName: data.lastName,
  password: data?.password,
  tags: transformToTags(data.tags),
  roleCode: data.roleCode,
  position: data.position,
  signingKey: data.signingKey.rawFile
    ? await convertFileToBase64(data.signingKey.rawFile)
    : data.signingKey.title,
  signingKeyPass: data.signingKeyPass
});

const transformToTags = (tags: string[]): UserTag[] =>
  mapCollection(
    (tag) => ({
      tagId: tag
    }),
    tags
  );
