import { CachePattern, CachePatternLanguage } from '../../core/models/cache';
import { Pattern, PatternLanguage } from '../../core/models/pattern';
import { mapCacheVersionToDBVersion } from '../version';
import { DBPattern, DBPatternLanguage } from './types';

export function mapCachePatternToDBPattern(
  pattern: CachePattern,
  patternGroupId: string
): DBPattern {
  return {
    id: pattern.id,
    patternGroupId,
    code: pattern.code,
    lastVersion:
      pattern.lastVersion === null
        ? null
        : mapCacheVersionToDBVersion(pattern.lastVersion),
    languages: pattern.languages.map(
      mapCachePatternLanguageToDBCachePatternLanguage
    )
  };
}

export function mapCachePatternLanguageToDBCachePatternLanguage(
  language: CachePatternLanguage
): DBPatternLanguage {
  return {
    code: language.code,
    name: language.name
  };
}

export function mapDBPatternToPattern(pattern: DBPattern): Pattern {
  return {
    id: pattern.id,
    code: pattern.code,
    languages: pattern.languages.map(mapDBPatternLanguageToPatternLanguage)
  };
}

export function mapDBPatternLanguageToPatternLanguage(
  language: DBPatternLanguage
): PatternLanguage {
  return {
    code: language.code,
    name: language.name
  };
}
