import { Unit } from '../models/unit';
import axiosInstance, { getDefaultRequestConfig } from './base/axios.client';

export async function getPatternGroupUnits(patternGroupId: string) {
  return await axiosInstance.get<Unit[]>(
    `/pattern-groups/${patternGroupId}/units`,
    getDefaultRequestConfig()
  );
}

export async function getMagnitudeUnits(magnitudeCode: string) {
  return await axiosInstance.get<Unit[]>(
    `/magnitudes/${magnitudeCode}/units`,
    getDefaultRequestConfig()
  );
}
