import { Version } from '../models/version';
import { query } from '../utils/url';
import axiosInstance, { getDefaultRequestConfig } from './base/axios.client';

export async function getLastVersions(
  patternIds: string[]
): Promise<Version[]> {
  const patternsFilter = query({ patternId: patternIds });
  const { data } = await axiosInstance.get(
    `versions/last?${patternsFilter}`,
    getDefaultRequestConfig()
  );

  return data;
}
