const MuiAutocomplete = () => ({
  styleOverrides: {
    root: {
      width: '100%',
      '& .MuiFormLabel-root': {
        top: '-6px'
      }
    },
    inputRoot: {
      padding: '0 9px',
      height: '40px'
    },
    input: {
      height: '23px'
    }
  }
});

export default MuiAutocomplete;
