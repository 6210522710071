import {
  ArrayInput,
  CheckboxGroupInput,
  Labeled,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedShowLayout,
  TextInput,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '../../../shared/icons/DeleteIcon';
import FormFooterToolbar from '../../../general/FormFooterToolbar';
import InputDropZone from '../../../shared/InputDropZone';
import { transformFromApi } from '../../../../core/utils/machine-util';
import RichTextInput from '../../../shared/RichTextInput';
import { getPatternGroups } from '../../../../core/services/pattern-groups-api.service';
import { LANGS } from '../../../../providers/i18nProvider';
import { MULTILINE_TEXT_ROWS } from '../../../../core/constants/input.constants';
import ChecklistsInputs from '../../../shared/ChecklistsInputs';
import DatePickerInput from '../../../shared/DatePickerInput';

export const MachineForm = () => {
  const t = useTranslate();
  const [patternGroupsChoices, setPatternGroupsChoices] = useState([]);

  const record = useRecordContext();
  const transformedRecord = transformFromApi(record);

  useEffect(() => {
    getPatternGroupsChoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPatternGroupsChoices = async () => {
    const patternGroups = await getPatternGroups({ _sort: 'name' });
    setPatternGroupsChoices(patternGroups?.data);
  };

  return (
    <SimpleForm toolbar={<FormFooterToolbar />} record={transformedRecord}>
      <div className="fieldset fieldset-observations">
        {LANGS.map((language) => (
          <Labeled
            key={crypto.randomUUID()}
            label={t('resources.machines.fields.nameLang', {
              lang: language.code
            })}
            className="labeled-input"
          >
            <TextInput source={`name.${language.code}`} validate={required()} />
          </Labeled>
        ))}
      </div>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="resources.machines.form.tabs.magnitudes">
          <div className="fieldset fieldset-magnitud no-margin">
            <div className="custom-iterator-header">
              <div>{t('resources.machines.fields.patternGroup')}</div>
              <div>{t('resources.machines.fields.hasExtraField')}</div>
            </div>
            <ArrayInput label=" " source="patternGroups">
              <SimpleFormIterator
                className="custom-iterator"
                disableReordering
                disableClear
                addButton={
                  <Button variant="outlined">
                    {t('resources.machines.form.buttons.addPatternGroup')}
                  </Button>
                }
                removeButton={<DeleteIcon />}
              >
                <SelectInput
                  label=" "
                  source="patternGroup.id"
                  choices={patternGroupsChoices}
                  className="custom-iterator-field"
                />
                <CheckboxGroupInput
                  label=""
                  source="fields"
                  choices={[{ id: 'hasExtraField', name: '' }]}
                  className="custom-iterator-field align-center"
                  sx={{
                    '& .MuiFormControlLabel-root': {
                      marginRight: 0
                    },
                    '& .RaCheckboxGroupInputItem-checkbox': {
                      height: '42px'
                    }
                  }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </div>
          <div className="fieldset fieldset-checklist">
            <div className="fieldset-title">
              {t('resources.machines.fields.checklist')}
            </div>
            <ChecklistsInputs />
          </div>
          <div className="fieldset fieldset-observations">
            {LANGS.map((language) => (
              <Labeled
                label={t('resources.machines.fields.observationsLang', {
                  lang: language.code
                })}
                className="labeled-input"
                key={crypto.randomUUID()}
              >
                <TextInput
                  source={`observation.${language.code}`}
                  multiline
                  rows={MULTILINE_TEXT_ROWS}
                  key={crypto.randomUUID()}
                />
              </Labeled>
            ))}
          </div>
          <div>{t('resources.machines.fields.image')}</div>
          <InputDropZone source="image" label=" " />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="resources.machines.form.tabs.procedure">
          <div className="fieldset fieldset-two-columns">
            <Labeled
              className="labeled-input"
              label={t('resources.machines.fields.procedureNoIns')}
            >
              <TextInput source="procedure.procedureNoIns" />
            </Labeled>
            <Labeled
              className="labeled-input"
              label={t('resources.machines.fields.lastUpdate')}
            >
              <DatePickerInput source="procedure.lastUpdate" />
            </Labeled>
          </div>
          <div className="fieldset fieldset-two-columns">
            {LANGS.map((language) => (
              <RichTextInput
                label={language.code}
                source={`procedure.blocks.0.languages.text.${language.code}`}
                key={crypto.randomUUID()}
              />
            ))}
          </div>
          <InputDropZone source="procedure.blocks.0.image" label=" " />
          <div className="fieldset fieldset-two-columns">
            {LANGS.map((language) => (
              <RichTextInput
                label={language.code}
                source={`procedure.blocks.1.languages.text.${language.code}`}
                key={crypto.randomUUID()}
              />
            ))}
          </div>
          <InputDropZone source="procedure.blocks.1.image" label=" " />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </SimpleForm>
  );
};
