import {
  ArrayInput,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  Toolbar,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate
} from 'react-admin';
import { useParams } from 'react-router';
import DeleteIcon from '../shared/icons/DeleteIcon';
import SaveWithConfirmButton from '../shared/SaveWithConfirmButton';
import { useQueryClient } from 'react-query';
import { useSetAtom } from 'jotai';
import { editAtom } from '../shared/EditListener';
import queryKeys from '../../core/hooks/query-keys';
import { Version } from '../../core/models/version';
import { NumberInput } from '../shared/NumberInput';
import { VersionValue } from '../../core/models/versionValue';

type Props = {
  confirm?: boolean;
};

export const PatternGroupsPatternVersionShowForm = ({ confirm }: Props) => {
  const record = useRecordContext<Version>();
  const { id } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();

  const [update] = useUpdate(undefined, undefined, {
    onError: (error) => {
      notify('resources.versions.errors.edit', { type: 'error' });
      refresh();
    },
    onSuccess: () => {
      notify('resources.versions.success.edit', {
        type: 'success'
      });
      refresh();
    }
  });

  function handleSubmit(data: { values: VersionValue[] }) {
    update('versions', {
      id: `${id}/values`,
      data: {
        values: data.values.map((v) => ({
          ...v,
          value: Number(v.value.toString().replace(',', '.')),
          uncertainty: Number(v.uncertainty.toString().replace(',', '.'))
        }))
      }
    });
  }

  const setEdit = useSetAtom(editAtom);
  const queryClient = useQueryClient();
  const handleConfirm = async () => {
    setEdit(false);
    await queryClient.invalidateQueries(queryKeys.versions.detail(record.id));
    await queryClient.refetchQueries(queryKeys.versions.detail(record.id));
  };

  return (
    <SimpleForm
      toolbar={
        <Toolbar className="footertoolbar">
          {confirm ? (
            <SaveWithConfirmButton
              translateOptions={{ id: record.name }}
              customSubmit={handleSubmit}
              onConfirm={handleConfirm}
            />
          ) : (
            <SaveButton
              type="button"
              label="ra.action.save"
              variant="contained"
              icon={null}
            />
          )}
        </Toolbar>
      }
      className="patterns-form"
    >
      <ArrayInput source="values" label="">
        <SimpleFormIterator
          disableReordering
          disableClear
          removeButton={<DeleteIcon />}
          className="custom-iterator"
        >
          <NumberInput
            label="resources.versions.fields.value"
            source="value"
            className="outer-label"
          />
          <NumberInput
            label="resources.versions.fields.uncertainty"
            source="uncertainty"
            className="outer-label"
          />
          <div className="unit">{record.unit.symbol}</div>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
