import { useRecordContext } from 'react-admin';
import { getMachineName } from '../../core/utils/machine-util';

type Props = {
  source?: string;
};

export const MachineNameField = ({ source }: Props) => {
  const contextRecord = useRecordContext();
  const record = source ? contextRecord[source] : contextRecord;

  const name = getMachineName(record);

  return <span>{name}</span>;
};
