import { useQuery } from 'react-query';
import { getPatternsByIds } from '../services/patterns-api.service';
import queryKeys from './query-keys';

type UsePatternsOpts = {
  ids: string[];
};

export default function usePatterns({ ids }: UsePatternsOpts) {
  return useQuery(
    queryKeys.patterns.list({ ids }),
    () => getPatternsByIds({ ids }),
    { refetchOnWindowFocus: false }
  );
}
