import { FormTabProps, TabbedForm } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Card } from '@mui/material';
import { useState } from 'react';
import {
  FormCertificate,
  FormCertificatePatternGroup
} from '../../../../core/models/certificate';
import CertificateAddTabPatternGroupList from './CertificateAddTabPatternGroupList';
import usePatternGroups from '../../../../core/hooks/usePatternGroups';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

type CertificateAddTabProps = Omit<
  FormTabProps,
  'label' | 'onClick' | 'icon'
> & {
  onGroupClick: (group: FormCertificatePatternGroup) => void;
};

export default function CertificateAddTab({
  onGroupClick,
  ...props
}: CertificateAddTabProps) {
  const [showGroups, setShowGroups] = useState(false);

  const { data: patternGroups } = usePatternGroups({ _sort: 'name' });

  function handleTabClick(e: React.MouseEvent) {
    e.preventDefault();
    setShowGroups((prev) => !prev);
  }

  function handleGroupClick(
    group: FormCertificatePatternGroup,
    e: React.MouseEvent
  ) {
    e.stopPropagation();
    e.preventDefault();
    setShowGroups(false);
    onGroupClick(group);
  }

  const { watch } = useFormContext<FormCertificate>();
  const isCertificateBlocked = watch('done');

  if (isCertificateBlocked) return null;

  return (
    <TabbedForm.Tab
      {...props}
      label=""
      className={clsx('add-tab', { opened: showGroups })}
      onClick={handleTabClick}
      style={{ position: 'relative', overflow: 'visible' }}
      icon={
        <>
          <AddIcon />
          {showGroups && (
            <Card className="tab-dropdown">
              <CertificateAddTabPatternGroupList
                patternGroups={patternGroups}
                onGroupClick={handleGroupClick}
              />
            </Card>
          )}
        </>
      }
    />
  );
}
