const RaTabbedForm = (palette, defaultPadding) => ({
  styleOverrides: {
    root: {
      '& .MuiCardContent-root': {
        backgroundColor: palette.light.grey,
        padding: defaultPadding
      }
    }
  }
});

export default RaTabbedForm;
