import CloseIcon from '@mui/icons-material/Close';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormCertificate } from '../../../../core/models/certificate';

type CertificateFormPatternGroupTabCloseIconProps = {
  onClick: () => void;
  position: number;
};

export default function CertificateFormPatternGroupTabCloseIcon({
  onClick,
  position
}: CertificateFormPatternGroupTabCloseIconProps) {
  const { remove } = useFieldArray({ name: 'patternGroups' });

  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    remove(position);
    onClick();
  }

  const { watch } = useFormContext<FormCertificate>();
  const isCertificateBlocked = watch('done');

  if (isCertificateBlocked) return null;

  return <CloseIcon fontSize="small" onClick={handleClick} />;
}
