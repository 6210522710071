import {
  Button,
  Labeled,
  required,
  TextInput,
  useTranslate
} from 'react-admin';
import MagnitudeUnitInput from '../../../shared/MagnitudeUnitInput';
import SwitchInput from '../../../shared/SwitchInput';
import { LANGS } from '../../../../providers/i18nProvider';
import { MULTILINE_TEXT_ROWS } from '../../../../core/constants/input.constants';
import {
  FormCertificate,
  FormCertificatePatternGroup
} from '../../../../core/models/certificate';
import { useState } from 'react';
import CertificateMagnitudeFormPatterns from './CertificateMagnitudeFormPatterns';
import { CertificateFormValues } from './CertificateFormValues';
import { CertificateFormResults } from './CertificateFormResults';
import CertificatePatternsDialog from './CertificatePatternsDialog';
import { useFormContext } from 'react-hook-form';
import { NumberInput } from '../../../shared/NumberInput';
import CheckboxInput from '../../../shared/CheckboxInput';

type Props = {
  patternGroup: FormCertificatePatternGroup;
  position: number;
};

export default function CertificateMagnitudeForm({
  patternGroup,
  position
}: Props) {
  const t = useTranslate();

  const [showDialog, setShowDialog] = useState(false);

  const { setValue, watch } = useFormContext<FormCertificate>();
  const isCertificateBlocked = watch('done');

  const patternIds = watch(`patternGroups.${position}.patternIds`, []);
  function handleRemovePattern(id: string) {
    setValue(
      `patternGroups.${position}.patternIds`,
      patternIds.filter((id2) => id !== id2),
      { shouldDirty: true }
    );
  }

  function handleSavePatterns(data: string[]) {
    setValue(`patternGroups.${position}.patternIds`, data, {
      shouldDirty: true
    });
  }

  return (
    <>
      <CertificatePatternsDialog
        show={showDialog}
        onClose={() => setShowDialog(false)}
        onSave={handleSavePatterns}
        patternIds={patternIds}
        patternGroupId={patternGroup.patternGroupId}
      />
      <div className="select-patterns">
        <div>
          <Button
            variant="contained"
            onClick={() => setShowDialog(true)}
            size="medium"
            disabled={isCertificateBlocked}
          >
            <>{t('resources.pattern-groups.buttons.selectPatterns')}</>
          </Button>
        </div>
        <div className="flexbox" style={{ alignItems: 'center' }}>
          <div style={{ marginTop: 10, marginRight: 10 }}>
            <CheckboxInput
              source={`patternGroups.${position}.hasExtraField`}
              label="resources.certificates.fields.displayValuesId"
              disabled={isCertificateBlocked}
            />
          </div>
          <SwitchInput
            source={`patternGroups.${position}.done`}
            label="resources.certificates.fields.status"
            labelPlacement="start"
            leftLabel="resources.certificates.fields.pending"
            rightLabel="resources.certificates.fields.done"
            disabled={isCertificateBlocked}
          />
        </div>
      </div>

      <CertificateMagnitudeFormPatterns
        patternIds={patternIds}
        onRemove={handleRemovePattern}
      />

      <div className="fieldset fieldset-two-columns">
        <div className="fieldset-two-columns">
          <MagnitudeUnitInput
            source={`patternGroups.${position}.unit`}
            magnitudeCode={patternGroup.magnitude.id}
            validate={required()}
            label="resources.certificates.fields.unit"
            disabled={isCertificateBlocked}
          />
          <Labeled className="labeled-input">
            <TextInput
              label="resources.certificates.fields.magnitude.scale"
              source={`patternGroups.${position}.scale`}
              validate={required()}
              disabled={isCertificateBlocked}
            />
          </Labeled>
        </div>
        <div className="fieldset-two-columns">
          <Labeled className="labeled-input">
            <TextInput
              label="resources.certificates.fields.magnitude.scaleDivision"
              source={`patternGroups.${position}.scaleDivision`}
              validate={required()}
              disabled={isCertificateBlocked}
            />
          </Labeled>
          <Labeled className="labeled-input">
            <NumberInput
              label="resources.certificates.fields.magnitude.resolution"
              source={`patternGroups.${position}.resolution`}
              validate={[required()]}
              disabled={isCertificateBlocked}
            />
          </Labeled>
        </div>
        <div className="fieldset fieldset-two-columns">
          <Labeled className="labeled-input">
            <TextInput
              label="resources.certificates.fields.magnitude.indicator"
              source={`patternGroups.${position}.indicator`}
              disabled={isCertificateBlocked}
            />
          </Labeled>
          <Labeled className="labeled-input">
            <TextInput
              label="resources.certificates.fields.magnitude.satNote"
              source={`patternGroups.${position}.satNote`}
              defaultValue=""
              multiline
              rows={MULTILINE_TEXT_ROWS}
              disabled={isCertificateBlocked}
            />
          </Labeled>
        </div>
      </div>
      <div className="fieldset fieldset-two-columns">
        <div className="fieldset fieldset-observations">
          {LANGS.map(({ code }) => (
            <Labeled
              key={code}
              className="labeled-input"
              label={t(
                'resources.certificates.fields.magnitude.clientObservation',
                {
                  lang: code
                }
              )}
            >
              <TextInput
                source={`patternGroups.${position}.clientObservation.${code}.text`}
                multiline
                rows={MULTILINE_TEXT_ROWS}
                disabled={isCertificateBlocked}
              />
            </Labeled>
          ))}
        </div>
      </div>
      <CertificateFormValues
        showCustomId={watch(`patternGroups.${position}.hasExtraField`)}
        position={position}
      />
      <CertificateFormResults position={position} />
    </>
  );
}
