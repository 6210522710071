import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumbs } from '@mui/material';
import breadcrumbRoutes from './breadcrumb-routes';
import { useRedirect } from 'react-admin';

type TopToolbarNavProps = {
  children: React.ReactNode;
};

export default function TopToolbarNav({ children }: TopToolbarNavProps) {
  const redirect = useRedirect();
  const breadcrumbs = useBreadcrumbs(breadcrumbRoutes, {
    disableDefaults: true
  });

  const handleClickBack = () => {
    const previous = breadcrumbs.at(-2);
    if (!previous) return; // Button should be disabled.

    redirect(previous.match.pathname);
  };

  return (
    <div className="toptoolbar__nav">
      <IconButton
        disableRipple
        disabled={breadcrumbs.length < 2}
        className="toptoolbar__backlink"
        onClick={handleClickBack}
      >
        <ArrowBackIosIcon viewBox="-4 0 24 24" />
      </IconButton>
      <div className="toptoolbar__breadcrumbs">
        <Typography variant="h1" className="toptoolbar__title">
          {children}
        </Typography>
        <Breadcrumbs aria-label="breadcrumb" separator=">">
          {breadcrumbs.map(({ breadcrumb }) => breadcrumb)}
        </Breadcrumbs>
      </div>
    </div>
  );
}
