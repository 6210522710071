import { useQuery } from 'react-query';
import { getClients } from '../services/clients-api.service';

export default function useClients(q: string) {
  return useQuery(
    ['clients', q],
    () => getClients(q).then(({ data }) => data),
    {
      refetchOnWindowFocus: false
    }
  );
}
