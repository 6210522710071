import { defaultTheme } from 'react-admin';
import MuiAutocomplete from './components/styled/MuiAutocomplete';
import MuiButton from './components/styled/MuiButton';
import MuiFormControl from './components/styled/MuiFormControl';
import MuiOutlinedInput from './components/styled/MuiOutlinedInput';
import MuiTextField from './components/styled/MuiTextField';
import RaCheckboxGroupInput from './components/styled/RaCheckboxGroupInput';
import RaFilterForm from './components/styled/RaFilterForm';
import RaLabeled from './components/styled/RaLabeled';
import RaLayout from './components/styled/RaLayout';
import RaRichTextInput from './components/styled/RaRichTextInput';
import RaSidebar from './components/styled/RaSidebar';
import RaSimpleForm from './components/styled/RaSimpleForm';
import RaSimpleFormIterator from './components/styled/RaSimpleFormIterator';
import RaTabbedShowLayout from './components/styled/RaTabbedShowLayout';
import RaTabbedForm from './components/styled/RaTabbedForm';
import RaRadioButtonGroupInput from './components/styled/RaRadioButtonGroupInput';

const DEFAULT_PADDING = '30px 25px 10px';

export const palette = {
  primary: {
    main: '#0001BE'
  },
  secondary: {
    main: '#F9F9F9'
  },
  dark: {
    main: '#000000',
    grey: '#2A2B1A'
  },
  light: {
    main: '#F9F9F9',
    grey: '#F5F5F5'
  },
  text: {
    primary: '#000000'
  }
};

export const theme = {
  ...defaultTheme,
  palette: palette,
  typography: {
    fontFamily: ['Titillium Web', 'sans-serif'].join(',')
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  sidebar: {
    width: 260,
    closedWidth: 50
  },
  components: {
    ...defaultTheme.components,
    MuiAutocomplete: MuiAutocomplete(),
    MuiButton: MuiButton(),
    MuiFormControl: MuiFormControl(),
    MuiOutlinedInput: MuiOutlinedInput(),
    MuiTextField: MuiTextField(),
    RaCheckboxGroupInput: RaCheckboxGroupInput(palette),
    RaRadioButtonGroupInput: RaRadioButtonGroupInput(palette),
    RaFilterForm: RaFilterForm(),
    RaLabeled: RaLabeled(palette),
    RaLayout: RaLayout(palette),
    RaRichTextInput: RaRichTextInput(),
    RaSidebar: RaSidebar(palette),
    RaSimpleForm: RaSimpleForm(palette, DEFAULT_PADDING),
    RaSimpleFormIterator: RaSimpleFormIterator(),
    RaTabbedForm: RaTabbedForm(palette, DEFAULT_PADDING),
    RaTabbedShowLayout: RaTabbedShowLayout(palette, DEFAULT_PADDING)
  }
};
