import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { useQuery, useQueryClient } from 'react-query';
import queryKeys from '../../core/hooks/query-keys';
import sync from '../../service-worker/sync';
import { useOfflineDb } from '../providers/OfflineDbProvider';
import { useNetworkStatus } from '../providers/NetworkStatusProvider';

export default function SyncButton() {
  const db = useOfflineDb();
  const translate = useTranslate();
  const queryClient = useQueryClient();
  const { isOffline } = useNetworkStatus();

  const { data: count, isLoading } = useQuery(
    queryKeys.cache.count,
    () => db.clients.count(),
    { refetchOnWindowFocus: false }
  );

  const [syncing, setSyncing] = useState(false);
  async function handleClick() {
    setSyncing(true);
    await sync(db);
    setSyncing(false);
    await queryClient.invalidateQueries(queryKeys.cache.all);
  }

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      disabled={count === 0 || syncing || isLoading || isOffline}
    >
      {syncing ? translate('cache.syncing') : translate('cache.sync')}
    </Button>
  );
}
