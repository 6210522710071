import { useTranslate } from 'react-admin';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormCertificate } from '../../../../core/models/certificate';
import { useState } from 'react';
import useCalculateResults from '../../../../core/hooks/useCalculateResults';
import useFormValueChanged from '../../../../core/hooks/useFormValueChanged';
import { formatThousands, round } from '../../../../core/utils/number-utils';
import { countDecimals } from '../../../../core/utils/string-utils';

type Props = {
  position: number;
};

export const CertificateFormResults = ({ position }: Props) => {
  const t = useTranslate();

  const { control, getValues, watch } = useFormContext<FormCertificate>();
  const { replace: replaceResults } = useFieldArray<FormCertificate>({
    name: `patternGroups.${position}.results`
  });

  const { calculate: calculateResults, data: calculateResultsData } =
    useCalculateResults();

  const { changed: patternsChanged } = useFormValueChanged<FormCertificate>({
    control,
    name: `patternGroups.${position}.patternIds`
  });

  const { changed: unitChanged } = useFormValueChanged<FormCertificate>({
    control,
    name: `patternGroups.${position}.unit`
  });

  const { changed: resolutionChanged } = useFormValueChanged<FormCertificate>({
    control,
    name: `patternGroups.${position}.resolution`
  });

  const { changed: valuesChanged } = useFormValueChanged<FormCertificate>({
    control,
    name: `patternGroups.${position}.values`
  });

  const handleResultsUpdate = () => {
    calculateResults({
      resolution: getValues(`patternGroups.${position}.resolution`)?.replace(
        ',',
        '.'
      ),
      unitCode: getValues(`patternGroups.${position}.unit`),
      patternGroupId: getValues(`patternGroups.${position}.patternGroupId`),
      selectedPatterns: getValues(`patternGroups.${position}.patternIds`),
      values: getValues(`patternGroups.${position}.values`).map((data) => ({
        ...data,
        measurement: Number(data.measurement?.toString().replace(',', '.')),
        datas: data.datas.map((data) => ({
          ...data,
          data: Number(data.data?.toString().replace(',', '.'))
        }))
      }))
    });
  };

  if (patternsChanged || unitChanged || resolutionChanged || valuesChanged) {
    handleResultsUpdate();
  }

  const unit = watch(`patternGroups.${position}.unit`);

  const [prevResult, setPrevResults] = useState(calculateResultsData);
  if (prevResult !== calculateResultsData) {
    replaceResults(calculateResultsData);
    setPrevResults(calculateResultsData);
  }

  const resolutionDecimals = countDecimals(
    getValues(`patternGroups.${position}.resolution`)
  );

  return (
    <table className="table-datagrid-style">
      <thead>
        <tr>
          {watch(`patternGroups.${position}.patternResultPrimary`) ? (
            <>
              <th>
                {t(
                  `resources.certificates.fields.${
                    unit ? 'patternResultUnit' : 'patternResult'
                  }`,
                  {
                    unit: unit
                  }
                )}
              </th>
              <th>
                {t(
                  `resources.certificates.fields.${
                    unit ? 'measurementResultUnit' : 'measurementResult'
                  }`,
                  {
                    unit: unit
                  }
                )}
              </th>
            </>
          ) : (
            <>
              <th>
                {t(
                  `resources.certificates.fields.${
                    unit ? 'measurementResultUnit' : 'measurementResult'
                  }`,
                  {
                    unit: unit
                  }
                )}
              </th>
              <th>
                {t(
                  `resources.certificates.fields.${
                    unit ? 'patternResultUnit' : 'patternResult'
                  }`,
                  {
                    unit: unit
                  }
                )}
              </th>
            </>
          )}
          <th>
            {t(
              `resources.certificates.fields.${
                unit ? 'errorResultUnit' : 'errorResult'
              }`,
              {
                unit: unit
              }
            )}
          </th>
          <th>
            {t(
              `resources.certificates.fields.${
                unit ? 'siResultUnit' : 'siResult'
              }`,
              {
                unit: unit
              }
            )}
          </th>
          <th>
            {t(
              `resources.certificates.fields.${
                unit ? 'liResultUnit' : 'liResult'
              }`,
              {
                unit: unit
              }
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {watch(`patternGroups.${position}.results`, []).map((result, index) => (
          <tr key={index}>
            <td>
              {formatThousands(
                round(result?.measurement, resolutionDecimals + 2)?.toFixed(
                  resolutionDecimals + 2
                )
              )}
            </td>
            <td>
              {formatThousands(
                round(result?.pattern, resolutionDecimals + 2)?.toFixed(
                  resolutionDecimals + 2
                )
              )}
            </td>
            <td>
              {formatThousands(
                round(result?.error, resolutionDecimals + 2)?.toFixed(
                  resolutionDecimals + 2
                )
              )}
            </td>
            <td>
              {formatThousands(
                round(result?.si, resolutionDecimals + 3)?.toFixed(
                  resolutionDecimals + 3
                )
              )}
            </td>
            <td>
              {formatThousands(
                round(result?.li, resolutionDecimals + 3)?.toFixed(
                  resolutionDecimals + 3
                )
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
