import {
  FormControlLabel,
  FormControlLabelProps,
  Stack,
  Switch
} from '@mui/material';
import { FieldTitle } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';

type SwitchInputProps = {
  source: string;
  label?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  leftLabel?: string;
  rightLabel?: string;
  onChange?: () => void;
  disabled?: boolean;
};

export default function SwitchInput({
  source,
  label,
  labelPlacement,
  leftLabel,
  rightLabel,
  onChange,
  disabled = false
}: SwitchInputProps) {
  const { control } = useFormContext();

  let labelStyles = {};
  if (labelPlacement === 'start') {
    labelStyles = {
      marginRight: '5px'
    };
  }
  if (labelPlacement === 'end') {
    labelStyles = {
      marginLeft: '5px'
    };
  }

  return (
    <FormControlLabel
      label={<FieldTitle label={label} source={source} />}
      labelPlacement={labelPlacement}
      control={
        <Stack direction="row" alignItems="center">
          <FieldTitle label={leftLabel} />
          <Controller
            name={source}
            control={control}
            render={({ field: props }) => (
              <Switch
                {...props}
                checked={props.value}
                onChange={(e) => {
                  props.onChange(e.target.checked);
                  onChange && onChange();
                }}
                disabled={disabled}
              />
            )}
          />
          <FieldTitle label={rightLabel} />
        </Stack>
      }
      sx={{
        marginLeft: 0,
        marginTop: '10px',
        '& .MuiFormControlLabel-label': labelStyles
      }}
    />
  );
}
