import axiosInstance, {
  getDefaultAnonymousRequestConfig
} from '../core/services/base/axios.client';

const jwtManager = () => {
  let isRefreshing: boolean | null = null;
  let logoutEventName = 'ra-logout';
  const apiUrl = process.env.REACT_APP_API_URL;
  let refreshEndpoint = `${apiUrl}/auth/token`;

  const setLogoutEventName = (name: string) => (logoutEventName = name);
  const setRefreshTokenEndpoint = (endpoint: string) =>
    (refreshEndpoint = endpoint);

  const waitForTokenRefresh = async () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }

    await isRefreshing;
    isRefreshing = null;

    return true;
  };

  const getRefreshedToken = async () => {
    const refreshToken = window.localStorage.getItem('refreshToken');

    const response = await axiosInstance.post(
      `${refreshEndpoint}?refresh_token=${refreshToken}`,
      null,
      {
        ...getDefaultAnonymousRequestConfig(),
        headers: {
          ...getDefaultAnonymousRequestConfig().headers,
          creadentials: 'include'
        }
      }
    );
    if (response.status !== 200) {
      deleteToken();
      isRefreshing = false;
    } else {
      const { token, refreshToken } = response.data;
      setToken(token, refreshToken);
      isRefreshing = true;
    }

    return isRefreshing;
  };

  const getToken = () => window.localStorage.getItem('token');

  const setToken = (token: string, refreshToken: string) => {
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('refreshToken', refreshToken);
    return true;
  };

  const deleteToken = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.setItem(logoutEventName, Date.now().toString());
    return true;
  };

  // This listener will allow to disconnect a session of ra started in another tab
  window.addEventListener('storage', (event) => {
    if (event.key === logoutEventName) {
      window.localStorage.removeItem('token');
    }
  });

  return {
    deleteToken,
    getRefreshedToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
    waitForTokenRefresh
  };
};

export default jwtManager();
