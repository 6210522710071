import { useState } from 'react';
import {
  Confirm,
  SaveButton,
  useRecordContext,
  useResourceContext,
  useSaveContext,
  useTranslate
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

type SaveWithConfirmButtonProps = {
  translateOptions?: object;
  customSubmit?: (values: unknown) => void;
  onConfirm?: () => void;
};

export default function SaveWithConfirmButton({
  translateOptions = {},
  customSubmit,
  onConfirm
}: SaveWithConfirmButtonProps) {
  const [open, setOpen] = useState(false);
  const form = useFormContext();
  const record = useRecordContext();
  const resource = useResourceContext();
  const saveContext = useSaveContext();
  const translate = useTranslate();

  function handleSaveButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  function handleSubmit(values: unknown) {
    saveContext.save(values);
  }

  async function handleConfirm() {
    if (customSubmit) {
      await customSubmit(form.getValues());
    } else {
      await form.handleSubmit(handleSubmit)();
    }
    setOpen(false);
    onConfirm?.();
  }

  return (
    <>
      <SaveButton
        type="button"
        label="ra.action.save"
        variant="contained"
        onClick={handleSaveButtonClick}
        icon={null}
        disabled={!form.formState.isDirty}
      />
      <Confirm
        isOpen={open}
        onConfirm={handleConfirm}
        onClose={handleCloseDialog}
        title="general.message.save_title"
        content="general.message.save_content"
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1
          }),
          id: record.id,
          ...translateOptions
        }}
      />
    </>
  );
}
