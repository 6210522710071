import { TableHead, TableRow, Checkbox } from '@mui/material';
import { ReactNode } from 'react';
import { TableCell } from './TableCell';
import { useListContext } from 'react-admin';

type Props = {
  columns: ReactNode;
  editColumn?: boolean;
  deleteColumn?: boolean;
};

export const ListHeader = ({
  columns,
  editColumn = false,
  deleteColumn = false
}: Props) => {
  const { data, selectedIds, onSelect, onUnselectItems } = useListContext();

  const handleCheckboxChange = () => {
    if (selectedIds.length === data.length) {
      onUnselectItems();
    } else {
      onSelect(data.map((elem) => elem.id));
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sortable={false}>
          <Checkbox
            onChange={handleCheckboxChange}
            checked={selectedIds.length === data.length}
          />
        </TableCell>
        {columns}
        {editColumn && <TableCell className="cell-action" sortable={false} />}
        {deleteColumn && <TableCell className="cell-action" sortable={false} />}
      </TableRow>
    </TableHead>
  );
};
