import { CachePatternGroup } from '../../core/models/cache';
import { DetailedCertificateOriginalPatternGroup } from '../../core/models/certificate';
import { PatternGroup } from '../../core/models/pattern-group';
import {
  mapCacheMagnitudeToDBMagnitude,
  mapDBMagnitudeToMagnitude
} from '../magnitudes';
import { DBPatternGroup } from './types';

export function mapCachePatternGroupToDBPatternGroup(
  group: CachePatternGroup
): DBPatternGroup {
  return {
    id: group.id,
    name: group.name,
    magnitude: mapCacheMagnitudeToDBMagnitude(group.magnitude),
    patternResultPrimary: group.patternResultPrimary
  };
}

export function mapDBPatternGroupToPatternGroup(
  group: DBPatternGroup
): PatternGroup {
  return {
    id: group.id,
    name: group.name,
    magnitude: mapDBMagnitudeToMagnitude(group.magnitude),
    patternIds: []
  };
}

export function mapDBPatternGroupToDetailedCertificateOriginalPatternGroup({
  id,
  name
}: DBPatternGroup): DetailedCertificateOriginalPatternGroup {
  return { id, name };
}
