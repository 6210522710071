import { Cache } from '../models/cache';
import axiosInstance from './base/axios.client';
import { getSession } from './base/storage-service';

export function getCache(clientId: string) {
  return axiosInstance
    .get<Cache>(`/cache?clientId=${clientId}`, {
      headers: {
        Authorization: `Bearer ${getSession()?.token}`,
        'Content-Type': 'application/json',
        'Accept-Language': 'en'
      }
    })
    .then(({ data }) => data);
}
