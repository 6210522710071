import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

export type NetworkStatus = {
  isOffline: boolean;
};

const NetworkStatusContext = React.createContext<NetworkStatus | null>(null);

export function useNetworkStatus() {
  return useContext(NetworkStatusContext);
}

type NetworkStatusProviderProps = {
  children: React.ReactNode;
};

export function NetworkStatusProvider({
  children
}: NetworkStatusProviderProps) {
  const queryClient = useQueryClient();
  const [status, setStatus] = useState<NetworkStatus>({ isOffline: true });

  function updateStatus() {
    fetch(process.env.PUBLIC_URL, { method: 'head', cache: 'no-store' })
      .then(() => setStatus((prev) => ({ ...prev, isOffline: false })))
      .catch(() => setStatus((prev) => ({ ...prev, isOffline: true })));
  }

  useEffect(() => {
    updateStatus();
    const intervalId = setInterval(updateStatus, 60000);

    return () => clearInterval(intervalId);
  }, []);

  // `invalideQueries` must be run as an effect
  useEffect(() => {
    queryClient.cancelQueries();
    queryClient.invalidateQueries();
  }, [queryClient, status.isOffline]);

  const [prevStatus, setPrevStatus] = useState<NetworkStatus | null>(null);
  if (prevStatus !== status) {
    setPrevStatus(status);
    navigator.serviceWorker?.controller?.postMessage({
      type: 'UPDATE_NETWORK_STATUS',
      payload: status
    });
  }
  return (
    <NetworkStatusContext.Provider value={status}>
      {children}
    </NetworkStatusContext.Provider>
  );
}
