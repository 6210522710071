import { Logout, Menu, useGetIdentity, usePermissions } from 'react-admin';
import logo from '../../../assets/images/logo-idm-test.png';
import { PrivilegeType } from '../../../core/models/privilegeType';
import { some } from '../../../core/utils/collection-utils';

export const CustomMenu = () => {
  const { permissions } = usePermissions();
  const { data: user, isLoading: isIdentityLoading } = useGetIdentity();

  return (
    <Menu className="custom-menu">
      <div className="logo">
        <img src={logo} alt="IDM test" width="88" height="48" />
      </div>
      <Menu.ResourceItem name="clients" />
      {some(
        [PrivilegeType.ADMIN, PrivilegeType.USER_MAINTENANCE],
        permissions
      ) && <Menu.ResourceItem name="pattern-groups" />}
      <Menu.ResourceItem name="machines" />
      {some([PrivilegeType.ADMIN], permissions) && (
        <Menu.ResourceItem name="users" />
      )}
      {!isIdentityLoading && (
        <span
          style={{
            marginTop: 'auto',
            marginBottom: '0px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <b>{user.fullName}</b>
        </span>
      )}
      <Logout className="logout-link" style={{ marginTop: '0px' }} />
    </Menu>
  );
};
