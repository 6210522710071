import { useLocaleState } from 'react-admin';
import { useQuery } from 'react-query';
import { getPatternGroups } from '../services/pattern-groups-api.service';
import queryKeys from './query-keys';

type Props = {
  _sort?: string;
};

export default function usePatternGroups({ _sort }: Props) {
  const [locale] = useLocaleState();
  return useQuery(
    queryKeys.patternGroups.all,
    () => getPatternGroups({ locale, _sort }).then(({ data }) => data),
    { refetchOnWindowFocus: false }
  );
}
