import {
  Datagrid,
  Labeled,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useTranslate,
  required
} from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { useEffect, useState } from 'react';
import CreateDialog from '../shared/CreateDialog';
import FormFooterToolbar from '../general/FormFooterToolbar';
import { ListEmpty } from '../shared/ListEmpty';
import { getMagnitudes } from '../../core/services/magnitudes-api.service';
import { ListDelete } from '../shared/ListDelete';
import { ListBulkActions } from '../shared/ListBulkActions';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { ListPagination } from '../shared/ListPagination';

export const PatternGroupsList = () => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [magnitudesChoices, setMagnitudesChoices] = useState([]);
  const t = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    getMagnitudesChoices();
  }, []);

  const getMagnitudesChoices = async () => {
    const magnitudes = await getMagnitudes();
    setMagnitudesChoices(
      magnitudes?.data.sort((a, b) => a.name.localeCompare(b.name))
    );
  };

  const handleClickCreate = () => {
    setShowCreateDialog(true);
  };

  const handleCreateSuccess = () => {
    notify('resources.pattern-groups.success.create', {
      type: 'success'
    });
    refresh();
  };

  const handleError = () => {
    notify('resources.pattern-groups.errors.create', { type: 'error' });
  };

  return (
    <>
      <CreateDialog
        show={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        mutationOptions={{
          onSuccess: handleCreateSuccess,
          onError: handleError
        }}
        title={t('resources.pattern-groups.modal.add')}
      >
        <SimpleForm toolbar={<FormFooterToolbar />}>
          <Labeled className="labeled-input">
            <TextInput source="name" validate={required()} />
          </Labeled>
          <Labeled className="labeled-input">
            <SelectInput
              source="magnitude"
              choices={magnitudesChoices}
              validate={required()}
            />
          </Labeled>
        </SimpleForm>
      </CreateDialog>
      <List
        hasCreate
        actions={<CustomToolbar onClickCreate={handleClickCreate} />}
        empty={<ListEmpty onCreateClick={handleClickCreate} />}
        pagination={<ListPagination />}
      >
        <Datagrid
          rowClick={(id) => `${id}/patterns`}
          className="table-action"
          bulkActionButtons={<ListBulkActions />}
          header={
            <ListHeader
              columns={
                <>
                  <TableCell source="name" />
                  <TableCell source="magnitude" sortable={false} />
                </>
              }
              deleteColumn
            />
          }
        >
          <TextField source="name" />
          <TextField
            source="magnitude.name"
            label="resources.pattern-groups.fields.magnitude"
          />
          <ListDelete nameAttr="name" />
        </Datagrid>
      </List>
    </>
  );
};
