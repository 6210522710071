import {
  ImageField,
  ImageInput,
  useRecordContext,
  useTranslate
} from 'react-admin';
import { useState } from 'react';
import get from 'lodash/get';
import UploadIcon from '../shared/icons/UploadIcon';

type Props = {
  source: string;
  label: string;
};

export default function InputDropZone({ source, label }: Props) {
  const t = useTranslate();
  const record = useRecordContext();
  const [showDropZone, setShowDropZone] = useState(!get(record, source));

  const DropZone = () => (
    <div className="dropzone">
      <UploadIcon />
      <p>{t('general.form.dropZone.image.message')}</p>
    </div>
  );

  const handleFileChange = () => setShowDropZone(false);
  const handleOnRemove = () => setShowDropZone(true);

  return (
    <ImageInput
      source={source}
      label={label}
      accept="image/*"
      placeholder={<DropZone />}
      sx={{
        '& .RaFileInput-removeButton button': { opacity: 1 },
        '& .RaFileInput-dropZone': !showDropZone ? { display: 'none' } : {},
        '& .MuiFormHelperText-root': { display: 'none' },
        '& .RaLabeled-label': { display: 'none' }
      }}
      onChange={handleFileChange}
      onRemove={handleOnRemove}
    >
      <ImageField source="src" />
    </ImageInput>
  );
}
