import {
  CalculateResultsRequest,
  DetailedCertificatePatternGroupResult
} from '../models/certificate';
import { useState } from 'react';
import { calculateResults } from '../services/certificate.service';

export default function useCalculateresults() {
  const [data, setData] = useState<DetailedCertificatePatternGroupResult[]>([]);

  const calculate = async (params: CalculateResultsRequest) => {
    setData(await calculateResults(params));
  };

  return { data, calculate };
}
