import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useSidebarState } from 'react-admin';

export function useResponsiveSideBar() {
  const [, setOpen] = useSidebarState();
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isDesktop) {
      setOpen(true);
    }
    if (isMobile) {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [isDesktop, isMobile]);

  return [isDesktop, setOpen] as const;
}
