import { useNetworkStatus } from '../providers/NetworkStatusProvider';
import { LuWifi, LuWifiOff } from 'react-icons/lu';

export default function WifiStatus() {
  const { isOffline } = useNetworkStatus();
  const size = '18px';
  return (
    <span className={isOffline ? 'wifi-icon wifi-off' : 'wifi-icon'}>
      {isOffline ? (
        <LuWifiOff size={size} color="#E03B3B" />
      ) : (
        <LuWifi size={size} color="#0001BE" />
      )}
    </span>
  );
}
