import {
  Datagrid,
  DateField,
  List,
  TextField,
  useNotify,
  useTranslate,
  useRedirect,
  RaRecord
} from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { useParams } from 'react-router-dom';
import { ListEmpty } from '../shared/ListEmpty';
import CreateDialog from '../shared/CreateDialog';
import { transformToApi } from '../../core/utils/version-utils';
import { ListDelete } from '../shared/ListDelete';
import { ListBulkActions } from '../shared/ListBulkActions';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { useState } from 'react';
import { ListEdit } from '../shared/ListEdit';
import EditDialog from '../shared/EditDialog';
import { DateTime } from 'luxon';
import VersionForm from '../versions/VersionForm';
import { ListPagination } from '../shared/ListPagination';

export const PatternGroupsPatternVersionList = () => {
  const t = useTranslate();
  const notify = useNotify();
  const { patternId } = useParams<'patternId'>();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [editingRecord, setEditingRecord] = useState<RaRecord>(null);
  const { patternGroupId } = useParams<'patternGroupId'>();

  const handleClickCreate = () => {
    setShowCreateDialog(true);
  };

  const handleClickEdit = (record: RaRecord) => {
    setEditingRecord(record);
  };

  const redirect = useRedirect();
  const handleCreateSuccess = (data: { id: string }) => {
    notify('resources.versions.success.create', {
      type: 'success'
    });
    redirect(data.id);
  };

  const handleCreateError = () => {
    notify('resources.versions.errors.create', { type: 'error' });
  };

  const handleEditSuccess = () => {
    notify('resources.versions.success.edit', {
      type: 'success'
    });
  };

  const handleEditError = () => {
    notify('resources.versions.errors.edit', { type: 'error' });
  };

  const transform = async (data) => transformToApi(data, patternId);

  return (
    <>
      <CreateDialog
        show={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        mutationOptions={{
          onSuccess: handleCreateSuccess,
          onError: handleCreateError
        }}
        title={t('resources.versions.modal.add')}
        resource="versions"
        transform={transform}
      >
        <VersionForm patternGroupId={patternGroupId} />
      </CreateDialog>
      <EditDialog
        resource="versions"
        show={editingRecord !== null}
        onClose={() => setEditingRecord(null)}
        mutationOptions={{
          onSuccess: handleEditSuccess,
          onError: handleEditError
        }}
        title={t('resources.versions.modal.edit')}
        record={editingRecord}
        transform={transformToApi}
      >
        <VersionForm
          patternGroupId={patternGroupId}
          record={{
            id: editingRecord?.id,
            name: editingRecord?.name,
            activatedAt: DateTime.fromISO(editingRecord?.activatedAt),
            unit: editingRecord?.unit.id,
            pdf: Object.fromEntries(
              Object.entries(
                (editingRecord?.files as {
                  [k: string]: { title: string; url: string };
                }) ?? {}
              ).map(([k, v]) => [
                k,
                {
                  title: v.title,
                  src: v.url
                }
              ])
            )
          }}
          confirm
        />
      </EditDialog>
      <List
        hasCreate
        actions={
          <CustomToolbar
            title={t('resources.versions.name', 2)}
            onClickCreate={handleClickCreate}
          />
        }
        resource="versions"
        filter={{ pattern_hash: patternId }}
        empty={<ListEmpty onCreateClick={handleClickCreate} />}
        pagination={<ListPagination />}
      >
        <Datagrid
          rowClick={(id) => `${id}`}
          bulkActionButtons={<ListBulkActions />}
          header={
            <ListHeader
              columns={
                <>
                  <TableCell source="name" />
                  <TableCell source="activatedAt" />
                  <TableCell source="unit" sortable={false} />
                  <TableCell className="cell-action" sortable={false} />
                </>
              }
              deleteColumn
            />
          }
        >
          <TextField source="name" />
          <DateField source="activatedAt" />
          <TextField
            label="resources.versions.fields.unit"
            source="unit.symbol"
            sortable={false}
          />
          <ListEdit onClick={handleClickEdit} />
          <ListDelete
            nameAttr="name"
            redirect={`/pattern-groups/${patternGroupId}/patterns/${patternId}/versions`}
          />
        </Datagrid>
      </List>
    </>
  );
};
