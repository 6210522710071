import { CacheClient, CacheClientMachine } from '../../core/models/cache';
import {
  ClientMachine,
  ClientMachineLanguage
} from '../../core/models/client-machine';
import { DBClientMachine, DBClientMachineLanguage } from './types';

export function mapCacheClientMachineToDBClientMachine(
  machine: CacheClientMachine,
  client: CacheClient
): DBClientMachine {
  return {
    id: machine.id,
    clientId: client.id,
    machineId: machine.machine.id,
    lastCalibratedAt: machine.lastCalibratedAt ?? '',
    serialNumber: machine.serialNumber,
    lastCertificateNumber: machine.lastCertificateNumber,
    languages: machine.machine.languages.map(
      mapCacheClientMachineLanguageToDBClientMachineLanguage
    )
  };
}

export function mapCacheClientMachineLanguageToDBClientMachineLanguage(
  language: ClientMachineLanguage
): DBClientMachineLanguage {
  return {
    code: language.code,
    name: language.name
  };
}

export function mapDBClientMachineToClientMachine(
  cm: DBClientMachine
): ClientMachine {
  return {
    id: cm.id,
    lastCalibratedAt: cm.lastCalibratedAt === '' ? null : cm.lastCalibratedAt,
    lastCertificateNumber: cm.lastCertificateNumber,
    serialNumber: cm.serialNumber,
    machine: {
      languages: cm.languages.map(
        mapDBClientMachineLanguageToClientMachineLanguage
      )
    }
  };
}

export function mapDBClientMachineLanguageToClientMachineLanguage(
  language: DBClientMachineLanguage
): ClientMachineLanguage {
  return {
    code: language.code,
    name: language.name
  };
}
