const RaFilterForm = () => ({
  styleOverrides: {
    root: {
      width: '100%',
      marginBottom: '10px'
    }
  }
});

export default RaFilterForm;
