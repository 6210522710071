import {
  EditBase,
  EditProps,
  RaRecord,
  UseUpdateMutateParams
} from 'react-admin';
import Modal from './Modal';

type EditDialogProps<RecordType extends RaRecord = any> =
  EditProps<RecordType> & {
    children: React.ReactNode;
    onClose: () => void;
    onOutsideClick?: () => void;
    record?: RaRecord;
    show: boolean;
  };

export default function EditDialog<RecordType extends RaRecord = any>({
  children,
  onClose,
  mutationOptions,
  title,
  record,
  onOutsideClick,
  show,
  ...props
}: EditDialogProps<RecordType>) {
  const onSuccess = (
    data: RecordType,
    variables: UseUpdateMutateParams<RecordType>,
    context: unknown
  ) => {
    onClose();
    mutationOptions && mutationOptions?.onSuccess(data, variables, context);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header title={title} onClose={onClose} />
      <Modal.Body>
        <EditBase
          {...props}
          mutationOptions={{ ...mutationOptions, onSuccess }}
          id={record?.id}
          mutationMode="optimistic"
        >
          <div>{children}</div>
        </EditBase>
      </Modal.Body>
    </Modal>
  );
}
