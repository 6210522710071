import { CacheClient } from '../../core/models/cache';
import { DBClient } from './types';

export function mapCacheClientToDBClient(client: CacheClient): DBClient {
  return {
    id: client.id,
    name: client.name,
    tag: { id: client.tag.id, name: client.tag.name },
    erpCode: client.erpCode
  };
}
