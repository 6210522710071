import { useState } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useFormState,
  useWatch
} from 'react-hook-form';

type Props<T extends FieldValues = FieldValues> = {
  control: Control<T>;
  name: FieldPath<T>;
};

export default function useFormValueChanged<
  T extends FieldValues = FieldValues
>(props: Props<T>) {
  const { isDirty } = useFormState<T>(props);
  const value = useWatch(props);
  const [prevValue, setPrevValue] = useState(value);
  const changed = isDirty && prevValue !== value;

  if (value !== prevValue) {
    setPrevValue(value);
  }
  return { changed };
}
