import { CacheVersion } from '../../core/models/cache';
import { Version } from '../../core/models/version';
import { mapCacheUnitToDBUnit } from '../units';
import { mapCacheVersionValueToDBVersionValue } from '../versionValue/mappers';
import { DBVersion } from './types';

export function mapCacheVersionToDBVersion(version: CacheVersion): DBVersion {
  return {
    id: version.id,
    values: version.values.map(mapCacheVersionValueToDBVersionValue),
    unit: mapCacheUnitToDBUnit(version.unit)
  };
}

export function mapDBVersionToVersion(version: DBVersion): Version {
  return {
    id: version.id,
    name: '',
    values: version.values,
    unit: version.unit
  };
}
