import { Datagrid, List, TextInput } from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { ListBulkActions } from '../shared/ListBulkActions';
import { ListDelete } from '../shared/ListDelete';
import { ListEmpty } from '../shared/ListEmpty';
import { ListHeader } from '../shared/ListHeader';
import { TableCell } from '../shared/TableCell';
import { MachineNameField } from '../shared/MachineNameField';
import { getMachineName } from '../../core/utils/machine-util';
import { ListPagination } from '../shared/ListPagination';

const machineFilters = [
  <TextInput
    key="0"
    source="q"
    label="resources.machines.search.label"
    alwaysOn
  />
];

export const MachineList = () => {
  return (
    <List
      hasCreate
      filters={machineFilters}
      actions={<CustomToolbar />}
      empty={<ListEmpty />}
      pagination={<ListPagination />}
    >
      <Datagrid
        rowClick="edit"
        className="table-action"
        bulkActionButtons={<ListBulkActions />}
        header={
          <ListHeader columns={<TableCell source="name" />} deleteColumn />
        }
      >
        <MachineNameField />
        <ListDelete formatter={getMachineName} />
      </Datagrid>
    </List>
  );
};
