import { Create, useNotify } from 'react-admin';
import CustomToolbar from '../general/Toolbar';
import { UserForm } from './usersShared/usersSharedForm/UserForm';
import { transformToApi } from '../../core/utils/user-util';

export const UserCreate = () => {
  const notify = useNotify();
  const handleError = () => {
    notify('resources.users.errors.create', { type: 'error' });
  };

  const transform = async (data) => transformToApi(data);

  return (
    <Create
      mutationOptions={{ onError: handleError }}
      actions={<CustomToolbar />}
      transform={transform}
    >
      <UserForm isCreate />
    </Create>
  );
};
