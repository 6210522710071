import { EditButton, RaRecord, useRecordContext } from 'react-admin';
import EditIcon from './icons/EditIcon';

type Props = {
  onClick: (record: RaRecord) => void;
};

export const ListEdit = ({ onClick }: Props) => {
  const record = useRecordContext();

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(record);
  };

  return (
    <EditButton
      className="edit-button"
      icon={<EditIcon />}
      onClick={handleOnClick}
      label=""
    />
  );
};
