import { AutocompleteInput, Labeled, SimpleForm } from 'react-admin';
import FormFooterToolbar from '../general/FormFooterToolbar';
import useClients from '../../core/hooks/useClients';
import { useState } from 'react';

export const ClientMachineClientTransferForm = () => {
  const [q, setQ] = useState<string>();
  const { data } = useClients(q);

  return (
    <SimpleForm toolbar={<FormFooterToolbar />}>
      <Labeled className="labeled-input">
        <AutocompleteInput
          source="clientId"
          choices={(data ?? []).map((client) => ({
            id: client.id,
            name: client.name
          }))}
          onSelect={(value) => setQ((value.target as HTMLInputElement).value)}
        />
      </Labeled>
    </SimpleForm>
  );
};
