import { COVERAGE_FACTOR } from '../constants/certificate-result.constants';
import {
  CalculateResultsRequest,
  DetailedCertificatePatternGroupResult,
  DetailedCertificatePatternGroupValue
} from '../models/certificate';
import { getByUnits } from '../models/unitConversion';
import UnitType, { getByCode } from '../models/unitType';
import { Version } from '../models/version';
import { VersionValue } from '../models/versionValue';
import { getByMeasurementAndW } from '../models/wecc';
import {
  filter,
  filterMap,
  findIndex,
  isEmpty,
  mapCollection,
  sumUpValues
} from '../utils/collection-utils';
import { pow, sqrt } from '../utils/number-utils';
import { isNullOrUndefined } from '../utils/object-utils';
import { getLastVersions } from './versions.api.service';

export const calculateResults = (params: CalculateResultsRequest) =>
  Promise.all(
    params.values.map((value: DetailedCertificatePatternGroupValue) =>
      calculateResult(params, value)
    )
  );

const calculateResult = async (
  params: CalculateResultsRequest,
  value: DetailedCertificatePatternGroupValue
): Promise<DetailedCertificatePatternGroupResult> => {
  const data = filterMap(value.datas, (d) => d.data);
  const measurement = value.measurement;
  const pattern = calculatePattern(data);
  const error = calculateError(pattern, measurement);
  const si = calculateSi(data, pattern);
  const li = await calculateLi(
    measurement,
    si,
    params.resolution,
    data,
    params.selectedPatterns,
    params.unitCode
  );

  return {
    id: null, // TODO: generar clave random
    measurement: !isNaN(measurement) ? measurement : null,
    pattern: !isNaN(pattern) ? pattern : null,
    error: !isNaN(error) ? error : null,
    si: !isNaN(si) ? si : null,
    li: !isNaN(li) ? li : null
  };
};

const calculatePattern = (datas: number[]): number =>
  sumUpValues(datas) / datas.length;

const calculateError = (pattern: number, measurement: number): number =>
  pattern - measurement;

const calculateSi = (datas: number[], pattern: number): number | null =>
  datas.length - 1 > 0
    ? sqrt(
        sumUpValues(
          mapCollection((data: number) => pow(data - pattern, 2), datas)
        ) /
          (datas.length - 1)
      )
    : null;

const calculateLi = async (
  patternValue: number,
  si: number,
  resolution: string,
  datas: number[],
  patterns: string[],
  patternGroupUnit: string
): Promise<number | null> => {
  const datasSize = datas.length;
  const versions = await getLastVersions(patterns);
  const uncertainty = findUncertainty(versions, patternValue, patternGroupUnit);
  const objectW = getByMeasurementAndW(datasSize, COVERAGE_FACTOR);
  if (!isNullOrUndefined(uncertainty) && !isNullOrUndefined(objectW)) {
    const W = objectW.value;
    const ua = (W * si) / sqrt(datasSize);
    const u1 = Number(resolution) / sqrt(3);
    const u2 = uncertainty / 2;
    const ub = sqrt(pow(u1, 2) + pow(u2, 2));
    const u = sqrt(pow(ua, 2) + pow(ub, 2));
    const i = COVERAGE_FACTOR * u;
    return i;
  }
  return null;
};

const findUncertainty = (
  lastVersions: Version[],
  value: number,
  patternGroupUnit: string
): number => {
  if (isNullOrUndefined(value)) {
    return null;
  }
  const convertedVersions = convertLastVersionUnits(
    lastVersions,
    patternGroupUnit
  );
  const values: VersionValue[] = convertedVersions.flatMap((v) => v.values);
  const sortedValues: VersionValue[] = values.sort(
    (v1: VersionValue, v2: VersionValue) => v1.value - v2.value
  );
  const versionValue: VersionValue = selectVersionValue(sortedValues, value);
  if (!versionValue) {
    return null;
  }
  return versionValue.uncertainty;
};

const selectVersionValue = (
  sortedValues: VersionValue[],
  value: number
): VersionValue => {
  const findEqualIndex: number = findIndex(
    (v) => v.value === value,
    sortedValues
  );
  if (findEqualIndex !== -1) {
    return sortedValues.at(findEqualIndex);
  }
  const filterDownValues: VersionValue[] = filter(
    (v: VersionValue) => v.value < value,
    sortedValues
  );
  const filterUpValues: VersionValue[] = filter(
    (v: VersionValue) => v.value > value,
    sortedValues
  );
  if (isEmpty(filterDownValues) && isEmpty(filterUpValues)) {
    return null;
  }
  if (isEmpty(filterDownValues) && !isEmpty(filterUpValues)) {
    return filterUpValues.at(0);
  }
  if (!isEmpty(filterDownValues) && isEmpty(filterUpValues)) {
    return filterDownValues.at(filterDownValues.length - 1);
  }
  const downVersionValue: VersionValue = filterDownValues.at(
    filterDownValues.length - 1
  );
  const upVersionValue: VersionValue = filterUpValues.at(0);
  if (downVersionValue.uncertainty > upVersionValue.uncertainty) {
    return downVersionValue;
  }
  return upVersionValue;
};

const convertLastVersionUnits = (
  lastVersions: Version[],
  unit: string
): Version[] => {
  return lastVersions.map((lastVersion) =>
    convertLastVersionUnit(lastVersion, unit)
  );
};

const convertLastVersionUnit = (
  lastVersion: Version,
  unit: string
): Version => {
  lastVersion.values = convertVersionValuesUnits(
    lastVersion.values,
    lastVersion.unit.id,
    unit
  );
  return lastVersion;
};

const convertVersionValuesUnits = (
  values: VersionValue[],
  valueUnit: string,
  unit: string
): VersionValue[] => {
  return values.map((value) => convertVersionValueUnit(value, valueUnit, unit));
};

const convertVersionValueUnit = (
  value: VersionValue,
  versionUnit: string,
  unit: string
): VersionValue => {
  value.value = convert(getByCode(versionUnit), getByCode(unit), value.value);
  value.uncertainty = convert(
    getByCode(versionUnit),
    getByCode(unit),
    value.uncertainty
  );
  return value;
};

export const convert = (
  from: (typeof UnitType)[keyof typeof UnitType],
  to: (typeof UnitType)[keyof typeof UnitType],
  value: number
): number => {
  const conversionFactor = getByUnits(from, to);
  if (conversionFactor) {
    return value * conversionFactor;
  }
  return null;
};
