const MuiFormControl = () => ({
  defaultProps: {
    variant: 'outlined' as const
  },
  styleOverrides: {
    root: {
      width: '100%'
    }
  }
});

export default MuiFormControl;
