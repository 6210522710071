import { ReactNode } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useNetworkStatus } from '../providers/NetworkStatusProvider';
import { LuWifiOff } from 'react-icons/lu';
import { useTranslate } from 'react-admin';

type HideOfflineProps = {
  children: ReactNode;
};

const offlinePaths = ['/clients/*'];

export default function HideOffline({ children }: HideOfflineProps) {
  const translate = useTranslate();
  const { isOffline } = useNetworkStatus();

  const location = useLocation();
  const isOfflinePath = offlinePaths.some((path) =>
    matchPath(path, location.pathname)
  );

  return !isOffline || (isOffline && isOfflinePath) ? (
    <>{children}</>
  ) : (
    <div className="alert-panel">
      <div className="alert-panel__content">
        <span className="alert-panel__icon">
          <LuWifiOff size="36px" color="#E03B3B" />
        </span>
        <p>{translate('offline.msg')}</p>
      </div>
    </div>
  );
}
