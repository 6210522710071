import {
  CreateButton,
  useGetResourceLabel,
  useResourceContext,
  useResourceDefinition,
  useTranslate
} from 'react-admin';

type Props = {
  onCreateClick?: () => void;
};

export const ListEmpty = ({ onCreateClick, ...props }: Props) => {
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const { hasCreate } = useResourceDefinition(props);

  const handleOnClick = (e) => {
    if (onCreateClick) {
      e.preventDefault();
      e.stopPropagation();
      onCreateClick();
    }
  };

  const resource = useResourceContext(props);
  const resourceName = t(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: getResourceLabel(resource, 0)
  });

  return (
    <div className="empty-list">
      <p className="empty-list__text">
        {t('ra.page.empty', { name: resourceName })}
      </p>
      {hasCreate && (
        <CreateButton
          variant="contained"
          className="button-primary  button-medium"
          onClick={handleOnClick}
        />
      )}
    </div>
  );
};
