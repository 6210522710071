import { useLocaleState } from 'react-admin';
import { useQuery } from 'react-query';
import { getDetailedCertificate } from '../services/certificate-api.service';
import queryKeys from './query-keys';

export default function useDetailedCertificate(certificateId: string) {
  const [locale] = useLocaleState();
  return useQuery(
    queryKeys.certificates.detail(certificateId),
    () =>
      getDetailedCertificate(certificateId, { locale }).then(
        ({ data }) => data
      ),
    { refetchOnWindowFocus: false }
  );
}
