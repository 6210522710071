import { Pattern } from '../models/pattern';
import axiosInstance, { getDefaultRequestConfig } from './base/axios.client';

type GetPatternsOptions = {
  patternGroupId: string;
  searchTerm?: string;
  _sort?: string;
};

export async function getPatterns({
  patternGroupId,
  searchTerm,
  _sort
}: GetPatternsOptions) {
  const params = new URLSearchParams({
    ...(patternGroupId ? { pattern_group_hash: patternGroupId } : {}),
    ...(searchTerm ? { q: searchTerm } : {}),
    ...(_sort ? { _sort } : {})
  });
  return await axiosInstance.get<Pattern[]>(
    `/patterns?${params}`,
    getDefaultRequestConfig()
  );
}

type GetPatternsByIds = {
  ids: string[];
};

export async function getPatternsByIds({ ids }: GetPatternsByIds) {
  const query = ids.map((id) => `id=${id}`).join('&');
  const { data } = await axiosInstance.get<Pattern[]>(
    `/patterns?${query}`,
    getDefaultRequestConfig()
  );

  return data;
}

type PatternExistProps = {
  name?: string;
  code?: string;
};

export async function checkPatternExists(
  filters: PatternExistProps
): Promise<{ exists: boolean }> {
  const queryFilters = new URLSearchParams(filters);
  const { data } = await axiosInstance.get(
    `patterns/exists?${queryFilters}`,
    getDefaultRequestConfig()
  );

  return data;
}
