import {
  CalculateResultsRequest,
  DetailedCertificate,
  DetailedCertificatePatternGroupResult
} from '../models/certificate';
import axiosInstance, { getDefaultRequestConfig } from './base/axios.client';

type GetDetailedCertificateOptions = {
  locale: string;
};

export async function getDetailedCertificate(
  certificateId: string,
  { locale }: GetDetailedCertificateOptions
) {
  return await axiosInstance.get<DetailedCertificate>(
    `certificates/${certificateId}`,
    getDefaultRequestConfig(locale)
  );
}

type GetLastClientMachineCertificateOptions = {
  locale?: string;
};

export async function getLastClientMachineCertificate(
  clientMachineId: string,
  { locale }: GetLastClientMachineCertificateOptions = {}
) {
  const { data } = await axiosInstance.get<DetailedCertificate>(
    `client-machines/${clientMachineId}/last-certificate`,
    getDefaultRequestConfig(locale)
  );

  return data;
}

export async function downloadCertificate(
  certificateId: string,
  language: string
) {
  return await axiosInstance.post<Blob>(
    `certificates/${certificateId}/pdf`,
    undefined,
    { ...getDefaultRequestConfig(language), responseType: 'blob' }
  );
}

export async function deleteCertificatePatternGroup(
  certificatePatternGroupId: string
) {
  return await axiosInstance.delete(
    `certificate-pattern-groups/${certificatePatternGroupId}`,
    getDefaultRequestConfig()
  );
}

export async function checkCertificateNumberExists(
  certificateNumber: number
): Promise<{ exists: boolean }> {
  const { data } = await axiosInstance.get(
    `certificates/certificate-number/${certificateNumber}/exists`,
    getDefaultRequestConfig()
  );

  return data;
}

export async function calculateResults(params: CalculateResultsRequest) {
  return await axiosInstance.post<DetailedCertificatePatternGroupResult[]>(
    '/certificates/pattern-group/calculate-results',
    params,
    getDefaultRequestConfig()
  );
}
