import {
  Labeled,
  required,
  SaveButton,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  Toolbar
} from 'react-admin';
import DatePickerInput from '../shared/DatePickerInput';
import PatternGroupUnitInput from '../shared/PatternGroupUnitInput';
import { LANGS } from '../../providers/i18nProvider';
import InputFile from '../shared/InputFile';
import SaveWithConfirmButton from '../shared/SaveWithConfirmButton';

type VersionFormProps = Omit<SimpleFormProps, 'children'> & {
  patternGroupId: string;
  confirm?: boolean;
};

export default function VersionForm({
  patternGroupId,
  confirm = false,
  ...props
}: VersionFormProps) {
  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar className="footertoolbar">
          {confirm ? (
            <SaveWithConfirmButton
              translateOptions={{ id: props.record.name }}
            />
          ) : (
            <SaveButton
              type="button"
              label="ra.action.save"
              variant="contained"
              icon={null}
            />
          )}
        </Toolbar>
      }
    >
      <Labeled className="labeled-input">
        <TextInput source="name" validate={required()} />
      </Labeled>
      <Labeled className="labeled-input">
        <DatePickerInput source="activatedAt" required />
      </Labeled>
      <PatternGroupUnitInput patternGroupId={patternGroupId} required />
      {LANGS.map((language) => (
        <InputFile
          key={language.code}
          source={`pdf.${language.code}`}
          label={language.code}
          options={{ accept: 'application/pdf' }}
        />
      ))}
    </SimpleForm>
  );
}
