import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function UploadIcon(props: any) {
  const propsMerged = {
    ...props,
    viewBox: '0 0 33 30'
  };
  return (
    <SvgIcon {...propsMerged}>
      <path d="M16.611 10.142c-.111-.16-.371-.255-.588-.255a.808.808 0 0 0-.587.255l-6.65 6.834c-.328.337-.328.877 0 1.149.328.402.854.402 1.182 0l5.22-5.3v15.909c0 .477.371.858.835.858.477 0 .835-.381.835-.858v-15.91l5.221 5.301c.328.402.854.402 1.181 0 .328-.272.328-.812 0-1.15zM14.648.726c-5.045.182-9.12 4.334-9.464 9.553l-.016.287-.198.077C2.404 11.707.66 14.315.66 17.278c0 3.93 3.056 7.138 6.84 7.138h3.664c.46 0 .836-.384.836-.857s-.375-.809-.836-.809H7.5c-2.857 0-5.173-2.473-5.173-5.472 0-2.431 1.537-4.544 3.673-5.21.511-.11.827-.577.827-1.07 0-4.734 3.673-8.565 8.173-8.565s8.16 3.83 8.16 8.564c0 .647.583 1.132 1.2.961.37 0 .748-.104 1.14-.104 2.843 0 5.16 2.425 5.16 5.424 0 3-2.317 5.472-5.16 5.472h-4.586c-.46 0-.833.336-.833.809 0 .473.373.857.833.857H25.5c3.77 0 6.827-3.208 6.827-7.138 0-3.938-3.056-7.137-6.827-7.137l-.388.01-.318.028-.02-.254C24.26 4.755 20.08.72 15 .72z" />{' '}
    </SvgIcon>
  );
}
