import {
  CreateButton,
  TopToolbar,
  useGetResourceLabel,
  useResourceDefinition
} from 'react-admin';
import SyncButton from '../shared/SyncButton';
import WifiStatus from '../shared/WifiStatus';
import TopToolbarNav from './ToolbaNav';

type BaseCustomToolBarProps = {
  hasCreate?: boolean;
  onClickCreate?: () => void;
  children?: React.ReactNode;
};

type CustomToolBarProps = BaseCustomToolBarProps &
  (
    | {
        resource?: string;
        title?: never;
      }
    | {
        resource?: never;
        title?: string;
      }
  );

export default function CustomToolbar({
  resource,
  title,
  hasCreate = false,
  onClickCreate,
  children
}: CustomToolBarProps) {
  const resourceDefinition = useResourceDefinition();
  const getResourceLabel = useGetResourceLabel();

  const handleOnClickCreate = (e: React.MouseEvent) => {
    e.preventDefault();
    onClickCreate();
  };

  if (!title && !resource && !resourceDefinition?.name) return null;

  return (
    <TopToolbar className="toptoolbar">
      <TopToolbarNav>
        {title ?? getResourceLabel(resource ?? resourceDefinition.name, 2)}
      </TopToolbarNav>
      <div className="toptoolbar__buttons">
        <WifiStatus />
        <SyncButton />
        {children}
        {onClickCreate && (
          <CreateButton
            onClick={handleOnClickCreate}
            className="button-primary button-medium"
          />
        )}
        {!onClickCreate && hasCreate && (
          <CreateButton className="button-primary  button-medium" />
        )}
      </div>
    </TopToolbar>
  );
}
