import { useQuery } from 'react-query';
import { getPatterns } from '../services/patterns-api.service';
import queryKeys from './query-keys';

type UsePatternsByPatternGroupOptions = {
  patternGroupId: string;
  searchTerm?: string;
  _sort?: string;
};

export default function usePatternsByPatternGroup(
  options: UsePatternsByPatternGroupOptions
) {
  return useQuery(
    queryKeys.patterns.list({
      q: options.searchTerm,
      patternGroupId: options.patternGroupId
    }),
    () => getPatterns(options).then(({ data }) => data),
    { refetchOnWindowFocus: false }
  );
}
