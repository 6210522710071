import { Typography } from '@mui/material';
import { AppBar, LocalesMenuButton } from 'react-admin';

import { LANGS } from '../../providers/i18nProvider';

export const CustomAppBar = () => (
  <AppBar>
    <Typography flex="1" variant="h6" id="react-admin-title" />
    <LocalesMenuButton
      languages={LANGS.map(({ code, label }) => ({
        locale: code,
        name: label
      }))}
    />
  </AppBar>
);
