import {
  FileField,
  FileInput,
  FileInputProps,
  useNotify,
  useTranslate,
  Validator
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { isNullOrUndefined } from '../../core/utils/object-utils';

type Props = {
  source: string;
  label?: string;
  options?: FileInputProps['options'];
  validate?: Validator | Validator[];
};

type FileRejection = Parameters<
  FileInputProps['options']['onDropRejected']
>[0][0];

export default function InputFile({ source, label, options, validate }: Props) {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const file = useWatch({ control, name: source });

  const notify = useNotify();
  const handleDropRejected = (fileRejections: FileRejection[]) => {
    const errorMessages = fileRejections
      .flatMap((fr) => fr.errors)
      .map((err) => err.code);

    notify(`validation.${errorMessages[0]}`, { type: 'error' });
  };

  return (
    <FileInput
      source={source}
      label={label ?? false}
      placeholder={<DropZone />}
      sx={{
        '& .RaFileInput-removeButton button': { opacity: 1 },
        '& .RaFileInput-dropZone': !isNullOrUndefined(file)
          ? { display: 'none' }
          : Object.hasOwn(errors, source)
          ? { border: '2px dashed #d32f2f' }
          : {},
        '& .MuiFormHelperText-root': { display: 'none' }
      }}
      validate={validate}
      options={{ ...options, onDropRejected: handleDropRejected }}
    >
      <FileField source="src" title="title" />
    </FileInput>
  );
}

const DropZone = () => {
  const t = useTranslate();

  return (
    <div className="dropzone">
      <p>{t('general.form.dropZone.file.message')}</p>
    </div>
  );
};
